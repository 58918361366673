import {
  GET_ALL_FAVORITES_VIDEO,
  GET_ALL_FAVORITES_VIDEO_SUCCESS,
  GET_ALL_FAVORITES_VIDEO_FAIL,
  ADD_FAVORITE_VIDEO_SUCCESS,
  ADD_FAVORITE_VIDEO_FAILURE,
  ADD_FAVORITE_VIDEO_PENDING,
  ADD_FAVORITE_VIDEO,
  ADD_THEN_UPDATE_FAVORITE_VIDEO_SUCCESS,
  ADD_THEN_UPDATE_FAVORITE_VIDEO_FAILURE,
  ADD_FAVORITE_VIDEO_ATTEMPT,
  ADD_THEN_UPDATE_FAVORITE_VIDEO,
  UNFAVE_VIDEO,
  UNFAVE_THEN_GET_FAVORITE_VIDEO,
  UNFAVE_VIDEO_FAILURE,
  UNFAVE_VIDEO_SUCCESS,
  UNFAVE_THEN_GET_FAVORITE_VIDEO_FAILURE,
  UNFAVE_THEN_GET_FAVORITE_VIDEO_SUCCESS,
} from "../../actions/favorites.actions.js";
import {
  takeLatest,
  call,
  all,
  put,
  takeEvery,
  select,
  take,
  race,
} from "redux-saga/effects";
import { post } from "../../api";

export const getFavorites = function* (action) {
  let { headers } = action.payload;

  try {
    const response = yield call(
      post,
      "/api/favorites/myFavorites",
      {},
      {
        authorization: headers,
      }
    );

    const data = yield response.json();
    const favoritesInfo = data.content;

    /**const favIdsWithoutNull = favoritesInfo.map((favorite) => {
        if (favorite.video != null) return favorite
    })
    **/

    ;

    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ALL_FAVORITES_VIDEO_SUCCESS,
        favorites: favoritesInfo,
      });
    } else {
      throw data;
    }
  } catch (error) {
    yield put({ type: GET_ALL_FAVORITES_VIDEO_FAIL, error: error });
  }
};

const getFaveVideosState = (state) =>
  state.favoriteVideos.pending_favorites_by_id;

export const addFavoritesVideoAttempt = function* (action) {
  const { headers, videoId, callback } = action.payload;

  try {
    const response = yield call(
      post,
      "/api/favorites/add",
      {
        videoId,
      },
      {
        authorization: headers,
      }
    );

    const data = yield response.json();

    if (callback && data.status) {
      callback();
    }


    if (response.status >= 200 && response.status < 300 && data.status) {
      yield put({ type: ADD_FAVORITE_VIDEO_SUCCESS, videoId: videoId })
    } else {
      throw data
    }

  } catch (error) {
    yield put({ type: ADD_FAVORITE_VIDEO_FAILURE, error: error, videoId: videoId })
  }
}

export const addFavoritesVideo = function* (action) {
  const { videoId } = action.payload;
  let current_pending_favorites_by_id = yield select(getFaveVideosState);

  if (!current_pending_favorites_by_id.includes(videoId)) {
    //const data = yield call(addFavoritesVideoAttempt, { headers, videoId })

    yield put({ type: ADD_FAVORITE_VIDEO_ATTEMPT, payload: action.payload })

  } else {
    yield put({ type: ADD_FAVORITE_VIDEO_PENDING })
  }

}

export const addThenUpdateFaveVid = function* (action) {
  const { headers, videoId } = action.payload;

  try {
    const addfaveVideo = yield call(addFavoritesVideo, {
      payload: action.payload,
    });
    //const addVideo = yield put({ type: ADD_FAVORITE_VIDEO, payload: action.payload })
    const { success, failure } = yield race({
      success: take(ADD_FAVORITE_VIDEO_SUCCESS),
      failure: take(ADD_FAVORITE_VIDEO_FAILURE),
    });

    if (success) {
      const updateFavoritesVideoState = yield call(getFavorites, {
        payload: { headers },
      });
    } else if (failure) throw failure;

    yield put({ type: ADD_THEN_UPDATE_FAVORITE_VIDEO_SUCCESS });
  } catch (error) {
    yield put({ type: ADD_THEN_UPDATE_FAVORITE_VIDEO_FAILURE, error: error });
  }
};

export const unfaveVideo = function* (action) {
  const { headers, instanceId, callback } = action.payload;

  try {
    const res = yield call(
      post,
      "/api/favorites/delete",
      {
        favoriteId: instanceId,
      },
      {
        authorization: headers,
      }
    );

    const data = yield res.json();

    if (callback && data.status) {
      callback();
    }



    if (res.status >= 200 && res.status && data.status) {
      yield put({ type: UNFAVE_VIDEO_SUCCESS });
    } else throw data;
  } catch (error) {
    yield put({ type: UNFAVE_VIDEO_FAILURE, error: error });
  }
};

export const unfavoriteThenGetFaveVid = function* (action) {
  try {
    const unfaveVideoCall = yield call(unfaveVideo, { payload: action.payload })


    const getFaveVideoCall = yield call(getFavorites, { payload: action.payload })

    yield put({ type: UNFAVE_THEN_GET_FAVORITE_VIDEO_SUCCESS })

  } catch (err) {
    yield put({ type: UNFAVE_THEN_GET_FAVORITE_VIDEO_FAILURE, error: err })
  }
}

function* favorites() {
  yield all([
    takeLatest(GET_ALL_FAVORITES_VIDEO, getFavorites),
    takeEvery(ADD_FAVORITE_VIDEO, addFavoritesVideo),
    takeEvery(ADD_FAVORITE_VIDEO_ATTEMPT, addFavoritesVideoAttempt),
    takeEvery(ADD_THEN_UPDATE_FAVORITE_VIDEO, addThenUpdateFaveVid),
    takeEvery(UNFAVE_THEN_GET_FAVORITE_VIDEO, unfavoriteThenGetFaveVid),
    takeEvery(UNFAVE_VIDEO, unfaveVideo),
  ]);
}
export default favorites;
