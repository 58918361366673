export const ActionTypes = {
  SEND_VIDEO_OFFER: "SEND_VIDEO_OFFER",
  SEND_VIDEO_OFFER_SUCCESS: "SEND_VIDEO_OFFER_SUCCESS",
  SEND_VIDEO_OFFER_FAILURE: "SEND_VIDEO_OFFER_FAILURE",
  SEND_VIDEO_ANSWER: "SEND_VIDEO_ANSWER",
  SEND_VIDEO_ANSWER_SUCCESS: "SEND_VIDEO_ANSWER_SUCCESS",
  SEND_VIDEO_ANSWER_FAILURE: "SEND_VIDEO_ANSWER_FAILURE",
  JOIN_LIVE_CLASS: "JOIN_LIVE_CLASS",
  JOIN_LIVE_CLASS_SUCCESS: "JOIN_LIVE_CLASS_SUCCESS",
  JOIN_LIVE_CLASS_FAILURE: "JOIN_LIVE_CLASS_FAILURE",
  SEND_LIVE_CLASS_MESSAGE: "SEND_LIVE_CLASS_MESSAGE",
  SEND_LIVE_CLASS_MESSAGE_SUCCESS: "SEND_LIVE_CLASS_MESSAGE_SUCCESS",
  SEND_LIVE_CLASS_MESSAGE_FAILURE: "SEND_LIVE_CLASS_MESSAGE_FAILURE",
  CREATE_OFFER: "CREATE_OFFER",
  CREATE_OFFER_SUCCESS: "CREATE_OFFER_SUCCESS",
  CREATE_OFFER_FAILURE: "CREATE_OFFER_FAILURE",
  CREATE_ANSWER: "CREATE_ANSWER",
  CREATE_ANSWER_SUCCESS: "CREATE_ANSWER_SUCCESS",
  CREATE_ANSWER_FAILURE: "CREATE_ANSWER_FAILURE",
  SET_ANSWER: "SET_ANSWER",
  SET_ANSWER_SUCCESS: "SET_ANSWER_SUCCESS",
  SET_ANSWER_FAILURE: "SET_ANSWER_FAILURE",
  ADD_ICE_CANDIDATE: "ADD_ICE_CANDIDATE",
  ADD_ICE_CANDIDATE_SUCCESS: "ADD_ICE_CANDIDATE_SUCCESS",
  ADD_ICE_CANDIDATE_FAILURE: "ADD_ICE_CANDIDATE_FAILURE",
  END_LIVE_CLASS: "END_LIVE_CLASS",
  END_LIVE_CLASS_SUCCESS: "END_LIVE_CLASS_SUCCESS",
  END_LIVE_CLASS_FAILURE: "END_LIVE_CLASS_FAILURE",
  LEAVE_LIVE_CLASS: "LEAVE_LIVE_CLASS",
  LEAVE_LIVE_CLASS_SUCCESS: "LEAVE_LIVE_CLASS_SUCCESS",
  LEAVE_LIVE_CLASS_FAILURE: "LEAVE_LIVE_CLASS_FAILURE",
};

/*
export const createOffer = function (pc, peerUuid, dest) {
  return {
    type: ActionTypes.CREATE_OFFER,
    payload: {
      pc,
      peerUuid,
      dest
    }
  }
}*/
export const createOffer = function (
  pc,
  peerUuid,
  dest,
  peerUserType,
  callback
) {
  return {
    type: ActionTypes.CREATE_OFFER,
    payload: {
      pc,
      peerUuid,
      dest,
      peerUserType,
      callback,
    },
  };
};

export const createAnswer = function (
  offer,
  peerConnection,
  peerUuid,
  dest,
  callback
) {
  return {
    type: ActionTypes.CREATE_ANSWER,
    payload: {
      offer,
      peerConnection,
      peerUuid,
      dest,
      callback,
    },
  };
};

export const setAnswer = function (answer, peerConnection) {
  return {
    type: ActionTypes.SET_ANSWER,
    payload: {
      answer,
      peerConnection,
    },
  };
};

export const sendVideoOffer = function (offer) {
  return {
    type: ActionTypes.SEND_VIDEO_OFFER,
    payload: {
      offer,
    },
  };
};

export const sendVideoAnswer = function (answer) {
  return {
    type: ActionTypes.SEND_VIDEO_ANSWER,
    payload: {
      answer,
    },
  };
};

export const joinLiveClass = function (peerUuid, dest) {
  return {
    type: ActionTypes.JOIN_LIVE_CLASS,
    payload: {
      peerUuid,
      dest,
    },
  };
};

export const addIceCandidate = function (icecandidate, peerConnection) {
  return {
    type: ActionTypes.ADD_ICE_CANDIDATE,
    payload: {
      icecandidate,
      peerConnection,
    },
  };
};

export const sendLiveClassMessage = function (message) {
  return {
    type: ActionTypes.SEND_LIVE_CLASS_MESSAGE,
    payload: {
      message,
    },
  };
};

export const endLiveClass = function (message) {
  return {
    type: ActionTypes.END_LIVE_CLASS,
    payload: {
      message,
    },
  };
};
