import React from "react";
import JetsweatModal from "../JetsweatModal";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";

class AlreadySubscribedModal extends React.Component {
  constructor(props) {
    super(props);

    this.convertedTime = this.convertedTime.bind(this);
  }

  convertedTime(data) {
    if (data === "date") {
      let newTime = new Date(this.props.selectedClass.scheduledDatetime);
      let m = moment(newTime);
      return m.format("ddd, MMM DD, YYYY");
    }
    if (data === "time") {
      let newTime = new Date(this.props.selectedClass.scheduledDatetime);
      let m = moment(newTime).tz("America/New_York");
      return m.format("h:mm A");
    }
  }

  render() {
    const {
      whiteLabelBackgroundStyles,
      selectedClass,
      bookedClass,
    } = this.props;

    return (
      <JetsweatModal
        showModal={this.props.showModal}
        closeModal={this.props.closeModal}
        title={this.props.title}
        whiteLabelBackgroundStyles={this.props.whiteLabelBackgroundStyles}
        preventCloseModal={this.props.preventCloseModal}
      >
        {bookedClass.length > 0 ? (
          <>
            {/* <p style={{ color: "#979797", margin: 0 }}>
        Subscribers have access to all live classes.
      </p> */}
            <div>
              <p style={{ color: "#979797", textAlign: "center" }}>
                You have already registered for this class. As an active
                subscriber, you can find the scheduled class in your calendar
                tab, or within the live class tab of the platform.
              </p>
            </div>
            <div className="exit-modal">
              <button
                className="exit-modal-button2"
                onClick={() => {
                  this.props.closeModal();
                  this.props.bookedFunc();
                }}
                style={{
                  backgroundColor: whiteLabelBackgroundStyles
                    ? whiteLabelBackgroundStyles.backgroundColor
                    : "#9ac8b9",
                }}
              >
                Close
              </button>
              <button
                className="exit-modal-button1"
                style={{
                  color: whiteLabelBackgroundStyles
                    ? whiteLabelBackgroundStyles.backgroundColor
                    : "#9ac8b9",
                  borderColor: whiteLabelBackgroundStyles
                    ? whiteLabelBackgroundStyles.backgroundColor
                    : "#9ac8b9",
                }}
                onClick={() =>
                  this.props.history.push("/newplatform/dashboard")
                }
              >
                Proceed To Dashboard
              </button>
            </div>{" "}
          </>
        ) : (
          <>
            {/* <p style={{ color: "#979797", margin: 0 }}>
        Subscribers have access to all live classes.
      </p> */}
            <div style={{ margin: 0 }}>
              <p style={{ color: "#979797", textAlign: "center" }}>
                You can view this class at its start time{" "}
                <Link
                  to={{
                    pathname: `/newplatform/live/upcoming/${selectedClass.id}`,
                  }}
                >
                  <span
                    style={{
                      color:
                        whiteLabelBackgroundStyles.backgroundColor || "#9AC8B9",
                      textDecoration: "underline",
                    }}
                  >
                    here.
                  </span>
                </Link>
              </p>
            </div>
            <div>
              <p
                style={{
                  color: "#979797",
                  textAlign: "center",
                  fontSize: "22px",
                }}
              >
                Live Class Details
              </p>
              <div style={{ fontSize: "18px" }}>
                <div style={{ display: "flex" }}>
                  <p style={{ color: "#979797", fontWeight: "700" }}>Date </p>
                  <p>{this.convertedTime("date")}</p>
                </div>
                <div style={{ display: "flex" }}>
                  <p style={{ color: "#979797", fontWeight: "700" }}>Time </p>
                  <p>{this.convertedTime("time")} (Eastern Time Zone)</p>
                </div>
                <div style={{ display: "flex" }}>
                  <p style={{ color: "#979797", fontWeight: "700" }}>Class </p>
                  <p>{selectedClass.title}</p>
                </div>
              </div>
            </div>
            <div className="exit-modal">
              <button
                className="exit-modal-button2"
                onClick={() => {
                  this.props.closeModal();
                  this.props.bookedFunc();
                }}
                style={{
                  backgroundColor: whiteLabelBackgroundStyles
                    ? whiteLabelBackgroundStyles.backgroundColor
                    : "#9ac8b9",
                }}
              >
                Close
              </button>
              <button
                className="exit-modal-button1"
                style={{
                  color: whiteLabelBackgroundStyles
                    ? whiteLabelBackgroundStyles.backgroundColor
                    : "#9ac8b9",
                  borderColor: whiteLabelBackgroundStyles
                    ? whiteLabelBackgroundStyles.backgroundColor
                    : "#9ac8b9",
                }}
                onClick={() =>
                  this.props.history.push("/newplatform/items/calendar")
                }
              >
                View My Calendar
              </button>
            </div>
          </>
        )}
      </JetsweatModal>
    );
  }
}

export default withRouter(AlreadySubscribedModal);
