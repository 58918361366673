import { reverseArray } from "utilities/reverseArray";
import {
  GET_ALL_FAVORITES_VIDEO_SUCCESS,
  GET_ALL_FAVORITES_VIDEO_FAIL,
  ADD_FAVORITE_VIDEO,
  ADD_FAVORITE_VIDEO_SUCCESS,
  ADD_FAVORITE_VIDEO_FAILURE,
  ADD_FAVORITE_VIDEO_PENDING,
  ADD_FAVORITE_VIDEO_ATTEMPT,
  ADD_THEN_UPDATE_FAVORITE_VIDEO,
  ADD_THEN_UPDATE_FAVORITE_VIDEO_SUCCESS,
  ADD_THEN_UPDATE_FAVORITE_VIDEO_FAILURE,
} from "../../actions/favorites.actions";

const initialState = {
  favoritesVideo: [],
  error: {},
  pending_favorites_by_id: [],
  map_vidId_to_instanceId: {},
  favoriteVideosLength: 0,
};

export default function favoritesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_FAVORITES_VIDEO_SUCCESS:
      const copyfavorites = action.favorites.filter((video) => video.video);
      reverseArray(copyfavorites);
      const favoriteVideos = copyfavorites.map((video) => video.video);
      const map_videoId_to_instanceId = copyfavorites.reduce(
        (prevState, currState) => ({
          ...prevState,
          [currState.video.id]: currState.id,
        }),
        {}
      );

      return {
        ...state,
        favoritesVideo: favoriteVideos,
        error: {},
        map_vidId_to_instanceId: map_videoId_to_instanceId,
        favoriteVideosLength: copyfavorites.length,
      };
    case GET_ALL_FAVORITES_VIDEO_FAIL:
      return {
        ...state,
        error: { type: "Failed to fetch Favorites", ...action.error },
      };

    case ADD_FAVORITE_VIDEO_ATTEMPT:
      let pending_favorites_by_id;
      if (!state.pending_favorites_by_id.includes(action.videoId)) {
        pending_favorites_by_id = [
          ...state.pending_favorites_by_id,
          action.payload.videoId,
        ];
      } else {
        pending_favorites_by_id = [...state.pending_favorites_by_id];
      }
      return {
        ...state,
        pending_favorites_by_id,
      };

    case ADD_FAVORITE_VIDEO:
      return {
        ...state,
      };

    case ADD_FAVORITE_VIDEO_PENDING:
      return {
        ...state,
        error: { type: "Current Add request is pending..." },
      };

    case ADD_FAVORITE_VIDEO_SUCCESS:
      //Remove id from state
      return {
        ...state,
        pending_favorites_by_id: state.pending_favorites_by_id.filter(
          (videoId) => videoId !== action.videoId
        ),
        error: {},
      };
    case ADD_FAVORITE_VIDEO_FAILURE:
      //Remove id from state
      let current_pending_favorites_by_id = state.pending_favorites_by_id.filter(
        (videoId) => videoId !== action.videoId
      );
      return {
        ...state,
        pending_favorites_by_id: current_pending_favorites_by_id,
        error: { type: "Failed to add Video", ...action.error },
      };

    case ADD_THEN_UPDATE_FAVORITE_VIDEO:
      return {
        ...state,
      };

    case ADD_THEN_UPDATE_FAVORITE_VIDEO_SUCCESS:
      return {
        ...state,
        error: {},
      };
    case ADD_THEN_UPDATE_FAVORITE_VIDEO_FAILURE:
      return {
        ...state,
        error: { ...state.error },
      };
    default:
      return state;
  }
}
