import { ActionTypes } from "redux/actions/video.actions";
import { put, call, all, takeLatest, takeEvery } from "redux-saga/effects";
import { post } from "redux/api";

export const getPrograms = function* (action) {
  const { headers } = action.payload;

  try {
    const response = yield call(
      post,
      "/api/video/programs",
      {},
      {
        authorization: headers,
      }
    );

    const data = yield response.json();
    const programsInfo = data.content;

    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ActionTypes.GET_PROGRAMS_SUCCESS,
        programsInfo: programsInfo,
      });
    } else {
      throw data;
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PRORAMS_FAILURE, error: error });
  }
};

export const getAllClasses = function* (action) {
  const { headers } = action.payload;

  try {
    const response = yield call(
      post,
      "/api/video/videos",
      {
        targetMucles: 0,
        classType: {},
        studio: {},
        equipment: {},
        timeLength: {
          min: 0,
          max: 90,
        },
        calorieBurn: {
          min: 0,
          max: 800,
        },
        level: 0,
        bodyFocus: 0,
        limitations: 0,
        sort: {
          newest: true,
          longest: false,
          recommended: false,
          shortest: false,
          highest: false,
        },
      },
      {
        authorization: headers,
      }
    );

    const data = yield response.json();
    const allClasses = data.content;

    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ActionTypes.GET_ALL_CLASSES_SUCCESS,
        classes: allClasses,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ALL_CLASSES_FAILURE });
  }
};

function* video() {
  yield all([
    takeLatest(ActionTypes.GET_PROGRAMS, getPrograms),
    takeLatest(ActionTypes.GET_ALL_CLASSES, getAllClasses),
  ]);
}

export default video;
