import React from "react";
import JetsweatModal from "../JetsweatModal";
import branch from "branch-sdk";
import { Link } from "react-router-dom";
import { faBoxTissue } from "@fortawesome/free-solid-svg-icons";

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      warning: "",
      error: "",
    };
  }

  handleChange = (event) => {
    const fieldName = event.target.name;
    this.setState({ [fieldName]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    fetch(
      `/api/user/login/${
        this.props.whiteLabel ? this.props.studio.shortName : ""
      }`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          email: this.state.email,
          password: this.state.password,
        }),
      }
    )
      .then((res) => {
        if (res.status >= 400) {
          console.log("We're in the error");
          return res.json();
        }
        return res.json();
      })
      .then((body) => {
        branch.setIdentity(this.state.email);
        if (body == null) {
          return null;
        }
        if (body.status) {
          const currUser = body.content;
          this.props.setCurrUser(currUser);
          //   const pushTo =
          //     currUser.subscription && currUser.subscription.active
          //       ? '/newplatform/dashboard'
          //       : '/payment'
          //   this.props.history.push(pushTo)
          this.props.closeModal();
          if (currUser.subscription && currUser.subscription.active) {
            this.props.bookClass(this.props.selectedClass);
            this.props.alreadySubscribedModal();
          } else {
            this.props.paymentModal();
          }
        } else {
          console.log("We're in the error");
          this.setState({ error: body.content });
        }
      });
  };

  render() {
    const { whiteLabelBackgroundStyles, whiteLabel } = this.props;
    return (
      <JetsweatModal
        showModal={this.props.showModal}
        closeModal={this.props.closeModal}
        title={this.props.title}
        whiteLabelBackgroundStyles={this.props.whiteLabelBackgroundStyles}
        preventCloseModal={this.props.preventCloseModal}
        customStyles={this.props.customStyles}
      >
        <form onSubmit={this.handleSubmit}>
          <div className="login-modal">
            <h1
              className="Main-text Padding"
              style={{
                color: whiteLabelBackgroundStyles
                  ? whiteLabelBackgroundStyles.backgroundColor
                  : "#9ac8b9",
              }}
            >
              {" "}
              Log In{" "}
            </h1>
            {this.state.error ? (
              <div className="Padding">
                <div className="errormessage">{this.state.error}</div>
              </div>
            ) : (
              ""
            )}

            <input
              id="signupemail"
              autoFocus
              type="text"
              name="email"
              onChange={this.handleChange}
              className="Input-style Font No-highlight-border"
              placeholder="Email"
            />

            <input
              id="signuppassword"
              type="password"
              name="password"
              onChange={this.handleChange}
              className="Input-style Font No-highlight-border"
              placeholder="Password"
            />

            <div className="Padding" style={{ textAlign: "center" }}>
              <button
                type="submit"
                className="Button-style Font signup-button"
                style={{
                  backgroundColor: whiteLabelBackgroundStyles
                    ? whiteLabelBackgroundStyles.backgroundColor
                    : "#9ac8b9",
                }}
              >
                {" "}
                LOG IN{" "}
              </button>
              <br />
              <div style={{ padding: "10px" }}>
                <span style={{ paddingRight: "5px" }}>
                  Don't have an account?
                </span>
                <br />
                <span
                  className="hover"
                  onClick={() => {
                    this.props.closeModal();
                    this.props.signupModal();
                  }}
                  style={{
                    color: whiteLabel
                      ? whiteLabelBackgroundStyles.backgroundColor
                      : "#9ac8b9",
                    textDecoration: "none",
                    fontSize: 16,
                  }}
                >
                  Sign up Here!
                </span>
              </div>
              <div>
                <p className="message">
                  <span style={{ paddingRight: "5px" }}>
                    Forgot your password?
                  </span>
                  <br />
                  <Link
                    to="/forgot-password"
                    style={{
                      color: whiteLabel
                        ? whiteLabelBackgroundStyles.backgroundColor
                        : "#9ac8b9",
                      textDecoration: "none",
                    }}
                  >
                    Reset here!
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </form>
      </JetsweatModal>
    );
  }
}

export default LoginModal;
