export const ActionTypes = {
  GET_PROMO: "GET_PROMO",
  GET_PROMO_SUCCESS: "GET_PROMO_SUCCESS",
  GET_PROMO_FAILURE: "GET_PROMO_FAILURE",
  GET_ALL_CLASSTYPES: "GET_ALL_CLASSTYPES",
  GET_ALL_CLASSTYPES_SUCCESS: "GET_ALL_CLASSTYPES_SUCCESS",
  GET_ALL_CLASSTYPES_FAILURE: "GET_ALL_CLASSTYPES_FAILURE",
  GET_ALL_STUDIOS: "GET_ALL_STUDIOS",
  GET_ALL_STUDIOS_SUCCESS: "GET_ALL_STUDIOS_SUCCESS",
  GET_ALL_STUDIOS_FAILURE: "GET_ALL_STUDIOS_FAILURE",
};

export const verifyPromo = function (code, price, headers) {
  return {
    type: ActionTypes.GET_PROMO,
    payload: {
      code,
      price,
      headers,
    },
  };
};

export const getClassTypes = function (headers) {
  return {
    type: ActionTypes.GET_ALL_CLASSTYPES,
    payload: {
      headers,
    },
  };
};

export const getStudios = function (headers) {
  return {
    type: ActionTypes.GET_ALL_STUDIOS,
    payload: {
      headers,
    },
  };
};
