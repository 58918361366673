export const ActionTypes = {
  GET_ALL_PROFILE_DATA: "GET_ALL_PROFILE_DATA",
  GET_ALL_PROFILE_DATA_SUCCESS: "GET_ALL_PROFILE_DATA_SUCCESS",
  GET_ALL_PROFILE_DATA_FAILURE: "GET_ALL_PROFILE_DATA_FAILURE",
  SAVE_PROFILE_DATA: "SAVE_PROFILE_DATA",
  SAVE_PROFILE_DATA_SUCCESS: "SAVE_PROFILE_DATA_SUCCESS",
  SAVE_PROFILE_DATA_FAILURE: "SAVE_PROFILE_DATA_FAILURE",
};

export const getAllProfileData = (user) => {
  return {
    type: ActionTypes.GET_ALL_PROFILE_DATA,
    payload: {
      user,
    },
  };
};

export const saveProfileData = (user, newData) => {
  return {
    type: ActionTypes.SAVE_PROFILE_DATA,
    payload: {
      user,
      newData,
    },
  };
};
