import React, { Component } from "react";

import "./navlink.css";
import MappedStudioList from "components/MappedStudioList";
import downArrow from "../assets/downArrow.png";

export default class NavLink extends Component {
  render() {
    const { linkHref, label, studioInfoList, subMenuItems } = this.props;

    if (studioInfoList && label === "Studios") {
      return (
        <div className="dropDown">
          <div className="studios-dropDown-container">
            <a
              className="dropDownButton js-font-333333 js-open-sans js-font-16"
              href={linkHref}
            >
              {label}
              <img className="downArrow" src={downArrow} alt="down arrow" />
            </a>
            <ul className="dropDown-content">
              <MappedStudioList studioInfoList={studioInfoList} />
            </ul>
          </div>
        </div>
      );
    }

    if (subMenuItems) {
      return (
        <div className="dropDown">
          <div className="studios-dropDown-container">
            <a
              className="dropDownButton js-font-333333 js-open-sans js-font-16"
              href={linkHref}
            >
              {label}
              <img src={downArrow} alt="down arrow" />
            </a>
            <ul className="dropDown-content subMenu-content">
              {subMenuItems.map((item) => (
                <li key={item.label}>
                  <a href={item.href}>{item.label}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    }

    return (
      <div>
        <a className="js-font-333333 js-open-sans js-font-16" href={linkHref}>
          {label}
        </a>
      </div>
    );
  }
}
