//opt out for pulling data from backend instead of using fixed classtypes
export const classTypeList = [
  "Pilates",
  "Strength",
  "Cardio",
  "Boxing",
  "Meditation",
  "HIIT",
  "Running/Treadmill",
  "Martial Arts",
  "Barre",
  "Sculpt",
  "Yoga",
  "Dance",
  "Stretching",
  "Bootcamp",
  "Mobility",
  "Cycling",
  "Gua Sha",
  "Face Yoga",
  "Skin Cardio",
  "Lymphatic",
  "Kids",
];
export const studioList = [
  "BK Pilates",
  "Yoga Vida",
  "modelFIT",
  "Woom Center",
  "The Bari Studio",
  "IntenSati",
  "Masterskya",
  "Switch Playground",
  "Solace",
  "MindFresh",
  "BODYROK",
  "Body Space",
  "Wright Fit",
  "Hit House",
  "The Studio",
  "The Dailey Method",
  "MNTSTUDIO",
  "Fhitting Room",
  "SALT SF",
  "Mama Soul",
  // 'WillyB CrossFit',
  "Punch Pedal House",
  "Rowgatta",
  "The Space Between",
  "PLAYLIST. Yoga",
  "Naturally Cut Fitness",
  "JoyRide",
  "Box and Flow",
  "Brooks Pilates",
  "MPower",
  "Stretch*d",
  "WillyB Fitness",
  "Roots 66 Fit",
  "Jet Cetera",
  "305 Fitness",
  "Kickat55 Fitness",
  "Pilates ProWorks",
  "Authentic Yoga Studio",
  "Citizen Pilates",
];

export const equipmentList = [
  "None",
  "Ballet Barre or Sturdy Chair",
  "Dumbells",
  "Inflatable Ball",
  "Block",
  "Jump Rope",
  "Sliding Discs",
  "Stationary Bike",
  "Treadmill",
  "Resistance Band",
  "Yoga Mat",
  "Barbell",
  "Kettlebell",
  "Foam Roller",
  "Pilates Circle",
  "Step",
  "Ankle Weights",
  "Medicine Ball",
  "Strap",
  "Boxing Gloves",

  "Reformer",
  //(step, ankle weights, ball, and strap)
  //boxing gloves
];

export const equipmentHack = {
  "Sliding Discs": "Hack: Small Towels",
  Block: "Hack: Thick Books",
  "Ballet Barre or Sturdy Chair": "Hack: Sturdy Chair",
};

export const alternativeEquipName = {
  "Ballet Barre or Sturdy Chair": "Ballet Barre",
  None: "No Equipment",
};

export const alternativeFitnessLevelName = {
  Sedentary: "Sedentary - little to no exercise",
  "Lightly Active": "Lightly Active - light exercise 1-3 days/week",
  Active: "Active - moderate exercise 3-5 days/week",
  "Very Active": "Very Active - heavy exercise 6-7 days/week",
};

export const classLevelList = ["Any", "Intermediate", "Beginner", "Advanced"];

export const bodyFocusList = [
  "Any",
  "Upper Body",
  "Core",
  "Total Body",
  "Lower Body",
  "Mind",
];

export const limitationsList = ["None", "Postnatal", "Prenatal", "Low Impact"];

export const targetMuclesList = [
  "Front Deltoids",
  "Biceps",
  "Triceps",
  "Obliques",
  "Adductors",
  "Hip Flexors",
  "Ankle",
  "Lateral Deltoids",
  "Rotator Cuff",
  "Lower Back",
  "Hamstrings",
  "Soleus",
  "Neck",
  "Pectorals",
  "Forearms",
  "Rectus Abdominis",
  "Wrist Flexors",
  "Abductors",
  "Quadriceps",
  "Trapezius",
  "Rear Deltoids",
  "Latissimus Dorsi",
  "Glutes",
  "Gastrocnemius",
];

export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
