import React from "react";
import { Link } from "react-router-dom";

export default function MappedStudioList(props) {
  const { studioInfoList } = props;

  const mappedStudios = studioInfoList
    .sort((studioA, studioB) => {
      if (studioA.name < studioB.name) return -1;
    })
    .map((studio) => {
      let link;
      if (studio.name === "Chloe Kernaghan Yoga") link = "chloekernaghanyoga";
      else
        link = studio.shortName || studio.name.replace(/\W/g, "").toLowerCase();

      return (
        <Link
          key={`s-${studio.name}`}
          to={{
            pathname: `/studios/${link}`,
            state: { studio },
          }}
        >
          {studio.name}
        </Link>
      );
    });

  return mappedStudios;
}
