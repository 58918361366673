import React from "react";
import { Popup, Grid } from "semantic-ui-react";
import moment from "moment";
import dots from "../../images/three-dots-button.png";

import { Link, withRouter } from "react-router-dom";

const Workout = (props) => {
  const {
    wkt,
    openModal,
    whiteLabelBackgroundStyles,
    whiteLabelFontStyles,
  } = props;
  const workout = wkt.liveClass ? wkt.liveClass : wkt.video;
  let inlineStyle;
  if (wkt.status === "completed") {
    inlineStyle = { ...whiteLabelBackgroundStyles };
  } else if (wkt.status === "scheduled") {
    inlineStyle = {
      border: `3px solid ${whiteLabelFontStyles.color}`,
    };
  } else {
    inlineStyle = {};
  }
  return (
    <div className="workout">
      <Link
        to={{
          pathname: wkt.liveClass
            ? `/newplatform/live/upcoming/${wkt.liveClass.id}`
            : `/newplatform/items/classes/${wkt.video.id}`,
          state: {
            video: wkt.liveClass ? wkt.liveClass : null,
          },
        }}
        className="workout-new-grid"
      >
        <div id="workout-time">
          {/* Convert time to the user's timezone. */}
          {moment(wkt.when).format("hh:mm A")}
        </div>
        <div id="workout-status">
          <div className={`status ${wkt.status}`} style={inlineStyle} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            paddingRight: 10,
          }}
          className="title-wrapper"
        >
          <div>{workout.title}</div>
          {props.mapProgIdToTitle && (
            <div style={{ color: "#CDCCCC" }}>
              {props.mapProgIdToTitle[wkt.programId]}
            </div>
          )}
        </div>
      </Link>
      <div className="dots">
        <Popup trigger={<img alt="dots" src={dots} />} flowing hoverable>
          <div id="popup-bk">
            <Grid centered divided columns={3}>
              {!wkt.liveClass ? (
                <Grid.Column>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      openModal(wkt);
                    }}
                  >
                    <span>
                      <pre>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 59 63"
                          fill={
                            (whiteLabelFontStyles &&
                              whiteLabelFontStyles.color) ||
                            "#9ac8b9"
                          }
                        >
                          <defs>
                            <clipPath id="clip-Edit_100620">
                              <rect width="59" height="63" />
                            </clipPath>
                          </defs>
                          <g id="Edit_100620" clipPath="url(#clip-Edit_100620)">
                            <g id="Edit-Icon" transform="translate(-6.553 -1)">
                              <path
                                id="Path_18"
                                data-name="Path 18"
                                d="M100.653-25.645l41.084-41.084,8.865,8.865-.212.212L109.518-16.78,96.607-12.744Z"
                                transform="translate(-87.054 72.569)"
                                fill="none"
                                stroke={
                                  (whiteLabelFontStyles &&
                                    whiteLabelFontStyles.color) ||
                                  "#9ac8b9"
                                }
                                strokeLinecap="round"
                                clipPath="round"
                                strokeWidth="2"
                              />
                              <path
                                id="Path_23"
                                data-name="Path 23"
                                d="M100.376-22.234l6.45,6.45-9.5,2.97Z"
                                transform="translate(-87 72)"
                                stroke={
                                  (whiteLabelFontStyles &&
                                    whiteLabelFontStyles.color) ||
                                  "#9ac8b9"
                                }
                                strokeWidth="1"
                              />
                            </g>
                          </g>
                        </svg>{" "}
                        Edit
                      </pre>
                    </span>
                  </div>
                </Grid.Column>
              ) : (
                <Grid.Column />
              )}
              <Grid.Column>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    openModal(wkt, true);
                  }}
                >
                  <span>
                    <pre>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 60 72"
                        fill={
                          (whiteLabelFontStyles &&
                            whiteLabelFontStyles.color) ||
                          "#9ac8b9"
                        }
                      >
                        <defs>
                          <clipPath id="clip-DeleteTrash_100620">
                            <rect width="60" height="72" />
                          </clipPath>
                        </defs>
                        <g
                          id="DeleteTrash_100620"
                          clipPath="url(#clip-DeleteTrash_100620)"
                        >
                          <g id="Trash-Icon">
                            <path
                              id="Union_1"
                              data-name="Union 1"
                              d="M-450-63v-9h21v-8h18v8h21v9Z"
                              transform="translate(450 81)"
                            />
                            <path
                              id="Path_16"
                              data-name="Path 16"
                              d="M455.393,93.5l6.117,55.859h37.468L504.3,93.5Z"
                              transform="translate(-450 -80)"
                              fill="none"
                              stroke={
                                (whiteLabelFontStyles &&
                                  whiteLabelFontStyles.color) ||
                                "#9ac8b9"
                              }
                              strokeWidth="2"
                            />
                            <path
                              id="Path_17"
                              data-name="Path 17"
                              d="M471,93.5l1.963,55.952h14.224L488.435,93.5Z"
                              transform="translate(-450 -80.2)"
                              fill="none"
                              stroke={
                                (whiteLabelFontStyles &&
                                  whiteLabelFontStyles.color) ||
                                "#9ac8b9"
                              }
                              strokeWidth="2"
                            />
                          </g>
                        </g>
                      </svg>{" "}
                      Delete
                    </pre>
                  </span>
                </div>
              </Grid.Column>
            </Grid>
          </div>
        </Popup>
      </div>
    </div>
  );
};

export default withRouter(Workout);
