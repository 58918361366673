/* eslint-disable comma-dangle */
/* eslint-disable function-paren-newline */
import { createStore, combineReducers, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import livestreamReducer from "./reducers/livestream.reducer";
import stripeReducer from "./reducers/stripe.reducer";
import userFriendsReducer from "./reducers/userfriends.reducer";
import liveclassReducer from "./reducers/liveclass.reducer";
import chatReducer from "./reducers/chat.reducer";
import eventsReducer from "./reducers/events.reducer";
import favoritesReducer from "./reducers/favorites.reducer";
import rootSaga from "redux/sagas";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import programReducer from "views/store/programReducer";
import progressReducer from "views/store//progressReducer";
import profileReducer from "views/store//profileReducer";
import dashboardReducer from "views/store//dashboardReducer";
import calendarReducer from "views/store//calendarReducer";
import liveReducer from "views/store//liveReducer";
import basicReducer from "./reducers/basic.reducer";
import newprofileReducer from "./reducers/profile.reducer";
import videoReducer from "./reducers/video.reducer";
import favoriteclasstypeReducer from "./reducers/favoriteclasstypes.reducer";
import favoritestudiosReducer from "./reducers/favoritestudios.reducer";
import antmediaReducer from "./reducers/antmedia.reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//import liveReducer from './reducers/liveReducer'
//import rootReducer from './reducers/root.reducer'
//import persistReducer from 'redux-persist/es/persistReducer'
const logger = createLogger({
  timestamps: true,
  duration: true,
});

const persistConfig = {
  key: "root",
  storage,
};

const reducer = combineReducers({
  stripe: stripeReducer,
  events: eventsReducer,
  liveclass: liveclassReducer,
  livestream: livestreamReducer,
  userfriends: userFriendsReducer,
  calendarReducer: calendarReducer,
  eventsReducer: eventsReducer,
  chat: chatReducer,
  programReducer,
  progressReducer,
  profileReducer,
  dashboardReducer,
  favoriteVideos: favoritesReducer,
  profile: newprofileReducer,
  liveReducer,
  basic: basicReducer,
  video: videoReducer,
  favoriteclasstypes: favoriteclasstypeReducer,
  favoritestudios: favoritestudiosReducer,
  antmedia: antmediaReducer,
});
const persistedReducer = persistReducer(persistConfig, reducer);
const sagaMiddleware = createSagaMiddleware();
const middle = [sagaMiddleware, thunkMiddleware];
const middleware =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(...middle)
    : composeWithDevTools(
        applyMiddleware(...middle, createLogger({ collapsed: true }))
      );

const store = createStore(persistedReducer, middleware);
const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export { store, persistor };
