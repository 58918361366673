import React, { Component } from "react";
import CardSection from "../cardsection.js";
import "../account/signup.css";
import "../checkoutform.css";
import { CardNumberElement } from "@stripe/react-stripe-js";
import moment from "moment";

class LiveClassCheckout extends Component {
  constructor() {
    super();
    this.state = {
      promo: "",
      error: "",
      stall: false,
      isLoading: false,
      isPurchasePending: false,
      status: "scheduled",
      freeAmount: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getPromoText = this.getPromoText.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.couponSuccess !== prevProps.couponSuccess) {
      if (this.props.coupon.content.newAmount === 0) {
        this.setState({
          freeAmount: true,
        });
      } else {
        this.setState({
          freeAmount: false,
        });
      }
    }
  }

  getPromoText(code) {
    this.setState({
      promo: code,
    });
  }

  async handleSubmit(ev) {
    if (this.state.isSubmitting) return null;
    const {
      stripe,
      elements,
      firstName,
      lastName,
      sendLiveClassBookingConfirmation,
      selectedClass,
      user,
    } = this.props;
    //The price comes back in dollars and stripe expects the price amount in cents
    let selectedClassPrice = selectedClass.price * 100;

    if (this.props.couponSuccess) {
      if (this.props.coupon.content.newAmount === 0) {
        selectedClassPrice = 0;
      } else {
        selectedClassPrice = this.props.coupon.content.newAmount * 100;
      }
    }

    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    let time = this.props.selectedClass.scheduledDatetime;
    let newTime = new Date(time);
    let m = moment(newTime);
    let modifiedTime = m.utc().startOf("minute").utc().toISOString();
    if (this.state.stall || !stripe || !elements) {
      return null;
    }
    this.setState({
      isPurchasePending: true,
    });

    if (this.state.freeAmount) {
      sendLiveClassBookingConfirmation(
        selectedClassPrice,
        selectedClass.studioId,
        null,
        selectedClass.id,
        this.state.promo,
        {
          Authorization: user.authToken,
        }
      );

      fetch("/api/workout/add", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: this.props.user.authToken,
        },
        body: JSON.stringify({
          authUserInfo: user,
          videoId: null,
          liveClassId: selectedClass.id,
          status: this.state.status,
          when: modifiedTime,
        }),
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });

      this.setState({
        isPurchasePending: false,
      });
      this.props.closeModal();
      this.props.orderSummaryModal();
    } else {
      const response = await fetch(`/api/liveclass/upcoming/payment-intent`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          amount: selectedClass.price * 100,
          studioId: selectedClass.studioId,
          liveClassId: selectedClass?.id,
          coupon: this.state.promo,
        }),
      });

      const data = await response.json();
      if (data.status === false) {
        this.setState({
          error: data?.content ?? "Unable to complete payment",
          isPurchasePending: false,
        });
      }
      const { client_secret } = data.content;
      const result = await stripe.confirmCardPayment(client_secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
        },
      });

      if (result.error) {
        this.setState({
          isPurchasePending: false,
        });
      } else {
        sendLiveClassBookingConfirmation(
          selectedClassPrice,
          selectedClass.studioId,
          result.paymentIntent.id,
          selectedClass.id,
          this.state.promo,
          {
            Authorization: user.authToken,
          }
        );
        fetch("/api/workout/add", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: this.props.user.authToken,
          },
          body: JSON.stringify({
            authUserInfo: user,
            videoId: null,
            liveClassId: selectedClass.id,
            status: this.state.status,
            when: modifiedTime,
          }),
        })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });

        this.setState({
          isPurchasePending: false,
        });
        this.props.closeModal();
        this.props.orderSummaryModal();
      }
    }

    this.setState({ isLoading: false });
  }
  render() {
    let { isPurchasePending } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        {this.state.error ? (
          <div className="errormessage" style={{ textAlign: "center" }}>
            {this.state.error}
          </div>
        ) : (
          ""
        )}
        <CardSection {...this.props} promoText={this.getPromoText} />
        <div className="Padding" style={{ textAlign: "center" }}>
          <button
            style={this.props.whiteLabelBackgroundStyles}
            className="Button-style Font signup-button"
            type="submit"
            disabled={isPurchasePending}
          >
            {this.state.isLoading ? "Processing... " : "Confirm Order"}
          </button>
          <p>All sales are final, no cancellations or switches</p>
        </div>
      </form>
    );
  }
}

export default LiveClassCheckout;
