import React, { Component } from "react";
import "./button.css";

export default class Button extends Component {
  static defaultProps = {
    color: "orange",
    href: "",
    label: "",
  };

  getColorClassNames(color) {
    if (color === "orange")
      return `js-background-EE8373 js-font-semibold js-font-white`;
    if (color === "green")
      return `js-background-1F3830 js-font-semibold js-font-white`;
    if (color === "light-green")
      return `js-background-9AC8B9 js-font-semibold js-font-1F3830`;
    if (color === "transparent")
      return `js-background-transparent js-font-semibold`;
  }

  getClassName() {
    let { color, className } = this.props;
    return `${className} ${this.getColorClassNames(color)}`;
  }

  render() {
    let { label, href, children } = this.props;
    return (
      <a
        className={`js-font-20 js-font-14-mb js-avenir-next d-flex align-items-center justify-content-center js-home-button ${this.getClassName()}`}
        style={{ padding: 10 }}
        href={href}
      >
        {label}
        {children}
      </a>
    );
  }
}
