/* eslint-disable */
export const baseUrl = "https://d1db7fp1f6g9xk.cloudfront.net";

export const FONTS = {
  gothamBold: baseUrl + "/fonts/Gotham-Bold.ttf",
  futuraFont: baseUrl + "/fonts/futura-book-font.ttf",
};

export const IMAGES = {
  studios: {
    baristudio: {
      combinedLogo:
        baseUrl + "/images/allStudios/baristudio/bari-combined-logo.png",
      logo: baseUrl + "/images/allStudios/baristudio/baristudiologo.png",
      hero: baseUrl + "/images/allStudios/baristudio/baristudio2copy.jpg",
      img2:
        baseUrl +
        "/images/allStudios/baristudio/The%20Bari%20Studio%20Bari%20Peel.jpg",
      about: baseUrl + "/images/allStudios/baristudio/baristudioabout.png",
      instructors: [
        // {
        //   name: "Chelsea",
        //   photoUrl: "",
        //   bio: ""
        // },
        // {
        //   name: "Tiffany",
        //   photoUrl: "",
        //   bio: ""
        // },
        // {
        //   name: "Colette Dong",
        //   photoUrl: "",
        //   bio: ""
        // }
      ],
      howItWorks:
        baseUrl +
        "/images/allStudios/baristudio/2%20The%20Bari%20Studio%20Online%20Classes.png",
      text: {
        title: "Bari Studio",
        about: [
          "Get your heart rate up in this high intensity, sweat-drenching, beat-driven, soul-shaking workout. Challenge your body and mind with sets that strategically built for toning. Co-founders Alexandra Bonetti-Perez and Brice Andrew Hall have worked with Madonna, and have created choreography with everything from jumping jacks, kicks, punches and even mini trampolines.",
          "This is a full body workout that delivers visible results and a workout high like you’ve never experienced before. Dance the day away with Bari in the comfort of your own home!",
        ],
        testimonials: [
          // {
          //   text: "",
          //   author: ""
          // }
        ],
      },
      classTypes: ["Sculpt", "Cardio"],
      levels: "",
      equipment: [],
    },
    bkpilates: {
      combinedLogo:
        baseUrl + "/images/allStudios/bkpilates/bkpilates-combined-logo.png",
      logo:
        baseUrl +
        "/images/allStudios/bkpilates/BK%20Pilates_logoWebsiteHero.png",
      hero: baseUrl + "/images/allStudios/bkpilates/BKPilates_Hero_2.jpg",
      img2:
        baseUrl +
        "/images/allStudios/bkpilates/BK%20Pilates%20Leg%20Sculpt_2.png",
      about: baseUrl + "/images/allStudios/bkpilates/BK_3_2.jpg",
      instructors: [
        {
          name: "Marina Kaydanova",
          photoUrl:
            baseUrl +
            "/images/allStudios/bkpilates/BKPilates%20Marina%20Kayadonova_5_1.jpg",
          bio: "",
        },
        {
          name: "Marcy Schafler",
          photoUrl:
            baseUrl +
            "/images/allStudios/bkpilates/BK%20Pilates%20Marcy%20Schafler_5_2.jpg",
          bio: "",
        },
        {
          name: "Nabila",
          photoUrl:
            baseUrl +
            "/images/allStudios/bkpilates/Bk%20Pilates%20-%20Nabila.jpg",
          bio: "",
        },
        {
          name: "Jess",
          photoUrl:
            baseUrl +
            "/images/allStudios/bkpilates/BK%20Pilates%20-%20Jess.jpg",
          bio: "",
        },
      ],
      howItWorks:
        baseUrl +
        "/images/allStudios/bkpilates/03_BK%20Pilates%20Accelerated%20Ab%20Sculpt.png",
      text: {
        title: "BK Pilates",
        about: [
          `BK Pilates' mission is to help clients achieve control of their health and fitness goals. Keeping those goals in mind BK Pilates provides customized exercise routines that help their clients develop the essential mind-body connection, define the body, establish core strength, achieve greater flexibility and improve posture.`,
          `With emphasis on spinal and pelvic alignment, breathing and balance, the studio provides different exercises in all different levels.`,
        ],
        testimonials: [
          {
            text:
              "I always walk out feeling longer, leaner and stronger. Highly recommend to everyone!",
            author: "Yelp Review",
          },
          {
            text:
              "Great new experience- I've never done Pilates before and this was a great way to get into it.",
            author: "Yelp Review",
          },
          {
            text:
              "...you get an insane work out. I've been going for two months now, and I can really see the difference in my body. From skinny-fat to lean and toned. I also noticed an improvement in my strength and stamina. Really happy with the results and actually once you start taking care of your body it naturally inclines you to also be mindful of the things you consume - great catalyst for healthy living. Truly recommend this place.",
            author: "Yelp Review",
          },
        ],
      },
      classTypes: ["Pilates", "Sculpt", "Mobility"],
      levels: "allLevels",
      equipment: ["yogaMat", "inflatableBall", "pilatesCircle", "dumbells"],
    },
    bodyrok: {
      combinedLogo:
        baseUrl + "/images/allStudios/bodyrok/bodyrok-combined-logo.png",
      logo: baseUrl + "/images/allStudios/bodyrok/bodyrok-logo-black.png",
      hero:
        baseUrl +
        "/images/allStudios/bodyrok/Bodyrok_%20Reformer%20Class%20Hero_2.png",
      img2:
        baseUrl +
        "/images/allStudios/bodyrok/Bodyrok%20Ab%20and%20Oblique%20Sculpt%20_2.png",
      about:
        baseUrl +
        "/images/allStudios/bodyrok/3_Bodyrok%20Core%20and%20Glute%20Sculpt.png",
      instructors: [
        {
          name: "Katie Panas",
          photoUrl: baseUrl + "/images/allStudios/bodyrok/BodyRok-%20katie.jpg",
          bio: "",
        },
        {
          name: "Sina",
          photoUrl: baseUrl + "/images/allStudios/bodyrok/BodyRok-Cena.jpg",
          bio:
            "Sina is a NASM certified Personal Trainer originally from Germany, living in New York for 5 years . With a competitive Judo background of 7+ years, she’s always been an athlete and passionate about fitness. She is known for delivering results as a Lead instructor at Bodyrok where she is constantly focusing on ways to enhance the Bodyrok experience at any level.",
        },
        {
          name: "Claudia",
          photoUrl: baseUrl + "/images/allStudios/bodyrok/BodyRok-Claudia.jpg",
          bio:
            "Originally from the San Francisco Bay Area, Claudia is an elementary school teacher by day and BODYROK instructor by night (and weekends)! In her spare time, you can find her singing Broadway show tunes, quoting Kristin Wiig, or correcting your grammar. ",
        },
        {
          name: "Courtney",
          photoUrl: baseUrl + "/images/allStudios/bodyrok/BodyRok-Courtney.jpg",
          bio:
            "Movement and strength have always been a huge part of Courtney's life. Her highly effective and precise class leaves students feeling not only sore, but motivated and fueled for what's next. Courtney is thrilled to join the BodyRok team and work with you!",
        },
        {
          name: "Kayley",
          photoUrl: baseUrl + "/images/allStudios/bodyrok/BodyRok-Kailey.jpg",
          bio:
            "Kayley has been moving and sweating from a young age. Born and raised in CA, she competed in high-level sports growing up, and went on to be a NCAA Division 1 athlete in college. She began her fitness career years ago with cycling, and has since expanded to master pilates. Kayley also offers personal training, specializing in pre/post natal. Happiest when moving, fitness combines her love of fitness and helping others.",
        },
        {
          name: "Rebekah",
          photoUrl: baseUrl + "/images/allStudios/bodyrok/RebekehBioImage.jpg",
          bio:
            "Rebekah Coster is a Balanchine trained ballet dancer born and raised in Southern California. She spent her high school years training at the School of American Ballet in New York City and Pacific Northwest Ballet in Seattle, WA. After completing high school, Rebekah went on to major in Dance at the University of California Santa Barbara. While there, she fell in love with other styles of fitness and began her teaching career. In 2014, she obtained her Pilates mat certification and has been teaching many different styles of dance, barre, and Pilates ever since. Rebekah enjoys the opportunity to share her passions in each one of her classes while balancing a full time job working in accessibility technology for deaf and hard of hearing students.",
        },
        {
          name: "Abbie Wohl",
          photoUrl: baseUrl + "/images/allStudios/bodyrok/BODYROK_Abbie.jpg",
          bio:
            "Originally from the East Coast, Abbie danced her way to San Francisco in 2009 to pursue a career in ballet. Flash forward a few years and a few injuries later, she went on to get a degree in Kinesiology and found BodyRok in 2013. These days, you’ll find her selling software by day in NYC and applying her knowledge about the body to give killer Sculpt classes by night.",
        },
        {
          name: "Jay Centero",
          photoUrl: baseUrl + "/images/allStudios/bodyrok/5_3.png",
          bio: "",
        },
      ],
      howItWorks:
        baseUrl +
        "/images/allStudios/bodyrok/Bodyrok%20Full%20Body%20Combo%20Class_4.png",
      text: {
        title: "BODYROK",
        about: [
          "BODYROK offers high intensity, low impact workouts that will tone, sculpt and redefine your body and mind in under an hour. BODYROK believes that cross training is key, so they have formulated workouts that compliment each other perfectly and put them all under one roof.",
          "All of their workouts are form-focused, ensuring you get the most out of every exercise.",
        ],
        testimonials: [
          {
            text:
              "I've been to a few pilates reformer studios around the city and this is hands down, one of the best.  BodyRok has awesome instructors and you're sure to get a damn good workout.  Highly recommend!",
            author: "Yelp Review",
          },
          {
            text:
              "Love love love the classes here! Great studio and upbeat instructors. One main highlight is the instructors go by counts, instead of reps. This allows you to go at your own pace instead of focusing on keeping up with the number of reps.",
            author: "Yelp Review",
          },
          {
            text:
              "Each movement has it's very targeted to the muscle group it's exercising, and has its own funky name (Mermaid, Sexy Back, Scrambled Eggs to name a few). While the movements may look easy, they're actually hard AF because the slower the movement, the harder the burn. This workout is nothing if not efficient ... This is a serious workout. You may not be dripping with sweat at the end of it, but make no mistake: You. Will. Be. Sore.",
            author: "TheSweatReport.com",
          },
          {
            text:
              "Although you'll see this fitness center on our list again for Pilates, note that BodyROK's spin classes are pretty powerful, too. The Marina studio offers hour-long spins in addition to its half-spin, half-Pilates signature hybrid. There’s just something about working up a sweat on a bike followed by a challenging, lengthening, and toning session on its mega reformers. There aren’t any locker rooms, but it does have some light amenities for a quick freshening up before dashing off.",
            author: "Refinery 29",
          },
        ],
      },
      classTypes: ["Pilates", "Sculpt", "Cardio"],
      levels: "advance",
      equipment: ["slidingDiscs", "dumbells"],
    },
    bodyspace: {
      combinedLogo:
        baseUrl + "/images/allStudios/bodyspace/bodyspace-combined-logo.png",
      logo:
        baseUrl + "/images/allStudios/bodyspace/body-space-fitness-logo.png",
      hero: baseUrl + "/images/allStudios/bodyspace/bodyspacehero.jpg",
      img2:
        "https://jetsweat.s3.us-east-2.amazonaws.com/studios/Body+Space/2+Body+space+fitness+online+HIIT+workouts.png",
      about: baseUrl + "/images/allStudios/bodyspace/3.jpg",
      instructors: [
        {
          name: "Kelvin Gary",
          photoUrl:
            baseUrl + "/images/allStudios/bodyspace/kelvin-gary-headshot.jpg",
          bio:
            "Some people just need to move. Specialties: Performance Enhancement, kettlebell, Cycling Certifications: NASM-CPT; Precision Nutrition Coach; Kettlebell Athletics (Levels I and II); Functional Movement Specialist; Pre- and Post-Natal, CPR/AED.",
        },
      ],
      howItWorks: baseUrl + "/images/allStudios/bodyspace/4%20mockup.png",
      text: {
        title: "Body Space",
        about: [
          "Body Space Fitness (BSF) focuses on functional fitness—the kind that helps you live your life better, faster, and stronger. BSF trainers believe that every body is different, and they teach you how to carry, push, pull, and stabilize weights to effectively and safey use every part of your body. You will build true strength, while shedding unwanted weight.",
        ],
        testimonials: [
          {
            text:
              "This is one of the best workouts I've had...Completely kicked my butt - in the best way possible.",
            author: "Body Space Fitness Client",
          },
          {
            text:
              "The good part about the circuit workouts is that they can all be modified to accommodate your preferred level of difficulty. The trainers are simultaneously motivating and incredibly welcoming of people at all fitness levels.",
            author: "Body Space Fitness Client",
          },
          {
            text:
              "BSF trainers are great because they intuitively understand something that other trainer's don't: people want to be challenged as much as possible without feeling judged.",
            author: "Yelp Review",
          },
        ],
      },
      classTypes: ["HIIT", "Barre", "Sculpt", "Cardio"],
      levels: "allLevels",
      equipment: ["slidingDiscs", "kettlebell"],
    },
    chloekernaghanyoga: {
      combinedLogo:
        baseUrl + "/images/allStudios/skyting/skyting-combined-logo.png",
      logo: baseUrl + "/images/allStudios/skyting/skytinglogo.png",
      hero: baseUrl + "/images/allStudios/skyting/skytinghero.png",
      img2: baseUrl + "/images/allStudios/skyting/skyting2.jpg",
      about: baseUrl + "/images/allStudios/skyting/skyting4.jpg",
      instructors: [
        {
          name: "Chloe Kernaghan ",
          photoUrl: baseUrl + "/images/allStudios/skyting/Chloe%2B05.jpg",
          bio:
            "Born and raised on the island of Guam, I came to NYC to study at NYU and have been practicing yoga since. After graduating with a BFA, I completed my first training and have continued studies with Nevine Michaan and Abbie Galvin at Katonah Yoga, and Rodney Yee and Colleen Saidman Yee at Yoga Shanti. My teachings derive from several traditions of yoga and an expanded study of physicality in general. I've been acting and dancing since I can remember, my background in performing arts both informs and energizes my method and material. My classes are typically defined by an emphasis on structural integrity, smart sequencing, a connection to music and a unifying spiritual thread. I aim to help students better connect with themselves and with the world-wide-web of life. All in all, my primary goal with my practice and what I teach is to figure out how yoga can be a tool to make your life better. Whether its expanding your vision, helping anchor you down, making you more radiant, or sparking joy... this practice is magic if you understand how to use it.",
        },
      ],
      howItWorks: baseUrl + "/images/allStudios/skyting/skyting1.jpg",
      text: {
        title: "Chloe Kernaghan Yoga",
        about: [
          "The yoga we teach is a medley of lineages. All styles of yoga have something important to offer and we steal from them all! Cross-pollinating yogic material allows us to create something wholly new yet refined with rich substance and history. In biology, monocultures are highly susceptible to disease and death. Likewise, we believe that braiding a diverse pool of influences helps us create the strongest possible offering for the physical, emotional and cultural health and well-being of our wonderful community. The primary traditions we study and borrow from are Katonah, Taoist, Hatha and Vinyasa, as well as several different meditation and breathing practices. Chloe Kernaghan Yoga founder, Chloe studies at Katonah and uses it to hone her material. Katonah is unlike any yoga you've ever taken before; it will revolutionize, compliment and inform your yoga practice.",
        ],
        testimonials: [
          {
            text:
              "...Chloe Kernaghan Yoga's distinguished, down-to-earth teachers are perhaps what separates it most from its many neighbors.",
            author: "Vogue.com ",
          },
          {
            text: "Fashion’s favorite yoga trainer...",
            author: "The New York Times",
          },
          {
            text:
              "Chloe is a dynamic, dedicated teacher that has created a space to explore yoga in an informative and supportive environment.",
            author: "The New York Times",
          },
          {
            text:
              "...many a Vogue editor swear by the classes Chloe Kernaghan Yoga has been teaching...",
            author: "Vogue.com",
          },
          {
            text: "...check your stress at the door...",
            author: "PureWow",
          },
        ],
      },
      classTypes: ["Yoga"],
      levels: "",
      equipment: [],
    },
    daileymethod: {
      combinedLogo:
        baseUrl +
        "/images/allStudios/daileymethod/daileymethod-combined-logo.png",
      logo:
        baseUrl +
        "/images/allStudios/daileymethod/TDM-Main-Logo-black-1000px.png",
      hero: baseUrl + "/images/allStudios/daileymethod/JMD_1423.jpg",
      img2:
        baseUrl +
        "/images/allStudios/daileymethod/The%20Dailey%20Method%20arm%20workout.png",
      about:
        baseUrl +
        "/images/allStudios/daileymethod/The%20Dailey%20Method%20Barre%20classes%20on%20TV.png",
      instructors: [
        {
          name: "Jill Dailey",
          photoUrl:
            baseUrl +
            "/images/allStudios/daileymethod/Jill%20Dailey%20Founder%20Bio.png",
          bio:
            "Jill Dailey is the founder and CEO of The Dailey Method. She has a B.S Degree in Kinesiology and has been in the fitness industry for over 30 years.",
        },
        {
          name: "Katina Griffin",
          photoUrl:
            baseUrl +
            "/images/allStudios/daileymethod/Katina%20Griffin%20Dailey%20Method%20Bio.png",
          bio:
            "Katina Griffin has a background in Dance and Kinesiology and has been with The Dailey Method since 2003. She is a Master Teacher Trainer with TDM. Katina's leadership skills, creativity, passion for the safety in movement as well as her love for teacher training are all strengths she brings to every one of her workouts.",
        },
        {
          name: "Kelly Frankin",
          photoUrl:
            baseUrl +
            "/images/allStudios/daileymethod/Kelly%20Franklin%20The%20Dailey%20Method%20Bio.png",
          bio:
            "Kelly Frankin became a Dailey Method devotee in 2012 after the birth of her second child, quickly seeing all the benefits of a Dailey practice. She became an instructor under founder Jill Dailey in 2015 and has since been teaching Barre classes for TDM.",
        },
        {
          name: "Margaret Schimmel",
          photoUrl:
            baseUrl +
            "/images/allStudios/daileymethod/Margaret%20Schimmel%20Dailey%20Method%20Bio.png",
          bio:
            "Margaret Schimmel is a Dailey Method Teacher. She teaches Dailey Method Basics at various studio locations.",
        },
      ],
      howItWorks:
        baseUrl +
        "/images/allStudios/daileymethod/The%20Dailey%20Method%20streaming%20online.png",
      text: {
        title: "THE DAILEY METHOD",
        about: [
          "The search for the best barre & cycle fitness class is over. The Dailey Method is here to help you achieve a strong, lean, sculpted body through an evolving mix of fitness practices that samples the best benefits from multiple disciplines. Our intrinsic focus on alignment and strength results in better posture, better movement, and a better you.",
          "Never stale, our ever-evolving mix of movement is always fresh, fun, and challenging. The Dailey Method is efficiency incarnate. You get a total workout: your body sculpted, mind sharpened, motivation heightened, attitude transformed.",
          "WHAT MAKES THE DAILEY METHOD DIFFERENT? We are passionate about alignment. We ignite awareness through hands-on training and education. The result is better posture, better movement, and a more complete workout. We do in a single class what takes others a week. It’s simple, but revolutionary. Proper alignment radiates outward from the spine, bringing balance. When we start with alignment the muscles engage in their correct positions, elongating, contracting, strengthening. The Dailey Method helps you work smarter.",
        ],
        testimonials: [
          {
            text:
              "City folks are devoted to this Cow Hollow studio for classes in one of the original barre techniques. Not easy by any means, Dailey Method isn't as brutal as some of the other studios around these parts. And, it might be one of our favorite ways to kick start the weekend. Dailey Method is a classic Lotte Berk-style workout for purists of the regimen and those wanting to build a solid foundation in classic barre.",
            author: "Refinery29",
          },
          {
            text:
              "Ever leave a barre class wondering if you even worked any muscles or feeling like you didn't get the attention you needed to get the most out of your workout? Then you weren't leaving The Dailey Method. Their instructors here ensure you're working the muscles in the way they're meant to move, meaning no strained back or sore knees after class. And they'll be with you the entire way through.",
            author: "Fitt.co 2 Best Gyms in San Francisco",
          },
          {
            text:
              "My number one. Why? It appeals to my inner-dance and it targets tough-to-tone areas.",
            author: "7x7",
          },
          {
            text:
              "Free weight and ballet barre moves that you don't have to be a ballerina to master.",
            author: "Allure",
          },
        ],
      },
      classTypes: [
        "Dailey Barre",
        "Dailey Interval (HIIT)",
        "Dailey Basics",
        "Dailey Fusion",
      ],
      levels: "allLevels",
      equipment: ["dumbells", "yogaMat"],
    },
    fhittingroom: {
      combinedLogo:
        baseUrl +
        "/images/allStudios/fhittingroom/fhitting%20room-combined-logo.png",
      logo: baseUrl + "/images/allStudios/fhittingroom/FhittingRoom-black.png",
      hero: baseUrl + "/images/allStudios/fhittingroom/01_HEADER.jpg",
      img2:
        baseUrl +
        "/images/allStudios/fhittingroom/02_fhitting_room_battleropes_groupworkout.png",
      about:
        baseUrl +
        "/images/allStudios/fhittingroom/fhitting%20room%20kari%20founder.png",
      instructors: [
        {
          name: "Daury",
          photoUrl: baseUrl + "/images/allStudios/fhittingroom/1_FRDaury.png",
          bio:
            "Daury loves helping people make positive changes.Certifications: NCCPT Personal Trainer, Kettlebell Concepts, PROnatal Fitness Pre and Post Natal, Functional StrengthCoach, TRX Level 1, StrongFirst Educated.",
        },
        {
          name: "Melody",
          photoUrl: baseUrl + "/images/allStudios/fhittingroom/2_FRMelody.png",
          bio:
            "Melody's goal is to help people feel confident both in the studio and their day-to-day lives. Certifications: NASM Personal Trainer, AFAA Group Fitness Instructor, PROnatalFitness Pre and Post Natal, TRX Level 1,CFSC Certified.",
        },
        {
          name: "Eric",
          photoUrl: baseUrl + "/images/allStudios/fhittingroom/3_FMEric.png",
          bio:
            "Eric found his passion helping others crush their goals. Certifications: NASM + NSCA Personal Trainer, Kettlebell Concepts, CrossFit Kettlebell, Functional Strength Coach, IndoorRowing Instructor (Concept 2 Rower), TRX Level 1, CrossFit Level 1, CrossFit Endurance, CrossFitGymnastics, USAW Olympic Weight Lifting, CrossFit Olympic Weight Lifting, Road Runners Club of AmericaRunning Coach, StrongFirst Educated.",
        },
        {
          name: "Riley",
          photoUrl: baseUrl + "/images/allStudios/fhittingroom/4_FRRiley.png",
          bio:
            "Riley loves spending her days connecting with her clients, helping them reach their goals and watching them grow. Certifications: Functional Strength Coach, NASM Personal Trainer, ACE Group Fitness, PROnatal Fitness Pre and PostNatal, TRX Level 1, StrongFirst Educate.",
        },
        {
          name: "Simon",
          photoUrl: baseUrl + "/images/allStudios/fhittingroom/5_FRSimon.png",
          bio:
            "Simon is an avid obstacle course racer and uses HIIT workouts to train and prepare for the grueling challenges these races offer. Certifications: NASM Personal Trainer, StrongFirst Kettlebell Level 1 Instructor, TRX, Annette Lang Pre and Post NatalTrainer, ISCA Boxing Instructor.",
        },
      ],
      howItWorks: baseUrl + "/images/allStudios/fhittingroom/04_image.png",
      text: {
        title: "THE FHITTING ROOM",
        about: [
          "Where personal training meets group fitness. Fhitting Room is a premium High Intensity Interval Training studio delivering the most efficient workout with the best results in just 50 minutes.",
          "Prior to founding FhittingRoom in 2013, Kari was a Marketing Executive at Pepsi-Cola Company, where she focused on building brand equity for powerhouse brands as well as launching new brands and product innovations. She left the world of boardrooms and bubbles and entered the world of diapers and sleepless nights nearly ten years ago.Exhausted and time-starved, Kari started High Intensity Interval Training (HIIT) as a means to work off her pregnancy weight. Kari created Fhitting Room to deliver super effective, safe and truly funHIIT workouts bringing together the best aspects of a one-on-one training session with the contagious culture and social dynamics of group exercise classes. Kari has an MBA from Harvard Business School, where she graduated with honors, and graduated summacum laude with a BS in Economics from the Wharton School of University of Pennsylvania.",
        ],
        testimonials: [
          {
            text:
              "It's hard as hell for any fitness level, but accessible for all too. The trainers are what make Fhitting Room the best in the business",
            author: "Brooke W.",
          },
          {
            text:
              "Today’s class was perfection! It was hard, fun, fast, strong, and easy to understand.",
            author: "Amy K.",
          },
          {
            text:
              "If you like intense classes that make you drip sweat, push you beyond your comfort zone, and offer variety in terms of modalities as well as moves, you’ll like this workout.",
            author: "Health Magazine",
          },
          {
            text:
              "The routines (which make use of a wide variety of equipment) change every time, but you can expect 50 minutes of great strength and endurance training in every session",
            author: "Refinery 29",
          },
        ],
      },
      classTypes: ["Cardio", "HIIT", "Strength"],
      levels: "allLevels",
      equipment: ["kettlebell", "dumbells"],
    },
    hithouse: {
      combinedLogo:
        baseUrl + "/images/allStudios/hit_house/hithouse-combined-logo.jpg",
      logo: baseUrl + "/images/allStudios/hit_house/Hit%20House%20copy.png",
      hero:
        baseUrl +
        "/images/allStudios/hit_house/Hit%20House%20Boxing%20Class-CROPPED-2.jpg",
      img2: baseUrl + "/images/allStudios/hit_house/IMG_2993.jpg",
      about: baseUrl + "/images/allStudios/hit_house/IMG_2905.jpg",
      instructors: [
        {
          name: "Tyler Scott",
          photoUrl:
            baseUrl +
            "/images/allStudios/hit_house/Bio%201%20Tyler%20Scott%20Hit%20House.jpg",
          bio: "",
        },
        {
          name: "Raquel Harris",
          photoUrl:
            baseUrl +
            "/images/allStudios/hit_house/Bio%202%20Raquel%20Harris%20Hit%20House.jpg",
          bio: "",
        },
        {
          name: "Justin Ng",
          photoUrl:
            baseUrl +
            "/images/allStudios/hit_house/Bio%203%20Justin%20Ng%20Hit%20House.JPG",
          bio: "",
        },
      ],
      howItWorks:
        baseUrl +
        "/images/allStudios/hit_house/4%20HitHouse%20Streaming%20Devices.jpg",
      text: {
        title: "HIT HOUSE",
        about: [
          "Hit House is a one-of-a-kind Muay Thai kickboxing boutique studio that encourages self-confidence and ambition while increasing cardio and producing muscle tone. If you’re in the mood to vent, a friend or therapist works, and sometimes even a punching bag. You’ll learn to improve your punches, kicks, knees and elbows with this high-intensity workout.",
          "Channel your inner Muhammad Ali with co-founder, Tyler Scott, and the trainers at Hit House. You’ll be a lover and a fighter. ",
        ],
        testimonials: [
          {
            text:
              "You know cardio always makes us sweat. And we have noted that sometimes in kickboxing classes we don’t always feel like we get that 100% cardio element. Hit House completely distests that notion. It was 100% cardio at all times. There was a great mix of strength building along with fast paced punches and kicks to really make this class a full body workout and an overall sweaty class.”",
            author: "The Class Cult",
          },
          {
            text:
              "We highly recommend trying this class, especially if you are into kickboxing. It is very similar to kickboxing, but there’s just something about giving kicks to the abdomen and elbows to the nose that makes you forget that this is also an intense cardiovascular workout.",
            author: "The Class Cult",
          },
          {
            text:
              "Hit House is a  Muay Thai kickboxing boutique fitness studio located on the corner of Spring & Bowery. You’ll learn and improve your punches, kicks, knees and elbows by beating up your own Bishop heavy bag. Muay Thai is a full body work out: expect to improve your cardio, muscle tone and fight skills.",
            author: "Bowery Boogie",
          },
        ],
      },
      classTypes: ["Boxing", "Cardio", "Strength"],
      levels: "",
      equipment: [],
    },
    intensati: {
      combinedLogo:
        baseUrl + "/images/allStudios/intensati/intensati-combined-logo.png",
      logo: baseUrl + "/images/allStudios/intensati/intensatilogo.png",
      hero: baseUrl + "/images/allStudios/intensati/intensatihero.jpg",
      img2: baseUrl + "/images/allStudios/intensati/2.png",
      about: baseUrl + "/images/allStudios/intensati/intensati2.png",
      instructors: [
        {
          name: "Patricia Moreno",
          photoUrl:
            baseUrl + "/images/allStudios/intensati/5-1of1_Patricia.jpg",
          bio: "",
        },
        {
          name: "Lucy Osborne",
          photoUrl:
            baseUrl +
            "/images/allStudios/intensati/Intensati%20Workout%20Lucy%20Osborne.jpg",
          bio: "",
        },
      ],
      howItWorks: baseUrl + "/images/allStudios/intensati/4.png",
      text: {
        title: "INTENSATI",
        about: [
          "The IntenSati method is a revolution that improves your mind and body through an uplifting workout experience. Creator, Patricia Moreno takes you through a journey of breath work, cardio, strength exercises, spoken affirmations, and intention-setting to kick-start transformations, both inside and out.",
          "Allow yourself to become more self-aware, regulate negative emotions and improve self-confidence with IntenSati. You’re experiencing inner peace- and achieving thinner peace at the same time. Prioritize positivity. ",
        ],
        testimonials: [
          {
            text:
              "Patricia Moreno’s cult fave spiritual-cardio class is all about engaging your mind, body, and emotions at once—and the renowned instructor says if you want to build something (like a more intentional, joyous life), you’ve got to put your whole self into it.",
            author: "Well+Good",
          },
          {
            text:
              "Little did I know that this class was not only harder than cardio boot camp but would be the catalyst for upping the level of my workouts, improving my mood and my outlook on life, and eventually even shifting my neuroscience research.",
            author: "Live Science",
          },
          {
            text:
              "It all comes down to your inner dialogue and how you define exercise itself, affirms Patricia Moreno, creator of IntenSati, a workout that combines empowering mantras with interval training, martial arts, dance and yoga.",
            author: "The Fashion Spot",
          },
        ],
      },
      classTypes: ["Dance", "Cardio", "Mindbody"],
      levels: "allLevels",
      equipment: [],
    },
    mamasoul: {
      combinedLogo:
        baseUrl + "/images/allStudios/MamaSoul/mamasoul%20x%20jetsweat.png",
      logo: baseUrl + "/images/allStudios/MamaSoul/MamaSoul_vectorlogo.png ",
      hero: baseUrl + "/images/allStudios/MamaSoul/Hi%20RES%20LOGO%20.png",
      img2: baseUrl + "/images/allStudios/MamaSoul/mamasoul%20image%202.png",
      about: baseUrl + "/images/allStudios/MamaSoul/mamasoul%20image%204.png",
      instructors: [
        {
          name: "Tara",
          photoUrl:
            baseUrl +
            "/images/allStudios/MamaSoul/tara%20bio%20pic%20%20(1).jpg",
          bio:
            "Tara started late in life as a mother. She left her 20 year Costume Designer career due to a startling lack of support for her perinatal mood and anxiety disorder. She practiced yoga with Melissa before pregnancy, and reconnected when she sought out some fellow mama solace at Melissa’s daughter’s 2nd birthday party. Tara met Laura while in treatment and loved her from hello. Tara currently lives in Brooklyn where she is raising a third generation Brooklyn boy.",
        },
        {
          name: "Kristen",
          photoUrl:
            baseUrl + "/images/allStudios/MamaSoul/MamaSoul%20_%20Kristen.jpg",
          bio:
            "Kristen Jay has a Ph. D in Psychology from UCLA, specifically in cognitive neuroscience. She has studied and taught about the mind-body relationships within this framework for more than 10 years.",
        },
        {
          name: "Lauren",
          photoUrl:
            baseUrl + "/images/allStudios/MamaSoul/MamaSoul%20_%20Lauren.jpg",
          bio:
            "Lauren Magarelli has been a student of Yoga for over 20 years and has been certified since 2005. Lauren has a BFA in both dance and psychology from The University of Buffalo, as well as a Master’s degree from Hunter College. For over 13 years, Lauren was a senior teacher at Laughing Lotus Yoga Center in New York City where she was the creative director of the 50 Hour Bhakti Teacher Training program. Lauren has taught over 22 teacher training both the 200 and 300 hr. Lauren also opened her dream studio three years ago in Beacon.",
        },
      ],
      howItWorks:
        "https://jetsweat.s3.us-east-2.amazonaws.com/studios/MamaSoul/mamasoul+image+3.jpg",
      text: {
        title: "MamaSoul",
        about: [
          "MamaSoul Studio Insightful prenatal and postpartum yoga and meditation guidance specially tailored for each phase of your pregnancy.",
        ],
        testimonials: [
          {
            text:
              "Thank You Tara!!! This is so helpful and (as a pregnant lady) I’m so grateful for the work you do.",
            author: "Anna",
          },
          {
            text: "I have recommended mamaSoul to several patients and people.",
            author: "Virginia Reath RPA MPH PC ",
          },
          {
            text:
              "MamaSoul Yoga took away the pain I was feeling in my pelvic region. Surprisingly, I also felt some energy and an uplift in my mood.",
            author: "Erica",
          },
          {
            text:
              "I love the MamaSoul Meditation videos at the 5pm witching hour. I find it an alternative to a glass of wine. I feel calmer, the edges feel smoother.",
            author: "Kristin",
          },
        ],
      },
      classTypes: ["Yoga", "Meditation"],
      levels: "",
      equipment: ["yogaMat", "block"],
    },
    masterskya: {
      combinedLogo:
        baseUrl + "/images/allStudios/masterskya/masterskya-combined-logo.png",
      logo: baseUrl + "/images/allStudios/masterskya/masterskyalogo.png",
      hero: baseUrl + "/images/allStudios/masterskya/masterskyahero.jpg",
      img2: baseUrl + "/images/allStudios/masterskya/masterskya2.jpg",
      about: baseUrl + "/images/allStudios/masterskya/masterskya3.jpg",
      instructors: [
        // {
        //   name: "Alex Ecklin",
        //   photoUrl: "",
        //   bio: ""
        // },
        // {
        //   name: " Van Allen Flores",
        //   photoUrl: "",
        //   bio: ""
        // }
      ],
      howItWorks: baseUrl + "/images/allStudios/masterskya/masterskyaabout.png",
      text: {
        title: "Masterskya",
        about: [
          "Masterskya is a unique approach to tackling the skill of Martial Arts, created by world ranked #2 Brazilian Jiu-Jitsu master, Alex Ecklin. The mission is to bring martial arts to its roots in the most authentic way, while teaching how to perfect one’s self-defense and fighting skills, and getting an intense and exceptional workout in the training.",
          "They provide the most honest, high-level training possible in an altruistic environment for anyone who wants to pursue a martial way. Masterskya has a specific vision to make martial arts accessible to anyone, and, with JETSWEAT, you can now pursue the martial way in your own home.",
        ],
        testimonials: [
          {
            text:
              "After training at dojos all over the world, I can easily say that Masterskya has some of the highest level instruction anywhere.",
            author: "Unknown",
          },
          {
            text:
              "Finding this place was like stumbling into the Garden of Eden.",
            author: "Unknown",
          },
          {
            text:
              "I've trained at a number of Brazilian Jiu-Jitsu academies over the years, but I'm really happy to have landed at Masterskya now. BJJ is an amazing art and there are a lot of good schools in NYC, but there are some of things that really make Masterskya stand out",
            author: "Unknown",
          },
        ],
      },
      classTypes: ["Martial Arts", "Cardio"],
      levels: "",
      equipment: [],
    },
    mindfresh: {
      combinedLogo:
        baseUrl + "/images/allStudios/mindfresh/mindfresh-combined-logo.png",
      logo: baseUrl + "/images/allStudios/mindfresh/mindfreshlogo.png",
      hero: baseUrl + "/images/allStudios/mindfresh/mindfreshhero.png",
      img2: baseUrl + "/images/allStudios/mindfresh/2%403x.png",
      about: baseUrl + "/images/allStudios/mindfresh/mindfresh1.png",
      instructors: [
        {
          name: "Jen Kluczkowski",
          photoUrl:
            baseUrl +
            "/images/allStudios/mindfresh/5-1of4_Jen%20Kluczkowski.png",
          bio: "",
        },
        {
          name: "Alex Brzenk",
          photoUrl:
            baseUrl + "/images/allStudios/mindfresh/5-2of4_Alex%20Brzenk.png",
          bio: "",
        },
        // {
        //   name: "Kate Pastorek",
        //   photoUrl: "",
        //   bio: ""
        // },
        // {
        //   name: "Either Dalal Arnold",
        //   photoUrl: "",
        //   bio: ""
        // }
      ],
      howItWorks: baseUrl + "/images/allStudios/mindfresh/mindfreshabout.png",
      text: {
        title: "Mind Fresh",
        about: [
          "Meditation, movement, & music to wake up the senses and help you conquer your day. ",
          "MindFresh has become a fusion of mindful movement, meditation & breathing techniques. It is one of the most powerful mindfulness formulas for those who work in an office. In a world where our work and life continue to blend, MindFresh is excited to help you pause, take a conscious breath, tune down the outer & inner noise & even improve your posture.",
          "Jen Kluczkowski, founder of MindFresh, will teach you the science behind her easy and effective meditation techniques, a combination of scientifically proven practices for those sitting behind a desk for long hours or just in need of a regenerating break from your day. MindFresh is taught at companies such as L’Oreal, Facebook, Estee Lauder, Tiffany & Co, Saks Fifth Avenue, Pinterest and Yahoo, helping people improve body language, reduce stress, and enhance emotional IQ.",
        ],
        testimonials: [
          {
            text:
              "The women behind MindFresh are making Yoga in the workplace cool.",
            author: "Fast Company",
          },
          {
            text:
              "Mindfresh is a game-changer for us. I feel lucky to work for a company that places value on health and wellness, and it is through our partnership with Mindfresh that we’ve been able to provide ways to make mindfulness and movement a priority at the office. Our sessions have become such an integral part of our routine that I now can’t imagine an Ai without it.",
            author:
              "Jessica Lippke, Associate Director, Project Management at Alexander Interactive (Ai)",
          },
        ],
      },
      classTypes: ["Meditation"],
      levels: "",
      equipment: [],
    },
    mntstudio: {
      combinedLogo:
        baseUrl + "/images/allStudios/mntstudio/mntstudio-combined-logo.png",
      logo: baseUrl + "/images/allStudios/mntstudio/MNT%20Logo.png",
      hero: baseUrl + "/images/allStudios/mntstudio/MNT%20Pilates%20Studio.jpg",
      img2: baseUrl + "/images/allStudios/mntstudio/2@3x.png",
      about: baseUrl + "/images/allStudios/mntstudio/MNT%20Studio%20Barre.jpeg",
      instructors: [
        {
          name: "Elaine Hayes",
          photoUrl:
            baseUrl +
            "/images/allStudios/mntstudio/MNT%20Studio%20Elaine%20Hayes%20Bio.jpg",
          bio:
            "Elaine Hayes is the Owner & Founder of MNTSTUDIO, a certified Pilates, Barre, and Yoga teacher, and the creator of the MNT Teacher Training Programs. Having been in the industry for nearly a decade, highly effective, intelligently sequenced, and above all FUN. She's known for delivering serious results and has converted even the most stubborn of couch potatoes (including herself!) into movement lovers. She lives in the San Francisco Bay Area with her husband Mike, toddler son, Leo, and fur baby, Zoey.",
        },
      ],
      howItWorks:
        baseUrl +
        "/images/allStudios/mntstudio/MNTSTUDIO%20APP%20LAPTOP%20CLASS.png",
      text: {
        title: "MNTSTUDIO",
        about: [
          "MNTSTUDIO is a Pilates & barre studio with a holistic approach to wellness. Join the community to feel empowered and to energize your mind, body, and spirit. Transform your life towards a healthier and happier lifestyle. MNTbarre, Mat Pilates, and Pilates Bootcamp energize the body, mind, and spirit. All of our classes will give your energy a boost and leave you feeling stronger head-to-toe. We believe wellness is for everyone, so we’ve made sure our roster of classes is accessible for all fitness levels.",
          "Our instructors go through an intense teacher training, created by MNTSTUDIO Founder, Elaine Hayes and are well-equipped to guide you on your fitness journey.",
          "MNT stands for Move Nourish Transform, but don’t let the delicate-sounding name or the studio’s Goop-y vibe fool you: Its Pilates will work you so hard you’ll hobble to your car after. So come and get your sweat on!",
        ],
        testimonials: [
          {
            text: "Pilates transforms a life — and MNTSTUDIO in S.F",
            author: "San Francisco Chronicle",
          },
          {
            text: "We found the most inspiring studio in the entire city",
            author:
              "PureWow, The best workouts in 7 San Francisco Neighborhoods",
          },
          {
            text:
              "Here, you’ll focus on small, sculpting movements on their Reformer machines, and sit back and relax with a free Nespresso afterward. Think you won’t sweat? Their instructors push you until your muscles are shaking — you’ll thank them later.",
            author: "Fitt.co 20 best gyms in San Francisco",
          },
          {
            text: "The Best Pilates in San Francisco",
            author: "TimeOut",
          },
        ],
      },
      classTypes: ["Pilates", "Bootcamp", "Cardio", "Sculpt"],
      levels: "allLevels",
      equipment: ["yogaMat", "dumbells"],
    },
    modelfit: {
      combinedLogo:
        baseUrl + "/images/allStudios/modelfit/modelfit-combined-logo.png",
      logo:
        baseUrl +
        "/images/allStudios/modelfit/logo-site-01-e1483845798587(1).png",
      hero: baseUrl + "/images/allStudios/modelfit/modelfit1copy.jpg",
      img2: baseUrl + "/images/allStudios/modelfit/4.png",
      about: baseUrl + "/images/allStudios/modelfit/1.JPG",
      instructors: [
        {
          name: "Javier Perez",
          photoUrl:
            baseUrl + "/images/allStudios/modelfit/5-%201of2_Javier.png",
          bio:
            "Javier Perez is a fitness instructor and performer from Miami, FL and now lives in New York City. He started his fitness career as an elite gymnast competing internationally and won gold at the Junior Olympics with his team in 2005. He continued on training in all styles of dance and went on to dance with artists such as Pitbull, Ricky Martin, Don Omar, Paulina Rubio, Julianne Hough, Mary J. Blige, and many more. Javier was a fitness instructor/personal trainer for the Tracy Anderson Method.",
        },
        {
          name: "Abbey Woodfin",
          photoUrl: baseUrl + "/images/allStudios/modelfit/5-3_Abby.png",
          bio:
            "Abbey is a NASM certified personal trainer and is studying to be a health coach at the Institute for Integrative Nutrition. She seeks to empower women through nutrition and fitness!",
        },
        {
          name: "Emily Chastain",
          photoUrl: baseUrl + "/images/allStudios/modelfit/5-4_Emily.png",
          bio:
            "Emily's love for movement and dance led her to become a certified barre instructor and ignited her fitness journey. She is so excited to be a part of the modelFIT team! As an instructor, she loves to challenge and encourage clients out of their comfort zone to be their personal best!",
        },
        {
          name: "Estee Beck",
          photoUrl: baseUrl + "/images/allStudios/modelfit/5-5_Estee.png",
          bio: "",
        },
        {
          name: "Jenna Miller ",
          photoUrl: baseUrl + "/images/allStudios/modelfit/5-6_Jenna.png",
          bio: "",
        },
        {
          name: "Paige Sealey",
          photoUrl: baseUrl + "/images/allStudios/modelfit/5-7_Paige.png",
          bio:
            "Fitness has become a huge part of Paige's life and she loves that ModelFIT combines her two passions. Paige is also a barre instructor, offers personal training, and has plans to get her yoga teacher training certification. Catch her in class and sweat it out with some spicy moves!",
        },
        {
          name: "RayAn Michaels",
          photoUrl: baseUrl + "/images/allStudios/modelfit/5-8_RayAn.png",
          bio:
            "RayAn Michales has grown up in movement, studying ballet-jazz-modern from the age of three at Los Angeles Ballet Academy. From here came the journey into Gyrotonic®, Pilates and Neuromuscular Massage Therapy and the move to New York City five years ago. She has been fortunate to travel over the globe teaching fitness at resorts and retreats in her 10 year teaching career and is very excited to continue her growth as an instructor in NYC. She believes that it is through your body that you realize you are a spark of divinity.",
        },
        {
          name: "Sammy Tuchman",
          photoUrl: baseUrl + "/images/allStudios/modelfit/5-9_Sammy.png",
          bio:
            "Born in New York City and raised in Atlanta, Sammy Tuchman has been dancing her whole life. She recently graduated from the University of Arizona with a Bachelor of Fine Arts in Dance and a minor in Gender and Women’s Studies. During her summers, she took part in programs such as the American Ballet Theatre, Rockettes Summer Intensive, Commercial Dance Intensive, and Debbie Allen Dance Academy. Trained in ballet, jazz, modern and tap, she incorporates those skills into her love of health and fitness, and is excited to be a part of the modelFIT team.",
        },
      ],
      howItWorks: baseUrl + "/images/allStudios/modelfit/modelfitabout.png",
      text: {
        title: "modelFIT",
        about: [
          "Welcome, Babe!",
          "ModelFIT is a customized approach to mindful and dynamic movement that builds graceful strength and confident presence both inside and outside the studio. The modelFIT method breaks down large movements to effective and low-impact muscle burning workouts without straining the joints.",
          "Create an oasis away from the chaos and stress of the city and work with purpose and on purpose to cultivate both the mindful and physiological muscles. With each class, you’ll find yourself shaking like a leaf with just a tiny weight.",
          "Made for everyone and open to everyone. Engage, get long, lean, and sculpted with dynamic workouts and now flexible programming with JETSWEAT.",
        ],
        testimonials: [
          {
            text:
              "That’s the genius part: ModelFIT will give a girl a good workout without even fully realizing how good it is.",
            author: "Elle Magazine",
          },
          {
            text:
              "Though the studio has a rep for hosting celebs, ModelFIT is pretty strict on their anti-diva policy",
            author: "Gotham Magazine",
          },
          {
            text:
              "ModelFIT encourages you to slow down and take your workout one step at a time, resulting in a lean, toned body that doesn’t push you to your physical limit. Indeed, research shows that sweating buckets are hardly the be-all and end-all of fitness",
            author: "Huffington Post",
          },
          {
            text:
              "After dutifully attending four to five classes a week, I was surprised to find changes in my body had actually occurred… and by changes, I mean dropping an actual dress size.",
            author: "The Observer",
          },
        ],
      },
      classTypes: [
        "Sculpt",
        "Dance",
        "Cardio",
        "Strength",
        "Prenatal & Postnatal",
      ],
      levels: "allLevels",
      equipment: ["dumbells", "yogaMat", "pilatesCircle", "foamRoller"],
    },
    playlistyoga: {
      combinedLogo:
        baseUrl +
        "/images/allStudios/playlistyoga/playlist_yoga_bw_combined_logo.png",
      logo:
        baseUrl + "/images/allStudios/playlistyoga/playlist_yoga_bw_final.png",
      hero:
        baseUrl + "/images/allStudios/playlistyoga/playlist_yoga_bw_hero.jpg",
      img2:
        baseUrl +
        "/images/allStudios/playlistyoga/playlist_yoga_bw_image_2.jpg",
      about:
        baseUrl + "/images/allStudios/playlistyoga/playlist_yoga_bw_about.jpg",
      instructors: [
        {
          name: "Nicole Sciacca",
          photoUrl:
            baseUrl +
            "/images/allStudios/playlistyoga/playlist_yoga_instructor_nicole.jpg",
          bio:
            "Nicole Sciacca is the Chief Yoga Officer of PLAYLIST. Yoga in West Hollywood, California. Nicole is a yoga teacher, FRCms (functional range conditioning mobility specialist), KINSTRETCH instructor, FRAs (functional range assessment specialist), professional dancer and host. She curates high energy music based flow classes while speaking to conscious, functional movement and yoga philosophy. She brings humor and encouragement to each class while challenging all levels of practitioner.",
        },
        {
          name: "Mikey Budd",
          photoUrl:
            baseUrl + "/images/allStudios/playlistyoga/playlist_yoga_mikey.jpg",
          bio:
            "Mikey is a Jersey Boy and proud of it. Growing up, he was non-stop moving in all types of sports but finally felt balanced after experiencing the calming nature of yoga in California. He carefully crafts each class into a unique experience that leaves you feeling fearless and powerful, and with a little more swag too...",
        },
      ],
      howItWorks:
        baseUrl +
        "/images/allStudios/playlistyoga/playlist_yoga_bw_how_it_works.jpg",
      text: {
        title: "PLAYLIST. Yoga",
        about: [
          "PLAYLIST. Yoga is the premiere lifestyle fitness brand centered around music-fueled yoga workouts.  Located in Los Angeles, our signature PLAYLIST. Yoga and REMIX. classes match your breath to the movement and movement to music, all while improving strength, flexibility and endurance. Our unique style and modern spin on yoga appeals to a broad range of students, both new and advanced. Grab your mat and come get lost in the flow. We promise you will leave feeling energized and inspired.",
        ],
        testimonials: [
          {
            text: "Top Ten Most Instagrammable Gym in the World",
            author: "Mr. Porter",
          },
          {
            text:
              "At PLAYLIST. Yoga, everyone takes the class at their own pace, and the ever-changing hip-hop music playlists made it fun and unpretentious.",
            author: "ENews",
          },
          {
            text:
              "With such a heavy focus on music, it could be easy to get by and fill classes by being more rockstar than real deal. But the beauty of PLAYLIST. Yoga is that they are both. Spearheaded by Nicole Sciacca, the PLAYLIST. Yoga instructors are both music driven and technically sound – inspirational and accessible. There’s no hierarchy or celeb factor here: PLAYLIST. Yoga believes that everyone is a member of the cool kids club whether you’re a newbie or seasoned pro.",
            author: "The Chalkboard Mag",
          },
          {
            text:
              "There’s a yoga studio that was founded on the belief that the fitness community is craving a different experience altogether. PLAYLIST. Yoga is a high-energy yoga studio that creates a practice centered around popular music, where you can vinyasa to the sounds of Taylor Swift or Jimi Hendrix.",
            author: "Luxury Retreats",
          },
        ],
      },
      classTypes: ["Yoga", "Sculpt"],
      levels: "allLevels",
      equipment: ["yogaMat", "dumbells"],
    },
    punchpedalhouse: {
      combinedLogo:
        baseUrl +
        "/images/allStudios/punchpedalhouse/punch_pedal_house_combined_logo.jpg",
      logo:
        baseUrl +
        "/images/allStudios/punchpedalhouse/punch_pedal_house_primary_logo.png",
      hero:
        baseUrl +
        "/images/allStudios/punchpedalhouse/punch_pedal_house_hero.jpg",
      img2:
        baseUrl +
        "/images/allStudios/punchpedalhouse/punch_pedal_house_image_2.jpg",
      about:
        baseUrl +
        "/images/allStudios/punchpedalhouse/punch_pedal_house_about.jpg",
      instructors: [
        {
          name: "JOEY",
          photoUrl:
            baseUrl +
            "/images/allStudios/punchpedalhouse/punch_pedal_house_instructor_joey.png",
          bio:
            "Co-Founder, co-owner and Head Instructor of Workout Design at Punch Pedal House, Joey designed and operated the award-winning fitness studio, Rhythm Ryde. He is an ex-Division-1 football and track athlete at University of Pittsburgh at a one of the top sports University in the US. After an injury, he transferred to NYU receiving an bachelor’s degree in economics, cum laude, and a Master in Real Estate from Columbia University, later pursuing a career in finance and real estate. Joey found his way back to fitness, where his passion and heart stayed. Certified by CFSC - Certified Functional Strength Training, Certified by Gleason’s USA Boxing, and trained by Soulcycle.",
        },
        {
          name: "NADA",
          photoUrl:
            baseUrl +
            "/images/allStudios/punchpedalhouse/punch_pedal_house_instructor_nada.png",
          bio:
            "She is the better half of the co-founders & co-owners, as well as the head of recruiting and training of Punch Pedal House. She is an ex-ballerina and dancer, trained at Ballet School Lujo Davicho Belgrade. She later received a bachelor’s degree in computer science and graphic design but chose to model for nearly a decade. She found her passion for health and fitness while traveling the world modeling for elite brands and luxury designers, wanting to build something that was not a big box company and that would focus on community and social integration. Certified by CFSC - Certified Functional Strength Training, Certified by Gleason’s USA Boxing, and trained by Rhythm Ryde.",
        },
        {
          name: "JERR",
          photoUrl:
            baseUrl +
            "/images/allStudios/punchpedalhouse/punch_pedal_house_instructor_jerr.jpg",
          bio:
            "Jerr is a bonafide New Yorker that believes in giving life to all. That same motto is expected when taking his class. He believes workouts should be fun and effective. Our bodies are all beautiful and different, so it is only natural for us to thrive at different moments. He believes that there are beauty and magic in the struggle. His number one rule is to give it all you have, push past comfort, and to look fucking fabulous while doing it. Certified by Gleason’s USA Boxing, and trained by Rhythm.",
        },
        {
          name: "ANETTE",
          photoUrl:
            baseUrl +
            "/images/allStudios/punchpedalhouse/punch_pedal_house_instructor_anette.jpg",
          bio:
            'Growing up as a professional horseback rider (showjumping) between Norway and Finland, Anette has always had the mentality of "get home a little better than when you left this morning." This mentality translates from sports and business, to life in general. She moved to Brooklyn in 2012 to study Business Management, and is currently Head of Digital at a creative agency in Flatiron. Throughout the years, she tried many gyms in NYC, but the moment she took Joey\'s class, there was no turning back. Pedal House is her escape and haven from a busy life of work and the fast pace of the city, where she gets to leave the world on the other side of that door.',
        },
        {
          name: "G",
          photoUrl:
            baseUrl +
            "/images/allStudios/punchpedalhouse/punch_pedal_house_instructor_g.jpg",
          bio:
            "This Pennsylvanian turned New Yorker is a graphic designer by day, personal trainer and boxing instructor by night. Even with his soft spot for sour gummy worms and anything Stars Wars, G will push you to be your best, inside and outside of class. His hip-hop chart-toppers will have you workin, twerkin’, movin’, and groovin’. NASIM, Certified by Gleason’s USA Boxing and trained by Rhythm.",
        },
        {
          name: "KIKI",
          photoUrl:
            baseUrl +
            "/images/allStudios/punchpedalhouse/punch_pedal_house_instructor_kiki.jpg",
          bio:
            "Kiki is a California native who made her way to New York to play D1 softball at LIU - Brooklyn. There she graduated with a B.S./M.S. in Sports & Exercise Science and found her passion for health and fitness after undergoing sports related surgeries. Kiki found Punch & Pedal through our Latin star, Sara and was hooked after her first boxing class with Joey. In her boxing classes you can find her playing a diverse playlist of Drake to Ed Sheeran and anything that makes your ‘Hood Go Crazy’. Her competitive nature will encourage you to push past your limits and teach you to enjoy the struggle along the way.",
        },
        {
          name: "SARA",
          photoUrl:
            baseUrl +
            "/images/allStudios/punchpedalhouse/punch_pedal_house_instructor_sara.jpg",
          bio:
            "Sara is the Latin twist to the PP Family. She grew up playing competitive golf in Venezuela, at 18 she decided to move New York to play D1 golf and pursue a degree in Marketing. Looking for workout that not only would help her physically but also mentally she stumbled upon RR. After taking her first class with Joey she was hooked; music, lights, intensity of the sweat and most importantly the energy. During her college years she understood the importance of taking care of her body and the process of becoming mindful. (Even though she’s the queen of soft serve) After ending a 13-year competitive golf career, she decided to further the passion she developed for indoor cycling, together with music and dancing and take on the podium to become a PH instructor.",
        },
        {
          name: "DENZ",
          photoUrl:
            baseUrl +
            "/images/allStudios/punchpedalhouse/punch_pedal_house_instructor_denz.jpg",
          bio:
            "This Dominican from the Boogie down Bronx is not only a dedicated and well equipped personal trainer and boxer; he is also passionate and reflects that with his upbeat energy. Being an underdog at 19 years old, he became a co-founder of Dogpound. His desire to see others succeed is unmatched. He goes the extra mile when it comes to motivating his trainees. His ultimate goal is to meet and exceed trainees expectations while setting fun and motivating ambiance to maintain the focus and upbeat energy. His personality is beyond just his physical exterior, which is intriguingly covered by meaningful art from his neck down to his arm. The kindness, patience, and comprehension he provides to his clients are unforgettable.",
        },
        {
          name: "EMMA",
          photoUrl:
            baseUrl +
            "/images/allStudios/punchpedalhouse/punch_pedal_house_instructor_emma.jpg",
          bio:
            '"The first thing I do in the morning is brush my teeth and sharpen my tongue." - Dorothy Parker\nThis sassy sarcastic sweetheart hails from Salt Lake City with an affinity for animals, alliteration, and acoustics. She moonlights as a promotions wizard working at a record label, so prepare your body and your ears for a feisty playlist with deep cuts and undiscovered artists. Sweat me if ya get me.',
        },
      ],
      howItWorks:
        baseUrl +
        "/images/allStudios/punchpedalhouse/punch_pedal_house_how_it_works.jpg",
      text: {
        title: "Punch Pedal House",
        about: [
          "Punch Pedal House is 100% family owned and operated luxury boutique fitness studio with multiple location in Brooklyn. Founded by Joey & Nada Foley, Punch Pedal is the next generation of fitness; it is an experience versus just a workout.",
          "Two foundational group fitness workouts in one studio - Indoor Cycling (Pedal House) & Boxing (Punch House) - made by athletes for everyday people, but it is not for everyone. We focus on people who want to be encouraged or pushed to a higher standard both physically and mentally.",
        ],
        testimonials: [
          {
            text:
              "Named one of 'New York’s best high-intensity workouts' and 'the most intense spin and boxing on the list.'",
            author: "Financial Times",
          },
          {
            text: "The Hottest Spin and Boxing in Brooklyn!",
            author: "Metro NY",
          },
          {
            text: "The Best in the Biz",
            author: "Hamptons",
          },
          {
            text: "The new it-workouts",
            author: "Gotham",
          },
        ],
      },
      classTypes: [
        "Cycling",
        "HIIT",
        "Full Body",
        "Tone",
        "Strength",
        "Mobility",
        "Endurance",
      ],
      levels: "allLevels",
      equipment: ["stationaryBike"],
    },
    rowgatta: {
      combinedLogo:
        baseUrl + "/images/allStudios/rowgatta/rowgatta_combined_logo.jpg",
      logo: baseUrl + "/images/allStudios/rowgatta/rowgatta_primary_logo.png",
      hero: baseUrl + "/images/allStudios/rowgatta/rowgatta_hero.jpg",
      img2: baseUrl + "/images/allStudios/rowgatta/rowgatta_image_2.jpg",
      about: baseUrl + "/images/allStudios/rowgatta/rowgatta_about.jpg",
      instructors: [
        {
          name: "Kenny Rosenzweig",
          photoUrl:
            baseUrl +
            "/images/allStudios/rowgatta/rowgatta_instructor_kenny_rosenzweig.jpg",
          bio:
            "Kenny is a co-founder and coach at Rowgatta. Kenny grew up playing every sport on the list, so fitness has always been an important aspect of his life. Although his organized athletic career is sadly over, staying fit and pushing himself physically (and mentally) is and will always be a part of who he is. Kenny believes that group fitness, in particular, provides the greatest analogy to that feeling of being part of a team, working together to get better each and every day. He tells his Athletes to bring a positive attitude, don't obsess over results, and focus on enjoying the journey. Kenny is a NASM-certified personal trainer and CrossFit Level 1 Trainer.",
        },
        {
          name: "Nadav Ben-Chanoch",
          photoUrl:
            baseUrl +
            "/images/allStudios/rowgatta/rowgatta_instructor_nadav_ben_chanoch.jpg",
          bio:
            "Nadav is a co-founder and coach at Rowgatta. Nadav’s passion for fitness was sparked when he was introduced to CrossFit in 2012. However, after years of high-impact training and a multitude of injuries, Nadav teamed up with Kenny to create a high-intensity, low-impact alternative. Nadav is a certified CrossFit Level 1 Trainer and NASM-CPT. He regularly participates in endurance competitions and is a two-time Ironman.",
        },
        {
          name: "Chris Keyloun",
          photoUrl:
            baseUrl +
            "/images/allStudios/rowgatta/rowgatta_instructor_chris_keyloun.jpg",
          bio:
            "Chris Keyloun is a founding coach and Head of Fitness Programming at Rowgatta. While training as a rower in college, Chris developed a love for weightlifting and gymnastics after discovering CrossFit during his Freshman year. When coaching, Chris pushes his Athletes to strive for virtuosity in each of their movements and encourages individuals to enter the studio with a positive attitude in order to build a motivating community environment. Chris is a certified weighlifter, AFAA Group Fitness Instructor and CrossFit Level 1 and Level 2 Trainer.",
        },
        {
          name: "Alexis Dreiss",
          photoUrl:
            baseUrl +
            "/images/allStudios/rowgatta/rowgatta_instructor_alexis_dreiss.jpg",
          bio:
            "Alexis (or Lex) is a founding coach at Rowgatta. As a former dancer and singer, Lex brings a unique perspective from her experience dancing to classes at Rowgatta. Athletes will recognize an elevated level of discipline and attention to technique when training with Lex. Lex is a NASM certified trainer and has been expanding her repertoire of training to include strength training, sports performance training, boxing, and much more.",
        },
        {
          name: "Julia Gytri",
          photoUrl:
            baseUrl +
            "/images/allStudios/rowgatta/rowgatta_instructor_julia_gytri.jpg",
          bio:
            "Julia Gytri is founding coach at Rowgatta and a certified personal trainer who specializes in strength and endurance training + functional mobility. This former gymnast has been teaching everything from advanced gymnastics, dance cardio, indoor cycling, barre technique, interval running, weight training, and rowing for almost ten years. When working with her personal training clients, she is highly attentive to individualized needs, and works to implement that same 1-on-1 vibe into the classes she teaches.",
        },
      ],
      howItWorks:
        baseUrl + "/images/allStudios/rowgatta/rowgatta_how_it_works.jpg",
      text: {
        title: "Rowgatta",
        about: [
          "Rowgatta is a boutique fitness studio delivering a high-intensity, low-impact (HILIT) bootcamp experience. Hand-selecting the best aspects from their CrossFit, marathon and triathlon training backgrounds, the Rowgatta team developed a concept that maximizes strength and endurance gains with minimal impact on the joints.",
          "The program they created - the Rowgatta Workout - is accessible and effective for Athletes of all skill levels, producing real results to a community of genuine and positive people.",
        ],
        testimonials: [
          {
            text:
              "Rowgatta, described as a ‘high-intensity, low-impact (HILIT) bootcamp experience,’ is ready to take over.",
            author: "AskMen",
          },
          {
            text:
              "This rowing-inspired bootcamp class is the perfect mix of cardio and high intensity – low impact exercise.",
            author: "Bucket Listers",
          },
          {
            text: "It’s a high intensity workout but suitable for all levels.",
            author: "Marcia T.",
          },
          {
            text:
              "I absolutely love coming to Rowgatta because the workouts are so intense but super low impact on your body.",
            author: "Karina M.",
          },
          {
            text:
              "The classes always provide an intense workout supported by encouraging, energetic, and knowledgeable instructors.",
            author: "Erick D.",
          },
          {
            text:
              "Rowgatta is an excellent workout class for anyone looking for something that is low impact, but high intensity.",
            author: "Brian G.",
          },
        ],
      },
      classTypes: ["Rowing", "HILIT"],
      levels: "",
      equipment: ["dumbells"],
    },
    saltsf: {
      combinedLogo: baseUrl + "/images/allStudios/saltsf/JETSWEATxSaltSF.png",
      logo: baseUrl + "/images/allStudios/saltsf/SaltBLACK-black.png",
      hero: baseUrl + "/images/allStudios/saltsf/Salt%20-%2004.jpg",
      img2: baseUrl + "/images/allStudios/saltsf/Salt%20-%2001.jpg",
      about: baseUrl + "/images/allStudios/saltsf/Salt%20-%2002.jpg",
      instructors: [
        {
          name: "Pilar",
          photoUrl: baseUrl + "/images/allStudios/saltsf/Salt%20-%20Pilar.jpg",
          bio: "",
        },
        {
          name: "Hannah",
          photoUrl: baseUrl + "/images/allStudios/saltsf/Salt%20-%20Hannah.jpg",
          bio: "",
        },
        {
          name: "Kelisha",
          photoUrl:
            baseUrl + "/images/allStudios/saltsf/Salt%20-%20Kelisha.jpg",
          bio: "",
        },
      ],
      howItWorks: baseUrl + "/images/allStudios/saltsf/Salt%20Group.jpg",
      text: {
        title: "SALT Fitness",
        about: [
          "SALT Fitness is a female founded boutique studio in the heart of San Francisco. The force behind the brand has created a home for intelligent programming, strength training, community and self development.",
          "Choreographed intentionally to connect both the client and trainer to the mind and body, expressed through the fluidity of movement. We use various functional strength tools to up the ante, like sandbags, resistance bands, lower body loops, and balance balls. No class will be the same and no muscle will be left unworked.",
        ],
        testimonials: [
          // {
          //   text: '',
          //   author: ""
          // }
        ],
      },
      classTypes: ["Sculpt", "Cardio"],
      levels: "intermediate",
      equipment: ["yogaMat"],
    },
    solace: {
      combinedLogo:
        baseUrl + "/images/allStudios/solace/solace-combined-logo.png",
      logo: baseUrl + "/images/allStudios/solace/SOLACE-TITLE-1-1024x140.png",
      hero: baseUrl + "/images/allStudios/solace/solacehero.jpg",
      img2: baseUrl + "/images/allStudios/solace/2%20Solace.png",
      about: baseUrl + "/images/allStudios/solace/3.jpg",
      instructors: [
        // {
        //   name: "Heidi Jones",
        //   photoUrl: "",
        //   bio: ""
        // },
        {
          name: "Andrew Mariani",
          photoUrl:
            baseUrl + "/images/allStudios/solace/5.%201%20of%204_Andrew.jpg",
          bio: "",
        },
        {
          name: "Lulu Faria",
          photoUrl:
            baseUrl + "/images/allStudios/solace/5.%202%20of%204_Lulu.jpg",
          bio: "",
        },
      ],
      howItWorks: baseUrl + "/images/allStudios/solace/solaceabout.png",
      text: {
        title: "Solace",
        about: [
          "Work with the Solace fit community- Solace is open to anyone and everyone. From a hardcore Crossfitter to a yogi, Solace specializes in Olympic weightlifting and traditional strength training and is known for its most balanced and comprehensive fitness programs, and it will help you to increase your general fitness and to reach your goal.",
          "With just dumbells and kettlebells, experience Solace with JETSWEAT with everything from a short 15-minute HIIT workout targeting your core, upper, or lower body. Better yet, grab a partner and get sweating! ",
        ],
        testimonials: [
          {
            text:
              "Joining this family has been and will continue to be one of the best decisions I’ve ever made. My transformation has largely been a product of the infectious mindset that just about every member has in some way: always striving for more.",
            author: "Omari Wallace",
          },
          {
            text:
              "I had a few things in my life that let me down and this isn’t one of them; the community is what pushes me and keeps me going.",
            author: "Elias Veneris",
          },
          {
            text:
              "Thanks to Solace, I’ve never been more confident or happy, not only in the way I look, but in my physical and mental capabilities. I now prioritize working out, not because I feel like I have to, but because I actually enjoy it…",
            author: "Kelly O'Neill",
          },
        ],
      },
      classTypes: ["HIIT", "Strength", "CrossFit", "Cardio"],
      levels: "",
      equipment: ["dumbells", "kettlebell"],
    },
    stretched: {
      combinedLogo:
        baseUrl + "/images/allStudios/stretched/JetSweatXStretch_d.png",
      logo:
        baseUrl +
        "/images/allStudios/stretched/stretched-black-logo-RESIZED.png",
      hero:
        baseUrl + "/images/allStudios/stretched/valerie-who-should-RESIZED.jpg",
      img2:
        baseUrl + "/images/allStudios/stretched/Copy-of-DSC07912-RESIZED.jpg",
      about: baseUrl + "/images/allStudios/stretched/migs-when-RESIZED.jpg",
      instructors: [
        {
          name: "Amanda Freeman",
          photoUrl: baseUrl + "/images/allStudios/stretched/Amanda.png",
          bio: `CEO & Founder of SLT, the boutique ﬁtness brand with 25 locations, Amanda also co-founded Vital Juice, the media brand. Prior to that, her background was in trend forecasting. As a chronic desk sitter, a mother of two young children, a former marathon runner and a boutique ﬁtness devotee, Amanda appreciates the value of active recovery. Amanda received her B.A. from Duke University and her MBA from Harvard.`,
        },
        {
          name: "Vanessa Chu",
          photoUrl:
            baseUrl + "/images/allStudios/stretched/Stretch_d-Vanessa-min.jpg",
          bio: `Vanessa has held senior roles at fast growing retail wellness companies, including Organic Avenue, BluePrint and Project Juice. Prior to that, she worked in investment banking. As a lifelong athlete (gymnast and collegiate diver) and certiﬁed yoga instructor, her passion has been in building wellness businesses. Vanessa attended Wellesley College and received her MBA from Columbia University.`,
        },
        {
          name: "Jeff Brannigan",
          photoUrl:
            baseUrl +
            "/images/allStudios/stretched/Jeff-Brannigan-Stretchd-Program-Director-RESIZED.jpg",
          bio: `Prior to Stretch*d, Jeff started Stretch Science, a stretch business in Washington, D.C. and Charleston, SC. As a former Division I college distance runner, his passion for stretching started when he sustained a hip injury. Jeff has used stretch techniques with college and Olympic athletes. Jeff has a Bachelor’s degree in Health Promotion and a Masters in Health Promotion Management from American University.`,
        },
        {
          name: "Trevor Swaine",
          photoUrl:
            baseUrl + "/images/allStudios/stretched/Stretch_d-Trevor-min.jpg",
          bio: `Trevor is originally from Richmond, VT. He has been in fitness for over 10 years in NYC. As the Director of Fitness at First Ascent Fitness, he built programs for climbers, professional athletes and weekend warriors.  With a dedication to wellness, he is always looking to help others align body, heart and mind.  He holds a degree in International Relations and is also a certified meditation teacher.`,
        },
      ],
      howItWorks:
        baseUrl + "/images/allStudios/stretched/valerie-legs-RESIZED.jpg",
      text: {
        title: "Stretched",
        about: [
          "Stretch*d is a convenient, customized and feel good way to get stretch*d. Benefits include reducing the risk of injury and improving recovery time. Stretch with our expert Stretch*rs who take you through stretch*s to improve flexibility, relieve soreness and accelerate recovery. Visit Stretch*d in Flatiron, NYC or Rye Brook, Westchester, or book a stretch at your home or office. ",
        ],
        testimonials: [
          {
            text:
              "Stretch*d is part of a whole boutique fitness movement focused on recovery. “That’s what sets athletes like Tom Brady apart,” one exercise physiologist told Mateo. “It’s not how hard he trains, it’s how hard he recovers.",
            author: "GQ",
          },
          {
            text:
              "After years of pushing ourselves to the max in workouts, it looks like we're finally getting on board with fitness recovery. This doesn’t mean we’re dropping intense workout sessions altogether, but we're working harder to add a bit of much-needed TLC into the mix.",
            author: "Byrdie",
          },
          {
            text:
              "Get thee to Stretch*d. It's like treating yourself to an awesome massage, except it's more productive, because you also learn skills you can take home. As I sat in my living room binging Big Little Lies two nights later, I slid off the couch and onto the floor, grabbed hold of my ankle, and gave my hamstrings some love.",
            author: "Men's Health",
          },
        ],
      },
      classTypes: ["Stretches"],
      levels: "",
      equipment: [],
    },
    switchplayground: {
      combinedLogo:
        baseUrl +
        "/images/allStudios/switchplayground/switch-combined-logo.png",
      logo:
        baseUrl + "/images/allStudios/switchplayground/switch-logo-black.png",
      hero: baseUrl + "/images/allStudios/switchplayground/1..png",
      img2: baseUrl + "/images/allStudios/switchplayground/2@3x.png",
      about:
        baseUrl +
        "/images/allStudios/switchplayground/3-%20Switch%20Playground%20HIIT%20class%20workout%20smaller.png",
      instructors: [
        {
          name: "Steve Uria",
          photoUrl:
            baseUrl +
            "/images/allStudios/switchplayground/5.%20Steve%20Uria.jpg",
          bio:
            "Steve Uria is an internationally acclaimed visionary whose fitness career has spanned 3 decades. Steve’s programs have revolutionized the fitness industry, including the very first Bootcamp, Ruthless, Blast 900 and SWEAT 1000. Steve’s roots in South Africa’s Special Forces Military training provided him the foundation to push beyond preconceived limits, and pursue personal empowerment – a philosophy he continually practices and a passion he inspires in others. Whether training the many beginners who come to his class, or the professional athletes who continually seek him out such as Oscar de la Hoya, Jamal Lewis, and Charles Barkley, Steve’s mission is simple: to bring out the very best in each client that walks through his fitness studio’s doors, and have them leave feeling stronger and better than when they walked in.",
        },
        {
          name: "Zack Held",
          photoUrl:
            baseUrl + "/images/allStudios/switchplayground/switch5_1.png",
          bio: "",
        },
        {
          name: "Jenna Arndt",
          photoUrl:
            baseUrl + "/images/allStudios/switchplayground/switch5_2.png",
          bio: "",
        },
        {
          name: "Ethan Blauner",
          photoUrl:
            baseUrl + "/images/allStudios/switchplayground/switch5_3.png",
          bio: "",
        },
      ],
      howItWorks:
        baseUrl +
        "/images/allStudios/switchplayground/4.%20mockupswitchplayground.png",
      text: {
        title: "Switch Playground",
        about: [
          "Recruiting talent from Swerve and Shadowbox, SWITCH Playground is not just about getting in shape– it takes you on a journey for the mind, body, and soul. SWITCH is an experiential total body workout that takes you through fast and challenging 2-minute segments with a yoga flow in between. Fuel your energy with blaring beats and dare to torch up to 1,000 calories in just one session.",
        ],
        testimonials: [
          {
            text: "Switch Playground is NYC's buzziest new workout",
            author: "Well+Good",
          },
          {
            text:
              "Switch Playground has been described as NYC's hardest workout on more than one occasion, but it's more like a fitness fiend's paradise! The class, which is structured on a partnership principle, splits participants into pairs and has them go through over a dozen training stations for one of the sweatiest workout you'll ever try.",
            author: "Hannah Bronfman for PopSugar",
          },
          {
            text:
              "It’s not just about getting in shape; we take you on a journey for the mind, body, and soul.",
            author: "Well+Good",
          },
        ],
      },
      classTypes: ["HIIT", "Strength", "Cardio"],
      levels: "intermediate",
      equipment: ["yogaMat", "pilatesCircle", "inflatableBall", "dumbells"],
    },
    thespacebetween: {
      combinedLogo:
        baseUrl +
        "/images/allStudios/thespacebetween/the_space_between_combined_logo.jpg",
      logo:
        baseUrl +
        "/images/allStudios/thespacebetween/the_space_between_logo.png",
      hero:
        baseUrl +
        "/images/allStudios/thespacebetween/the_space_between_hero.jpg",
      img2:
        baseUrl +
        "/images/allStudios/thespacebetween/the_space_between_image_2.jpg",
      about:
        baseUrl +
        "/images/allStudios/thespacebetween/the_space_between_about.jpg",
      instructors: [
        {
          name: "Daryn Schwartz",
          photoUrl:
            baseUrl +
            "/images/allStudios/thespacebetween/the_space_between_instructor_daryn_schwartz.jpg",
          bio:
            "Coming from the business world, Daryn woke up with the ‘I am not doing what I am meant to be doing with my life’ epiphany and set out to create the yoga studio and experience of her dreams. Incorporating all of the elements she felt was missing from the yoga experiences she was having, she opened The Space Between and and has built a beautiful family of students, teachers and staff.  Her teaching inspires and challenges the body, invites the soul to come alive and encourages her students to find the magic that resides in the space between.",
        },
        {
          name: "John Shostrand",
          photoUrl:
            baseUrl +
            "/images/allStudios/thespacebetween/the_space_between_instructor_john_shostrand.jpg",
          bio:
            "John is a dedicated and passionate yoga teacher and practitioner with a deep belief that the body and mind are one rather than separate. John believes we can improve our mind by improving our body and improve our body by improving our mind. Through yoga, John strives to share his knowledge of movement, the body, and healing using his experiences in yoga, physical therapy, and massage.",
        },
      ],
      howItWorks:
        baseUrl +
        "/images/allStudios/thespacebetween/the_space_between_how_it_works.jpg",
      text: {
        title: "THE SPACE BETWEEN",
        about: [
          "T H E  S P A C E  B E T W E E N is a yoga experience designed to get you out of your head and into your soul. We are breathing new life into a beautiful, ancient practice through movement, meditation, breathwork and a refreshing studio experience. Our flows are juicy, our guidance inspiring and our vibes high. Our mission is for you to leave feeling more alive than when you came in. We meet you in the space between where you are and where you want to be, in a place of self-discovery, on a quest for self-love. We give you the time and space to get out of your head and into your soul. Step inside and come alive.",
          "Our classes challenge the status quo - we believe in creativity, while honoring the body and the fundamentals of the yoga practice.  We hold our teachers to a higher standard and challenge them to color outside the lines.  You can expect interesting sequences, unique transitions and attention to alignment and anatomy that will keep your body safe, healthy and inspired.  Whether it’s a flow-based class or one more restorative in nature, you will be taken to new places in body, mind and soul.",
        ],
        testimonials: [],
      },
      classTypes: ["Yoga", "Stretching", "Mobility", "Meditation"],
      levels: "allLevels",
      equipment: ["yogaMat", "block"],
    },
    thestudio: {
      combinedLogo:
        baseUrl + "/images/allStudios/theStudio/theStudio-combined-logo.png",
      logo:
        baseUrl +
        "/images/allStudios/theStudio/TheStudioYogaAbbieGalvin-black.png",
      hero: baseUrl + "/images/allStudios/theStudio/theStudioHero.jpg",
      img2: baseUrl + "/images/allStudios/theStudio/theStudioabout2.png",
      about: baseUrl + "/images/allStudios/theStudio/theStudio1.jpg",
      instructors: [
        {
          name: "Abbie Galvin",
          photoUrl: baseUrl + "/images/allStudios/theStudio/5_1.jpg",
          bio:
            "Abbie Galvin is the owner of The Studio and has been teaching Katonah Yoga for the past 25 years. She has a strong student-base from all around the world and her instruction is informed by her own creative process as a filmmaker and her exploration of the therapeutic process as a psychoanalyst. She has learned over and over that truly participating in any formal process of self-exploration leads to transformation whether it be physical, psychological or intellectual. Her goal is to engage students of yoga in the dialogue between their conscious and their unconscious selves because it is through that effort that we potentiate ourselves.",
        },
        {
          name: "Cassandra Sandberg",
          photoUrl: baseUrl + "/images/allStudios/theStudio/5_2.png",
          bio:
            "Cassandra Sandberg has been practicing yoga for many years and is a dedicated Katonah Yoga instructor. Informed by her work as an actor and performer, she strives to encourage her students through her impeccable intention to detail and her refined adjustments.",
        },
        {
          name: "Jessica Willis",
          photoUrl: baseUrl + "/images/allStudios/theStudio/5_3.png",
          bio:
            "Jessica Willis has been teaching yoga for the past eight years. After teaching and practicing many styles of yoga, she found Katonah Yoga which has influenced her teaching greatly. She teaches from a place of personal experience focusing on form and alignment, with the aim to balance each students nervous system by finding the inherent ease that comes from exerting great effort and enabling them to do the work from the inside out.",
        },
      ],
      howItWorks: baseUrl + "/images/allStudios/theStudio/theStudio4.jpg",
      text: {
        title: "THE STUDIO",
        about: [
          "Katonah Yoga, as taught at The Studio, is a fundamentally formal practice. Our work is a commingling of classical Hatha yoga poses integrated with theories of sacred geometry that serve to develop stability and dimension, Taoist philosophy to observe and conform to nature’s patterns found in the body, peppered with Pranayama and Kundalini to move breath through the body’s terrain, serving as a conduit between the body and mind to soothe the soul and build an identity. The goal of the practice is to become whole, in order to live a life of integrity and happiness.",
          "KATONAH YOGA ® is a syncretic Hatha yoga practice developed by Nevine Michaan of Katonah Yoga Center over 40 years. She and her teachers incorporate classical Hatha yoga with Taoist theory, geometry, magic, mythology, metaphor, and imagination — in a practical framework designed to potentiate personal and communal well-being. Framing the practice, maps of time and personal space are defined and refined. Themes using asana as origami, manipulating form for function, and developing a sense of personal measure are incorporated in Katonah Yoga practices. Katonah Yoga is organized around three principles of esoteric dialogue: all polarities are mediated by trinity; the universe has pattern, pattern belies intelligence; by virtue of repetition there is potential for insight. Disciplined techniques are organized for revelation through revolutions.",
        ],
        testimonials: [
          {
            text:
              "The Studio - the most unique and intelligent yoga experience out there!",
            author: "Jessie Chase (blogger)",
          },
          {
            text: "Katonah Yoga: The Most Powerful Yoga You've Never Heard Of.",
            author: "Luke Storey (podcast)",
          },
          {
            text:
              "Great class - focused on structure, yet not boring or rigid. Instead, it was challenging, interesting, and expansive. I've enjoyed practicing various styles of yoga over the last 25 years, but, as an acupuncturist who works frequently with so many to recover from and prevent injury, I am very careful about my recommendations, needless to say, I'm coming back and adding Katonah to my very short list of referrals.",
            author: "Student",
          },
        ],
      },
      classTypes: ["Yoga", "Meditation"],
      levels: "allLevels",
      equipment: ["yogaMat", "balletBarre", "block"],
    },
    trooperfitness: {
      combinedLogo:
        baseUrl + "/images/allStudios/trooperfitness/trooper-combined-logo.png",
      logo:
        baseUrl + "/images/allStudios/trooperfitness/trooperfitnesslogo.png",
      hero:
        baseUrl + "/images/allStudios/trooperfitness/trooperfitnesshero.jpg",
      img2: baseUrl + "/images/allStudios/trooperfitness/trooperfitness3.jpg",
      about: baseUrl + "/images/allStudios/trooperfitness/trooperfitness1.jpg",
      instructors: [
        // {
        //   name: "Prince Brathwaite",
        //   photoUrl: "",
        //   bio: ""
        // }
      ],
      howItWorks:
        baseUrl + "/images/allStudios/trooperfitness/trooperfitnessabout.png",
      text: {
        title: "Trooper Fitness",
        about: [
          "Trooper Fitness is founded on high-energy, motivational workouts guided by certified coaches. Our wide variety of Trooper Camps follow a personalized group fitness experience able to satisfy anyone's goals. From NYC's top Boot Camp classes to and Strength Training and Met Con, our goal-oriented exercise camps will leave you feeling physically and mentally ready to take on anything! ALL WE KNOW IS GO!",
        ],
        testimonials: [
          {
            text:
              "I'm almost hesitant to write a review because I'm selfish and want these guys all to myself...but Trooper is hands down the best training in NY.",
            author: "Yelp Review",
          },
          {
            text:
              "Trooper Fitness is a very special place. It got me up and going in the early morning. Every class pushed me in the best possible way. When I was done with class almost nothing could ruin my day. The classes are challenging but in a motivating and very accessible way. More than that, the coaches are extremely knowledgeable and see the absolute best in their clients.",
            author: "Yelp Review",
          },
          {
            text: `There's "going to the gym" and then there is Trooper Fitness. If you want to get strong and fit, this is the place for you. The instructors are knowledgeable, creative and encouraging while at the same time tough and intense.`,
            author: "Yelp Review",
          },
        ],
      },
      classTypes: ["Strength", "Cardio"],
      levels: "",
      equipment: [],
    },
    willybcrossfit: {
      combinedLogo: baseUrl + "/images/allStudios/willyb/JetSweatXWillyB.png",
      logo: baseUrl + "/images/allStudios/willyb/WillyB-black.png",
      hero: baseUrl + "/images/allStudios/willyb/WillyBHeroImage-02.jpg",
      img2: baseUrl + "/images/allStudios/willyb/WillyBCrossFit-19.jpg",
      about: baseUrl + "/images/allStudios/willyb/WillyBCrossFit-16.jpg",
      instructors: [
        {
          name: "Abbee",
          photoUrl:
            baseUrl + "/images/allStudios/willyb/WillyBCrossFit-Abbee.jpg",
          bio:
            "Abbee's goal is to make everyone feel comfortable and accomplished in her class no matter the skill level! Since being a former gymnast of 10 years, and suffering a back injury which led to not being able to do gymnastics anymore she found a love for CrossFit. Abbee has been coaching for 3 years and her goal is to bring energy and excitement to the room so this is the best part of your day!",
        },
        {
          name: "Cavan",
          photoUrl:
            baseUrl + "/images/allStudios/willyb/WillyBCrossFit-Cavan.jpg",
          bio:
            "Cavan's goal is to show you that we can accomplish our goals by doing the work every single day, no matter what! Cavan used to weigh nearly 300lbs with a 40 inch waist. After getting his act together, Cavan saw this as an opportunity to identify with people that needed help taking their first steps towards better health. He knew he could help everyone who needed that push. He believes that together, we can #becomebetter!",
        },
        {
          name: "Lexie",
          photoUrl:
            baseUrl + "/images/allStudios/willyb/WillyBCrossFit-Lexie.jpg",
          bio:
            "Lexie helps athletes increase strength, master movements, and enhance endurance - ACE Certified Personal Trainer/Group Fitness Instructor, CrossFit Level 2 Trainer, CrossFit Kids, Precision Nutrition Coach.",
        },
      ],
      howItWorks: baseUrl + "/images/allStudios/willyb/WillyBCrossFit-13.jpg",
      text: {
        title: "WillyB CrossFit",
        about: [
          "WillyB offers CrossFit, Barbell, Crosshit, and Mobility classes for both new and experienced athletes. Programs are designed to advance your technique, build your strength, increase your speed and range of motion activities. All workouts are scalable.",
          "The single most important thing a human can do for their health is exercise regularly. Therefore, a fit BODY is a healthy BODY, and a healthy body leads to a healthy mind and spirit. BODY is a fitness methodology based on the core principles of CrossFit, HIIT, and traditional Strength and Conditioning programs. Body is designed for anyone seeking self-improvement, whatever that means to YOU. Classes are team-oriented to develop social connections, build a passionate fitness community, and encourage participants to push harder than they would on their own. You only have one BODY and each day is an opportunity to improve.",
        ],
        testimonials: [
          {
            text: `This box looks great, every detail covered here. Owners and space are very inviting. This is how a box should look and feel. Excited to spend more and more time here.`,
            author: "Juan Quiles",
          },
          {
            text:
              "Beautiful facility, knowledgeable and welcoming staff. A great place to workout!",
            author: "Lindsay Vanwinkle",
          },
          {
            text:
              "Great instructors- amazing space- if you want to take your fitness to the next level come check it out!!! I'm going on my 5th month and couldn't be happier!!",
            author: "Rafeal Cantreras",
          },
        ],
      },
      classTypes: ["CrossFit", "Barbell", "CrossHIIT", "Mobility"],
      levels: "allLevels",
      equipment: ["dumbells", "kettlebell", "barbell"],
    },
    wrightfit: {
      combinedLogo:
        baseUrl + "/images/allStudios/wrightfit/wrightfit-combined-logo.png",
      logo: baseUrl + "/images/allStudios/wrightfit/TWF_Logo_Black.png",
      hero:
        baseUrl +
        "/images/allStudios/wrightfit/1_HERO_The Wright Fit Glute Strengthener.png",
      img2:
        baseUrl +
        "/images/allStudios/wrightfit/2_The Wright Fit Hex Bar workout.png",
      about:
        baseUrl +
        "/images/allStudios/wrightfit/3_01 and 02_ The Wright Fit Glute Focus.png",
      instructors: [
        {
          name: "Rua Gilna",
          photoUrl:
            baseUrl +
            "/images/allStudios/wrightfit/5_01_The Wright Fit Glute and Abs workout.png",
          bio:
            "Rua has over 13 years of experience in the Fitness Industry in roles ranging from training elite level athletes, training the average Joe, managing multi-million dollar gyms and leading education for teams of 30+ trainers to designing and lecturing on Europe's highest level training certification courses. He has a background in martial arts, gymnastics, breakdancing and yoga among other things. His passion for helping people stems from his own journey through fitness and the confidence it gave him. Specialties include sustainable fat loss, injury rehab and nutrition.",
        },
        {
          name: "Nicole Lund",
          photoUrl:
            baseUrl +
            "/images/allStudios/wrightfit/5_02_TheWrightFit Strengthen.png",
          bio:
            "Nicole Lund (MS, RDN, Clinical Nutritionist) is a registered dietitian and certified personal trainer in NYC. She has expertise in sports and performance and specializes in personalized fitness training for people of various backgrounds and needs.",
        },
      ],
      howItWorks:
        baseUrl +
        "/images/allStudios/wrightfit/4_The Wright Fit Upper Body Sequence.png",
      text: {
        title: "THE WRIGHT FIT",
        about: [
          "The Wright Fit (TWF) is changing the landscape of fitness + wellness. TWF knows that bodies that move best are the ones that look the best. With a holistic approach, TWF strives to help clients achieve their best body and well-being both now and long-term. It's not about stringing together one-HIT wonder workouts to quickly burn out on, but about functional longevity.",
          "Think about the big picture goal: tight abs and a killer physique. Workout with TWF to get unparalleled expertise from the best-in-class trainers to perfect your form and physique, for the all-around wellness you deserve.",
        ],
        testimonials: [
          {
            text: `One way top-tier buildings are adding meaning to health and wellness spaces is by hiring professional teams (The Wright Fit is a favorite) to both design the building’s gym and oversee personal training, diet and fitness programs for residents.`,
            author: "Mansion Global",
          },
          {
            text:
              "Exceptional knowledge and thorough research has been done by Rua for over 10yrs and it shows. A wealth of broad knowledge on Rua's part helped me achieve my own goals and improve my confidence.",
            author: "Sean Treacy",
          },
        ],
      },
      classTypes: ["Strength", "Sculpt"],
      levels: "intermediate",
      equipment: ["dumbells", "kettlebell", "inflatableBall", "barbell"],
    },
    woomcenter: {
      combinedLogo:
        baseUrl + "/images/allStudios/woomcenter/woomcenter-combined-logo.png",
      logo:
        baseUrl +
        "/images/allStudios/woomcenter/WOOM Center Yoga Classes_LOGO.png",
      hero:
        baseUrl +
        "/images/allStudios/woomcenter/Screen%20Shot%202018-04-07%20at%207.23.53%20PM%20copy.png",
      img2: baseUrl + "/images/allStudios/woomcenter/2@3x.png",
      about: baseUrl + "/images/allStudios/woomcenter/1.%20heroNOTRESIZED.JPG",
      instructors: [
        {
          name: "Elian Zach",
          photoUrl:
            baseUrl + "/images/allStudios/woomcenter/5. 1of2_Elian Zach.png",
          bio: "",
        },
        {
          name: "Francesca Bove",
          photoUrl:
            baseUrl + "/images/allStudios/woomcenter/5. 2of2_FrancescaBove.png",
          bio: "",
        },
      ],
      howItWorks: baseUrl + "/images/allStudios/woomcenter/woomcenterabout.png",
      text: {
        title: "Woom Center",
        about: [
          "Woom Center is the holistic lovechild of east and west, tradition and innovation, the personal and the communal. Seamlessly blending ancient practices of yoga and meditation, a futuristic flavor for technology, and a deep desire to stay present. Woom is inspired by many philosophies, yet follows no particular one. It is nondenominational, unbiased and fear-free. It is oneness-centered, pro-choice and love-driven. It is about what brings us together and makes us realize how undeniably connected we are.",
          "We seek to incite curiosity and to find higher states of consciousness. It is not only our right, but also our cosmic responsibility, to grow and transform. We want to make this exploration available for everyone, every day, especially for the high vibrational people of New York City.",
        ],
        testimonials: [
          {
            text:
              "Everything about it feels perfectly designed and each section flows seamlessly into the next, with Bove and Zach switching on and off in terms of instruction.",
            author: "Well+Good",
          },
          {
            text:
              "WOOM, a new spot on the Bowery founded by Elian Zach-Shemesh and David Zach-Shemesh, might just offer the most innovative, unusual experience yet.",
            author: "The Observer",
          },
          {
            text:
              "Although most yogis rely on simple breathing techniques to help students focus, this new boutique studio blindfolds clients at the beginning of class, then plays gongs to grab their attention. Once the blindfolds come off, students can watch a stream of inanimate objects projected on the studio walls to help them transition between poses and find their zen",
            author: "New York Post",
          },
        ],
      },
      classTypes: [
        "WOOM Experience I",
        "WOOM Experience II",
        "WOOM Experience III",
      ],
      levels: "allLevels",
      equipment: ["yogaMat", "block"],
    },
    yogavida: {
      combinedLogo:
        baseUrl + "/images/allStudios/yogavida/yogavida-combined-logo.png",
      logo: baseUrl + "/images/allStudios/yogavida/yogavidalogo.png",
      hero: baseUrl + "/images/allStudios/yogavida/hero.jpg",
      img2: baseUrl + "/images/allStudios/yogavida/outdoor1.png",
      about: baseUrl + "/images/allStudios/yogavida/about.png",
      instructors: [
        {
          name: "Dina Ivas",
          photoUrl:
            baseUrl + "/images/allStudios/yogavida/5. 2of11_Dina Ivas.jpg",
          bio: "",
        },
        {
          name: "Lauren Yandoc",
          photoUrl:
            baseUrl + "/images/allStudios/yogavida/5. 4of11_Lauren Yandoc.jpg",
          bio: "",
        },
        {
          name: "Erica Chen",
          photoUrl:
            baseUrl + "/images/allStudios/yogavida/5. 5of11_Erica Chen.jpg",
          bio: "",
        },
        {
          name: "Jessica Rapp",
          photoUrl:
            baseUrl + "/images/allStudios/yogavida/5. 6of11_Jessica Rapp.jpg",
          bio: "",
        },
        {
          name: "Amy Wolfe",
          photoUrl:
            baseUrl + "/images/allStudios/yogavida/5. 7of11_Amy Wolfe.jpg",
          bio: "",
        },
        {
          name: "Will Schneider",
          photoUrl:
            baseUrl +
            "/images/allStudios/yogavida/5. 8of11_ Will Schneider.jpg",
          bio: "",
        },
        {
          name: "Jen Carter",
          photoUrl:
            baseUrl + "/images/allStudios/yogavida/5. 9of11_Jen Carter.jpg",
          bio: "",
        },
      ],
      howItWorks: baseUrl + "/images/allStudios/yogavida/outdoor2.jpg",
      text: {
        title: "Yoga Vida",
        about: [
          "Yoga Vida makes yoga accessible and inclusive - without judgment, pressure or demands. This is yoga for everyone! The Yoga Vida method connects purposeful movements with conscious breath, and their educational approach improves body awareness and promotes individual choice. Yoga Video believes in thinking independently to practice what you learn into your life. Their classes may give you a sweet set of abs and a well-contoured backside, but these are merely physical benefits of a consistent practice. Walking on your hands and touching your toes are discoveries along the path - not the destination. In a traditional sense, yoga seeks peace of mind and happiness. Asana alone may not get you there, but it opens the door to awareness in all forms. At Yoga Vida, they use physical practice as an entry point to a calm, steady, and undisturbed mind. They are committed to beginning and continuing your education with quality yoga at an affordable price.",
        ],
        testimonials: [
          {
            text: `This is the case at Yoga Vida, where all classes follow a common Vinyasa-style formula a flow, or series of fluid movements to warm the muscles, followed by a series of held standing poses. The difficulty varies, but in each class there’s a wide spread of beginners and seasoned yogis—you won’t feel out of place.`,
            author: "Vanity Fair ",
          },
        ],
      },
      classTypes: ["Yoga"],
      levels: "",
      equipment: [],
    },
  },
  icons: {
    tesimonialSml: baseUrl + "/images/icons/testimonial-icon-sml.png",
    classTypes: baseUrl + "/images/icons/Class Types.png",
    intermediate: baseUrl + "/images/icons/intermediate-10.png",
    advance: baseUrl + "/images/icons/advance-10.png",
    allLevels: baseUrl + "/images/icons/all levels-10.png",
    dumbells: baseUrl + "/images/icons/dumbells-08.png",
    kettlebell: baseUrl + "/images/icons/kettle bell-08.png",
    inflatableBall: baseUrl + "/images/icons/inflatable ball-08.png",
    barbell: baseUrl + "/images/icons/barbell-08.png",
    yogaMat: baseUrl + "/images/icons/yoga mat-08.png",
    pilatesCircle: baseUrl + "/images/icons/pilates circle-08.png",
    slidingDiscs: baseUrl + "/images/icons/sliding%20discs-08.png",
    balletBarre: baseUrl + "/images/icons/ballet%20barre-08.png",
    block: baseUrl + "/images/icons/block-08.png",
    foamRoller: baseUrl + "/images/icons/foam%20roller-08.png",
    stationaryBike: baseUrl + "/images/icons/stationary%20bike-08.png",
    treadmill: baseUrl + "/images/icons/treadmill-08.png",
    resistanceBand: baseUrl + "/images/icons/resistance band-08.png",
  },
  compatibleDevices: {
    laptop: baseUrl + "/images/compatibledevices/laptop-icon-@2x.png",
    phone: baseUrl + "/images/compatibledevices/phone-logo@2x.png",
    chromecast: baseUrl + "/images/compatibledevices/chromcast-logo@2x.png",
    airPlay: baseUrl + "/images/compatibledevices/airplay-logo@2x.png",
    hdmi: baseUrl + "/images/compatibledevices/HDMI.png",
  },
};
