import React, { useState, useEffect, useRef } from "react";
import Comment from "./Comment";
import { PieChart, Pie, Cell } from "recharts";
import {
  StruggleData,
  ActivationData,
  FeelBetterData,
  ExperienceData,
  CommentsData,
} from "../assets/data";
import Dashboard_1 from "../assets/images/Dashboard_1.png";
import Dashboard_2 from "../assets/images/Dashboard_2.svg";
import Dashboard_3 from "../assets/images/Dashboard_3.svg";
import Dashboard_4 from "../assets/images/Dashboard_4.png";
import Half_Circle from "../assets/images/half-circle.png";
import Card_Onboarding from "../assets/images/cards/01_Onboarding.svg";
import Card_Launch from "../assets/images/cards/02_Launch.svg";
import Card_Personalized from "../assets/images/cards/03_Personalized.svg";
import Card_Population from "../assets/images/cards/04_Population.svg";
import Meeting2 from "../assets/images/Meeting2.png";
import closeButton from "../../../assets/Vector.png";
import Modal from "react-modal";
import "../styles/RituWell.css";
import SingleImageCarousel from "./SingleImageCarousel";

const RituWell = () => {
  const RING_COLORS = ["#598275", "#E0E0E0"];
  const StruggleRate = StruggleData[0].value;
  const activationRate = ActivationData[0].value;
  const feelBetterRate = FeelBetterData[0].value;
  const experienceRate = ExperienceData[0].value;
  const [openModal, setOpenModal] = useState(false);
  const sectionRef = useRef(null);

  // Hubspot react forms
  useEffect(() => {
    console.log("react hubspot");
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "23498037",
          formId: "9649ccc1-a6e6-4a67-80bd-4cce292e497c",
          target: "#hubspotFormModal",
        });
      }
    });

    return () => {
      // Clean up: Remove the script from the document on component unmount
      document.body.removeChild(script);
    };
  }, [openModal]);

  const handleOpenModal = (e) => {
    e.preventDefault();
    setOpenModal(true);
    document.body.classList.add("workplace-modal-open");
  };
  const handleCloseModal = (e) => {
    e.preventDefault();
    setOpenModal(false);
    document.body.classList.remove("workplace-modal-open");
  };
  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="dashboard-container">
        <div className="intro-rituwell-container" data-aos="fade-down">
          <div className="intro-text-container">
            <h3>NEW ERA OF WORKPLACE WELLBEING</h3>
            <strong>
              <h1>Meet RituWell</h1>
            </strong>
            <p>
              RituWell is a well-being and longevity platform that helps
              employers engage and activate populations to live their healthiest
              lives.
            </p>
          </div>
          <div className="button-container">
            <button className="dark-button" onClick={handleOpenModal}>
              Request a demo
            </button>
            <button className="light-button" onClick={scrollToSection}>
              See how it works
            </button>
          </div>
        </div>
        <div id="gradient-elipse"></div>
        <div id="admin-white-background">
          <div className="dashboard-img-container">
            <div className="dashboard-1-img" data-aos="fade-up">
              <img src={Dashboard_1} alt="" />
            </div>
          </div>
          <div
            className="dashboard-2-img"
            data-aos="fade-right"
            data-aos-delay="300"
          >
            <img src={Dashboard_2} alt="" />
          </div>
          <div
            className="dashboard-3-img"
            data-aos="fade-left"
            data-aos-delay="300"
          >
            <img src={Dashboard_3} alt="" />
          </div>
          <div
            className="dashboard-4-img"
            data-aos="fade-left"
            data-aos-delay="300"
          >
            <img src={Dashboard_4} alt="" />
          </div>
        </div>
      </div>

      <div className="transition-container-rituwell">
        <div className="transition-chart-container">
          <div className="donut-chart-container">
            <p>{StruggleRate}%</p>
            <PieChart width={274} height={274}>
              <Pie
                data={StruggleData}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={80}
                fill="#689385"
              >
                {StruggleData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={RING_COLORS[index % RING_COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </div>

          <div className="transition-text-container">
            <h2 data-aos="fade-up">
              Over half of American employees report struggling with well-being.
            </h2>
            <h2 className="subtitle" data-aos="fade-up" data-aos-delay="300">
              <span>RituWell</span>{" "}
              <span className="mobile-break">
                <br />
              </span>{" "}
              can fix that!
            </h2>
          </div>
        </div>
      </div>
      <div className="how-it-works-container" ref={sectionRef}>
        <h1>
          How <span>RituWell</span> works
        </h1>
        <div className="cards-container" data-aos="fade-left">
          <img src={Card_Onboarding} alt="" className="card-container" />
          <img src={Card_Launch} alt="" className="card-container" />
          <img src={Card_Personalized} alt="" className="card-container" />
          <img src={Card_Population} alt="" className="card-container" />
        </div>
      </div>
      <div className="options-container">
        <img src={Half_Circle} alt="half-circle" />

        <img src={Half_Circle} alt='half-circle' />

        <div className='options-studios-slider'>
        </div>
        <div className="options-text-container" data-aos="fade-down">
          <h1>
            Meet Your Team's Unique Needs: Tailored Solutions for Every Interest
          </h1>
          <p>
            We offer diverse wellness options through one platform, helping
            employees personalize and embrace a healthy life style
          </p>
        </div>

        <div className='options-iphone-slider'>
          <SingleImageCarousel />
        </div>
      </div>
      <div className="impact-container">
        <strong>
          <h1>
            Discover our <span className="highlight">Impact</span> on Employers
          </h1>
        </strong>
        <div className="impact-chart-container">
          <div className="impact-text-container">
            <div className="donut-chart-container">
              <p>{activationRate}%</p>
              <PieChart width={274} height={274}>
                <Pie
                  data={ActivationData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={80}
                  fill="#689385"
                >
                  {ActivationData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={RING_COLORS[index % RING_COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <h3>
              Average employee
              <br />
              activation rate
            </h3>
          </div>
          <div className="impact-text-container">
            <div className="donut-chart-container">
              <p>{feelBetterRate}%</p>
              <PieChart width={274} height={274}>
                <Pie
                  data={FeelBetterData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={80}
                  fill="#689385"
                >
                  {FeelBetterData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={RING_COLORS[index % RING_COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <h3>
              Almost all employees
              <br />
              feel better after class
            </h3>
          </div>
          <div className="impact-text-container">
            <div className="donut-chart-container">
              <p>{experienceRate}%</p>
              <PieChart width={274} height={274}>
                <Pie
                  data={ExperienceData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={80}
                  fill="#689385"
                >
                  {ExperienceData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={RING_COLORS[index % RING_COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <h3>Large majority of employees experience something new</h3>
          </div>
        </div>
      </div>
      <div className="voice-container">
        <h1>
          <span className="highlight">Voices</span> of Experience
        </h1>
        <div className="comments-container">
          {CommentsData.map((commentData, index) => (
            <Comment
              key={index}
              userName={commentData.userName}
              userImage={commentData.userImage}
              rating={commentData.rating}
              comment={commentData.comment}
            />
          ))}
        </div>
      </div>
      <div className="ending-container">
        <img src={Meeting2} alt="" />
        <div className="ending-text-container">
          <div>
            <h1>
              We are excited to foster a culture of{" "}
              <span className="highlight">Well-being</span> with you
            </h1>
            <h2>Anytime. Anywhere.</h2>
          </div>
          <button className="dark-red-button" onClick={handleOpenModal}>
            Book a demo
          </button>
        </div>
      </div>

      <Modal
        style={{ overlay: { backgroundColor: "rgba(0,0,0,0.75" } }}
        isOpen={openModal}
        className={`workplace-modal ${openModal ? "open" : ""}`}
        shouldCloseOnOverlayClick={true}
      >
        <div className="modal-main">
          <button className="close-modal-button" onClick={handleCloseModal}>
            <img src={closeButton} />{" "}
          </button>
          <div className="modal-header">
            <h3>Get In Contact</h3>
            <p>Ask about our flexible pricing options!</p>
          </div>
          <div id="hubspotFormModal" className="hubspot-form"></div>
        </div>
      </Modal>
    </>
  );
};

export default RituWell;
