import { ActionTypes } from "redux/actions/favoriteclasstypes.actions";
import {
  takeLatest,
  call,
  all,
  put,
  takeEvery,
  select,
  take,
  race,
} from "redux-saga/effects";
import { post } from "../../api";

export const getFaveClassTypes = function* (action) {
  let { headers } = action.payload;

  try {
    const response = yield call(
      post,
      "/api/favoriteClasses/myFavoriteClasses",
      {},
      {
        authorization: headers,
      }
    );

    const data = yield response.json();
    const favoriteClasstypes = data.content;

    if (response.status >= 200 && response.status < 300 && data.status) {
      yield put({
        type: ActionTypes.GET_ALL_FAVE_CLASSTYPES_SUCCESS,
        favoriteClasstypes: favoriteClasstypes,
      });
    } else {
      throw data;
    }
  } catch (error) {
    yield put({
      type: ActionTypes.GET_ALL_FAVE_CLASSTYPES_FAILURE,
      error: error,
    });
  }
};

export const addFaveClassTypes = function* (action) {
  let { headers, classId, callbackUpdate } = action.payload;

  try {
    const response = yield call(
      post,
      "/api/favoriteClasses/add",
      {
        classId: classId,
      },
      {
        authorization: headers,
      }
    );
    const data = yield response.json();
    //debugger

    if (callbackUpdate && data.status) {
      callbackUpdate();
    }
    //debugger
    if (response.status >= 200 && response.status < 300 && data.status) {
      yield put({
        type: ActionTypes.ADD_FAVORITE_CLASSTYPES_SUCCESS,
        newRecord: data.content,
      });
    } else throw data;
  } catch (error) {
    yield put({
      type: ActionTypes.ADD_FAVORITE_CLASSTYPES_FAILURE,
      error: error,
    });
  }
};

export const unfavoriteClassTypes = function* (action) {
  const { headers, instanceId, callback } = action.payload;

  try {
    const response = yield call(
      post,
      "/api/favoriteClasses/delete",
      {
        id: instanceId,
      },
      {
        authorization: headers,
      }
    );

    const data = yield response.json();
    //debugger
    // pass down favClass to both callback inorder to change and remove
    if (callback && data.status) {
      callback();
    }

    if (response.status >= 200 && response.status < 300 && data.status) {
      yield put({
        type: ActionTypes.UNFAVORITE_CLASSTYPES_SUCCESS,
        deleted: data.content,
      });
    } else throw data;
  } catch (error) {
    yield put({
      type: ActionTypes.UNFAVORITE_CLASSTYPES_FAILURE,
      error: error,
    });
  }
};

function* favoriteclasstypes() {
  yield all([
    takeLatest(ActionTypes.GET_ALL_FAVE_CLASSTYPES, getFaveClassTypes),
    takeEvery(ActionTypes.ADD_FAVORITE_CLASSTYPES, addFaveClassTypes),
    takeEvery(ActionTypes.UNFAVORITE_CLASSTYPES, unfavoriteClassTypes),
  ]);
}

export default favoriteclasstypes;
