import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Footer from "./footer.js";
import hrImage from "../assets/workplace_wellness_hr.png";
import igniteImg from "../assets/ignitingBusiness.png";
import grayEclipseImg from "../assets/GrayEclipse.svg";
import EightyThree from "../assets/eightythree.png";
import SeventyFive from "../assets/seventyfive.png";
import EightFive from "../assets/eightyfive.png";
import closeButton from "../assets/Vector.png";
import peopleRunning from "../assets/people_running.jpeg";
import studentsWalking from "../assets/students.png";
import healthImg from "../assets/health.png";
import mentalImg from "../assets/mental.png";
import academicImg from "../assets/academic.png";
import fifty from "../assets/Group 306.png";
import seventyFour from "../assets/Group 386.png";
import twentyFive from "../assets/Group 387.png";
import women from "../assets/women.png";

import "./Education.css";

//const Education = () => {
//    return (
//    <div>
//        Hello
//        HI
//    </div>
//    )
//};
//
//export default Education;

const Education = () => {
  const [text, setText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    function updateText() {
      if (window.innerWidth < 625) {
        setText(`JETSWEAT
        Campus Wellness`);
      } else {
        setText("JETSWEAT CAMPUS WELLNESS");
      }
    }

    updateText();

    window.addEventListener("resize", updateText);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateText);
    };
  }, []);

  // Hubspot react forms
  useEffect(() => {
    console.log("react hubspot");
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "23498037", // Replace with your HubSpot portal ID
          formId: "e102e4cf-d99d-4876-81a2-095dde10a7d2", // Replace with your form ID
          target: "#hubspotFormModalEducation",
        });
      }
    });

    return () => {
      // Clean up: Remove the script from the document on component unmount
      document.body.removeChild(script);
    };
  }, [openModal]);

  // Using up and down key to scroll
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (openModal) {
        switch (e.key) {
          case "ArrowUp":
            handleScroll(-1);
            break;
          case "ArrowDown":
            handleScroll(1);
            break;
          default:
            break;
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openModal]);

  const handleScroll = (direction) => {
    const modalContent = document.getElementsByClassName("modal-main");

    if (modalContent) {
      modalContent[0].scrollTop += direction * 20; // Adjust the scrolling speed as needed
      setScrollPosition(modalContent.scrollTop);
    }
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    setOpenModal(true);
    document.body.classList.add("workplace-modal-open");
  };
  const handleCloseModal = (e) => {
    e.preventDefault();
    setOpenModal(false);
    document.body.classList.remove("workplace-modal-open");
  };

  return (
    <>
      <div className="image-container-education">
        <img
          className="first-img-education"
          src={peopleRunning}
          alt="People Running"
        />
        <div className="overlay-container-education">
          <div className="overlay-main-education">
            <h1 className="main-title-education">{text}</h1>

            <p className="first-paragraph-education">
              Provide students with an all-encompassing platform that
              facilitates success through health and wellness.
            </p>

            <button
              className="first-button-education"
              onClick={handleOpenModal}
            >
              Get in Contact
            </button>
          </div>
        </div>
      </div>

      <div className="two-columns-education">
        {/* column left */}
        <div className="campus-recreation-programming">
          <h2 className="campus-recreation-programming-header">
            Campus Recreation Programming
          </h2>
          <p className="campus-recreation-programming-body">
            Our digital programming accommodates busy schedules and unique
            preferences while fostering a sense of inclusion, enabling students
            and faculty to stay active and connected regardless of their
            physical location.
          </p>
        </div>

        {/* column right */}
        <div className="student-column">
          <img
            src={studentsWalking}
            alt="students walking"
            className="student-image"
          />
          <div className="student-text">
            <h2 className="student-header"> Reach every student</h2>
            <p className="student-body">
              With JETSWEAT individuals have the power to choose when and where
              to stream workouts and meditations ; from dorm rooms to campus
              gyms. Most workouts include minimal equipment and limited space.
              Classes can be accessed on the app for iphone for on-the-go
              access, and be streamed through TVs at home through Chromecast,
              Roku or HDMI.
            </p>
          </div>
        </div>
      </div>

      {/* Education Section */}
      <div className="education-needs">
        <div>
          <h2 className="education-header">
            Support Well-Being to Unlock Student’s Full-Potential
          </h2>
          <p2 className="education-body">
            A healthy body fuels a healthy mind, JETSWEAT takes a holistic{" "}
            <br />
            approach in providing tailored resources to the campus community.
          </p2>
        </div>

        <div className="three-columns-education">
          <div className="healthy-column">
            <img
              src={healthImg}
              className="healthy-img-size"
              alt="Health Icon"
            />
            <div className="education-icon-and-header">
              <div className="education-column-header">
                <h2>Empower Physical Health</h2>
              </div>
            </div>
            <p className="healthy-column-body">
              With 30+ diverse studio partners, JETSWEAT offers students a range
              of on-demand workouts for beginners to advanced skill levels. With
              JETSWEAT, students can track improvement in fitness, energy, and
              stamina.
            </p>
          </div>

          <div className="healthy-column">
            <img
              src={mentalImg}
              className="healthy-img-size"
              alt="Check on Board Icon"
            />
            <div className="education-icon-and-header">
              <div className="education-column-header">
                <h2>Enhance Mental Resilience</h2>
              </div>
            </div>
            <p className="healthy-column-body">
              Our programming provides customized resources to support emotional
              well-being. Students can engage in mindfulness and
              stress-reduction activities, connect with fellow students and find
              relaxation amidst the chaos.
            </p>
          </div>
          <div className="healthy-column">
            <img
              src={academicImg}
              className="healthy-img-size"
              alt="Down Arrow Icon"
            />
            <div className="education-icon-and-header">
              <div className="education-column-header">
                <h2>Achieve Academic Excellence</h2>
              </div>
            </div>
            <p className="healthy-column-body">
              Engaging in regular physical activity has been proven to enhance
              cognitive function, memory retention, and concentration levels.
              JETSWEAT has the power to enable students’ to excel in their
              coursework.
            </p>
          </div>
        </div>
      </div>
      {/* Education Section Ends*/}

      {/* Empower Health Section */}
      <div className="education-empower-background">
        <div>
          <h2 className="education-empower-header">
            Elevate the Campus Experience for Holistic Success
          </h2>
          <p className="education-empower-main-p">
            College students seek assistance in stress reduction and wellness
            support.
          </p>
          <div className="three-columns-business">
            <div className="education-column-campus">
              <div className="overlapping-percentage">
                <img
                  src={grayEclipseImg}
                  alt="85 percent"
                  className="percent-first-img"
                />
                <img
                  src={fifty}
                  alt="50 percent"
                  className="percent-second-img"
                />
              </div>

              <div className="education-empower-text">
                <p className="education-empower-paragraph">
                  College students who report their academic success is
                  negatively affected by their physical health and well-being.
                </p>
              </div>
            </div>

            <div className="education-column-campus">
              <div className="overlapping-percentage">
                <img
                  src={grayEclipseImg}
                  alt="83 percent"
                  className="percent-first-img"
                />
                <img
                  src={seventyFour}
                  alt="74 percent"
                  className="percent-seventyfour-img"
                />
              </div>
              <p className="education-empower-paragraph">
                College students with financial aid who report they want to
                reduce stress through excercise.{" "}
              </p>
            </div>

            <div className="education-column-campus">
              <div className="overlapping-percentage">
                <img
                  src={grayEclipseImg}
                  alt="75 percent"
                  className="percent-first-img"
                />
                <img
                  src={twentyFive}
                  alt="25 percent"
                  className="percent-twentyfive-img"
                />
              </div>
              <p className="education-empower-paragraph">
                College students who report their wellness facilities need
                improvement.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Empower Health Section Ends */}

      {/* Ignite Section */}
      <div className="education-ignite-container">
        <div className="education-ignite-text">
          <h2 className="education-ignite-header">
            Pave the Way for a Thriving College Experience
          </h2>
          <p2 className="education-ignite-paragraph">
            Facilitate your students’ success through a comprehensive and
            transformational approach.
          </p2>

          <div>
            <button className="second-button" onClick={handleOpenModal}>
              Get in Touch
            </button>
          </div>
        </div>

        <img
          src={women}
          alt="Hand holding plants"
          className="education-ignite-image"
        />
      </div>
      {/* Ignite Session Ends */}

      <Modal
        style={{ overlay: { backgroundColor: "rgba(0,0,0,0.75" } }}
        isOpen={openModal}
        className={`workplace-modal ${openModal ? "open" : ""}`}
        shouldCloseOnOverlayClick={true}
      >
        <div className="modal-main">
          <button className="close-modal-button" onClick={handleCloseModal}>
            <img src={closeButton} />{" "}
          </button>
          <div className="modal-header">
            <h3>Get In Contact</h3>
            <p>Ask about our flexible pricing options!</p>
          </div>
          <div id="hubspotFormModalEducation" className="hubspot-form"></div>
        </div>
      </Modal>

      <Footer />
    </>
  );
};

export default Education;
