import React, { useEffect, useState } from "react";
import { WhiteLabelCheckoutConfirmationPdf } from "./WhiteLabelCheckoutConfirmationPdf";
import { WhiteLabelCheckoutConfirmationSubscription } from "./WhiteLabelCheckoutConfirmationSubscription";
import { WhiteLabelCheckoutConfirmationCredits } from "./WhiteLabelCheckoutConfirmationCredits";

export const WhiteLabelCheckoutConfirmation = ({
  history,
  whiteLabelBackgroundStyles,
  currentUser,
  studio,
}) => {
  const [product, setProduct] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const searchParams = new URLSearchParams(window?.location?.search);
  const searchParamProductId = searchParams.get("productId") ?? "";

  const authToken = currentUser.authToken;

  useEffect(() => {
    if (searchParamProductId.length === 0) return;

    fetch(`/api/products/list?productIds=${searchParamProductId}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken,
      },
    })
      .then((res) => {
        if (res.status >= 400) {
          throw new Error("Unable to fetch Products");
        }
        return res.json();
      })
      .then((result) => {
        const products = result?.content?.products || [];

        if (products.length > 0) {
          setProduct(products[0]);
        } else {
          setHasError(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => {
        setHasError(true);
      });
  }, []);

  if (isLoading || hasError || !product) {
    return <></>;
  }

  const isEmailProduct = product?.type === "email";
  const isSubscriptionProduct = product?.type === "subscription";
  const isCredits = product?.type === "credit";

  if (isEmailProduct) {
    return (
      <WhiteLabelCheckoutConfirmationPdf
        product={product}
        studio={studio}
        currentUser={currentUser}
        whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
      />
    );
  } else if (isSubscriptionProduct) {
    return (
      <WhiteLabelCheckoutConfirmationSubscription
        product={product}
        studio={studio}
        currentUser={currentUser}
        whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
      />
    );
  } else if (isCredits) {
    return (
      <WhiteLabelCheckoutConfirmationCredits
        product={product}
        studio={studio}
        currentUser={currentUser}
        whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
      />
    );
  }

  history.push(`newplatform/dashboard${searchParams}`);
  return <></>;
};
