import { put, call, all, takeLatest, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "redux/actions/userfriends.actions";
import { get, post, patch, deleteRequest } from "redux/api";

const basePath = "/api/user/friends";

export const addFriend = function* (action) {
  try {
    const { friendUserId, headers } = action.payload;
    const response = yield call(
      post,
      basePath,
      {
        friendId: friendUserId,
      },
      headers
    );
    const data = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: ActionTypes.ADD_FRIEND_SUCCESS, success: true });
    } else {
      throw data;
    }
  } catch (error) {
    yield put({
      type: ActionTypes.ADD_FRIEND_FAILURE,
      errorMessage: error.message,
    });
  }
};

export const updateFriendRequest = function* (action) {
  try {
    const { userFriendId, accepted, headers } = action.payload;
    const response = yield call(
      patch,
      basePath,
      {
        userFriendId,
        accepted,
      },
      headers
    );
    const data = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: ActionTypes.UPDATE_FRIEND_REQUEST_SUCCESS });
    } else {
      throw data;
    }
  } catch (error) {
    yield put({
      type: ActionTypes.UPDATE_FRIEND_REQUEST_FAILURE,
      errorMessage: "",
    });
  }
};

export const removeFriend = function* (action) {
  try {
    const { friendId, userFriendId, headers } = action.payload;
    const response = yield call(
      deleteRequest,
      basePath,
      {
        friendId,
        userFriendId,
      },
      headers
    );
    const data = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: ActionTypes.REMOVE_FRIEND_SUCCESS });
    } else {
      throw data;
    }
  } catch (error) {
    yield put({ type: ActionTypes.REMOVE_FRIEND_FAILURE, errorMessage: "" });
  }
};

export const getFriends = function* (action) {
  try {
    const { headers } = action.payload;
    const response = yield call(get, basePath, "", headers);
    const data = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ActionTypes.GET_FRIENDS_SUCCESS,
        friends: data.content,
      });
    } else {
      throw data;
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_FRIENDS_FAILURE, errorMessage: "" });
  }
};

export const getFriendRequests = function* (action) {
  try {
    const { headers } = action.payload;
    const response = yield call(get, basePath, "?pending=true", headers);
    const data = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ActionTypes.GET_FRIEND_REQUESTS_SUCCESS,
        requests: data.content,
      });
    } else {
      throw data;
    }
  } catch (error) {
    yield put({
      type: ActionTypes.GET_FRIEND_REQUESTS_FAILURE,
      errorMessage: "",
    });
  }
};

export const sendEmailInvite = function* (action) {
  try {
    const { email, headers } = action.payload;
    const response = yield call(
      post,
      `${basePath}/invite`,
      {
        email,
      },
      headers
    );
    const data = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: ActionTypes.SEND_EMAIL_INVITE_SUCCESS, success: true });
    } else {
      throw data;
    }
  } catch (error) {
    yield put({
      type: ActionTypes.SEND_EMAIL_INVITE_FAILURE,
      errorMessage: "",
    });
  }
};

export const sendTextInvite = function* (action) {
  try {
    const { phone, headers } = action.payload;
    const response = yield call(
      post,
      `${basePath}/invite`,
      {
        phone,
      },
      headers
    );
    const data = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: ActionTypes.SEND_TEXT_INVITE_SUCCESS });
    } else {
      throw data;
    }
  } catch (error) {
    yield put({ type: ActionTypes.SEND_TEXT_INVITE_FAILURE, errorMessage: "" });
  }
};

export const searchFriends = function* (action) {
  try {
    const { email, headers } = action.payload;
    const response = yield call(post, `${basePath}/search`, { email }, headers);
    const data = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ActionTypes.SEARCH_FRIENDS_SUCCESS,
        result: { ...data.content },
      });
    } else {
      throw data;
    }
  } catch (error) {
    yield put({
      type: ActionTypes.SEARCH_FRIENDS_FAILURE,
      errorMessage: "User not found! Invite them by using the links below",
    });
  }
};

function* userfriends() {
  yield all([
    takeEvery(ActionTypes.ADD_FRIEND, addFriend),
    takeEvery(ActionTypes.REMOVE_FRIEND, removeFriend),
    takeEvery(ActionTypes.GET_FRIENDS, getFriends),
    takeEvery(ActionTypes.GET_FRIEND_REQUESTS, getFriendRequests),
    takeEvery(ActionTypes.UPDATE_FRIEND_REQUEST, updateFriendRequest),
    takeEvery(ActionTypes.SEND_EMAIL_INVITE, sendEmailInvite),
    takeEvery(ActionTypes.SEND_TEXT_INVITE, sendTextInvite),
    takeEvery(ActionTypes.SEARCH_FRIENDS, searchFriends),
  ]);
}

export default userfriends;
