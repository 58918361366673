import { put, call, all, takeLatest, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "redux/actions/events.actions";
import { patch, post } from "redux/api";

const basePath = "/api/events";

export const joinLiveClass = function* (action) {
  try {
    const { liveClassId, userAgent, browser } = action.payload;

    const response = yield call(
      post,
      `${basePath}/live-class`,
      {
        liveClassId,
        userAgent,
        browser,
      },
      {
        authorization: localStorage.authToken,
      }
    );

    const data = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ActionTypes.JOIN_LIVE_CLASS_SUCCESS,
        liveClassAttendance: data.content,
      });
    } else {
      throw data;
    }
  } catch (error) {
    yield put({ type: ActionTypes.JOIN_LIVE_CLASS_FAILURE });
  }
};

export const leaveLiveClass = function* (action) {
  try {
    const {
      liveClassAttendanceId,
      cameraOn,
      cameraOnDurationSeconds,
    } = action.payload;
    const response = yield call(
      patch,
      `${basePath}/live-class/${liveClassAttendanceId}`,
      {
        cameraOn,
        cameraOnDurationSeconds,
      },
      {
        authorization: localStorage.authToken,
      }
    );
    const data = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: ActionTypes.LEAVE_LIVE_CLASS_SUCCESS });
    } else {
      throw data;
    }
  } catch (error) {
    yield put({ type: ActionTypes.LEAVE_LIVE_CLASS_FAILURE });
  }
};

function* events() {
  yield all([
    takeEvery(ActionTypes.JOIN_LIVE_CLASS, joinLiveClass),
    takeEvery(ActionTypes.LEAVE_LIVE_CLASS, leaveLiveClass),
  ]);
}

export default events;
