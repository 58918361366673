import React from "react";

const Ending = ({ compatibleDevices }) => (
  <div className="ending">
    <div className="backgroundimgcontainer">
      <p className="h2 section-title mt-0 ml-0">
        Access the web platform from anywhere
      </p>
      <p className="ending-subtext font-size-md">
        JETSWEAT currently has an iOS app and a web-based platform, so you can
        do JETSWEAT from your iPhone or laptop on-the-go, or connect your device
        to a TV screen to immerse yourself in the full studio experience.
      </p>
    </div>

    <div className="pure-u-md-1-1  box-1-1">
      <div className="pure-u-1-5 box-1-2">
        <img
          className="pure-img"
          src={compatibleDevices.laptop}
          alt="Laptop Icon"
        />
        Web
      </div>
      <div className="pure-u-1-5 box-1-2">
        <img
          className="pure-img"
          src={compatibleDevices.phone}
          alt="Mobile Icon"
        />
        Mobile
      </div>
      <div className="pure-u-1-5 box-1-2">
        <img
          className="pure-img"
          src={compatibleDevices.chromecast}
          alt="Chromecast Icon"
        />
        Chromecast
      </div>
      <div className="pure-u-1-5 box-1-2">
        <img
          className="pure-img"
          src={compatibleDevices.airPlay}
          alt="Airplay Icon"
        />
        Airplay
      </div>
      <div className="pure-u-1-5 box-1-2">
        <img
          className="pure-img"
          src={compatibleDevices.hdmi}
          alt="HDMI Icon"
        />
        HDMI
      </div>
    </div>
  </div>
);

export default Ending;
