import React, { Component } from "react";
import { Helmet } from "react-helmet";

class Contact extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>JETSWEAT | FAQ</title>
          <meta name="description" content=""></meta>
        </Helmet>
        <div>
          <h2
            style={{ marginLeft: "80px", paddingTop: "3em" }}
            className="aboutheading section-title"
          >
            {" "}
            Contact Us{" "}
          </h2>
          <p style={{ marginTop: "2em", marginLeft: "80px" }}>
            {" "}
            We love to hear from you! Send us your feedback, questions or
            comments to support@jetsweatfitness.com.{" "}
          </p>
        </div>
      </div>
    );
  }
}

export default Contact;
