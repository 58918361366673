const IncludeHubSpot = (isInPlatform) => {
  if (!isInPlatform) {
    const tag = document.createElement("script");
    tag.setAttribute("id", "hs-script-loader");
    tag.setAttribute("type", "text/javascript");
    tag.setAttribute("src", "//js.hs-scripts.com/6858870.js");
    document.getElementsByTagName("body")[0].appendChild(tag);
  }
};

export default IncludeHubSpot;
