import React from "react";
import JetsweatModal from "../JetsweatModal";
import branch from "branch-sdk";
import { EmailWarning, PasswordWarning } from "../account/formHelpers";
import { Link } from "react-router-dom";

class SignupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      password_confirm: "",
      email_warning: false,
      password_warning: false,
      isSubmitting: false,
      error: "",
    };
  }

  handlePasswordChange = (event) => {
    this.handleChange(event);
    if (event.target.value === this.state.password) {
      this.setState({ password_warning: false });
    } else {
      this.setState({ password_warning: true });
    }
  };

  handleEmailChange = (event) => {
    this.handleChange(event);
    if (this.state.email_warning && event.target.value.includes("@")) {
      this.setState({ email_warning: false });
    }
  };
  handleChange = (event) => {
    const fieldName = event.target.name;
    this.setState({ [fieldName]: event.target.value });
  };
  validateEmail = (event) => {
    const email = event.target.value;
    if (!email.includes("@") && email) {
      this.setState({ email_warning: true });
    } else {
      this.setState({ email_warning: false });
    }
    // Track user on branch by email id
    branch.setIdentity(email);
  };
  registerUser = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true }, () => {
      if (this.state.password !== this.state.password_confirm) {
        return this.setState({ password_warning: true, isSubmitting: false });
      }
      if (this.state.email_warning) {
        return this.setState({ isSubmitting: false });
      }
      return fetch(
        `/api/user/register/${
          this.props.whiteLabel ? this.props.studio.shortName : ""
        }`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            userInfo: {
              email: this.state.email,
              password: this.state.password,
              firstName: this.state.firstname,
              lastName: this.state.lastname,
            },
          }),
        }
      )
        .then((res) => res.json())
        .then((body) => {
          if (!body.status) {
            return this.setState({ error: body.content, isSubmitting: false });
          }
          if (body.status) {
            // const currUser = body.content;
            // this.props.setCurrUser(currUser);
            this.props.closeModal();
          }
          branch.track("user-signup", {});
          return this.props.loginFromToken(body.content.authToken);
        })
        .catch((err) => {
          console.error(err);
          return this.setState({
            error: "Unknown error. Please try later.",
            isSubmitting: false,
          });
        });
    });
  };

  render() {
    const { whiteLabelBackgroundStyles, whiteLabel } = this.props;
    console.debug("why this doesn't get pushed");
    const errDiv = this.state.error ? (
      <div className="Center Padding">
        <div className="errormessage">{this.state.error}</div>
      </div>
    ) : (
      ""
    );

    return (
      <JetsweatModal
        showModal={this.props.showModal}
        closeModal={this.props.closeModal}
        title={this.props.title}
        whiteLabelBackgroundStyles={this.props.whiteLabelBackgroundStyles}
        preventCloseModal={this.props.preventCloseModal}
        customStyles={this.props.customStyles}
      >
        <form onSubmit={this.registerUser}>
          <div
            className="Padding"
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            {errDiv}
            <label style={{ textAlign: "left" }}>Email address</label>
            <input
              autoFocus
              id="signupemail"
              type="text"
              name="email"
              onChange={this.handleEmailChange}
              onBlur={this.validateEmail}
              className="Input-style Font9 No-highlight-border"
              placeholder="Email"
            />
            <EmailWarning warn={this.state.email_warning} />
            <label style={{ textAlign: "left" }}>First name</label>
            <input
              id="signupfirstname"
              type="text"
              required
              name="firstname"
              onChange={this.handleChange}
              className="Input-style Font9 No-highlight-border"
              placeholder="First Name"
            />
            <label style={{ textAlign: "left" }}>Last name</label>
            <input
              id="signuplastname"
              type="text"
              required
              name="lastname"
              onChange={this.handleChange}
              className="Input-style Font9 No-highlight-border"
              placeholder="Last Name"
            />
            <label style={{ textAlign: "left" }}>Password</label>
            <input
              id="signuppassword"
              type="password"
              required
              name="password"
              onChange={this.handleChange}
              className="Input-style Font9 No-highlight-border"
              placeholder="Password"
            />

            <label style={{ textAlign: "left" }}>Re-enter password</label>
            <input
              id="signuppasswordretype"
              type="password"
              required
              name="password_confirm"
              onFocus={this.passwordMatch}
              onChange={this.handlePasswordChange}
              className="Input-style Font9 No-highlight-border"
              placeholder="Confirm Password"
            />
          </div>
          <PasswordWarning warn={this.state.password_warning} />

          <button
            disabled={
              this.state.isSubmitting ||
              this.state.password_warning ||
              this.state.email_warning
            }
            type="submit"
            className="Button-style Font9 signup-btn"
            style={{
              width: "100%",
              backgroundColor: whiteLabelBackgroundStyles
                ? whiteLabelBackgroundStyles.backgroundColor
                : "#9ac8b9",
            }}
          >
            {this.state.isSubmitting ? "Loading...." : "Create My Account"}
          </button>

          <div style={{ padding: "10px" }}>
            <span style={{ paddingRight: "5px" }}>
              Already have an account?
            </span>
            <span
              className="hover"
              onClick={() => {
                this.props.closeModal();
                this.props.loginModal();
              }}
              style={{
                color: whiteLabel
                  ? whiteLabelBackgroundStyles.backgroundColor
                  : "#9ac8b9",
                textDecoration: "none",
                fontSize: 16,
              }}
            >
              Sign in
            </span>
          </div>
        </form>
      </JetsweatModal>
    );
  }
}

export default SignupModal;
