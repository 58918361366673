import { getToken, checkHttpStatusPromise, parseJSON } from "./utils";

export default function (endpoint, fetchArgs = {}) {
  const givenHeaders = fetchArgs.headers || {};
  const token = getToken();
  let body = fetchArgs.body || null;
  const contentType = givenHeaders["Content-Type"];

  if (
    body &&
    !(body instanceof FormData) &&
    (!contentType || contentType === "application/json")
  ) {
    givenHeaders["Content-Type"] = "application/json";
    body = JSON.stringify(body);
  }

  const fetchInit = {
    method: "GET",
    ...fetchArgs,
    body,
    headers: {
      Authorization: token || null,
      ...givenHeaders,
    },
  };

  return fetch(`/${endpoint}`, fetchInit)
    .then(checkHttpStatusPromise)
    .then(parseJSON);
}
