import React from "react";

export const EmailWarning = (props) => {
  if (!props.warn) {
    return null;
  }
  return (
    <div className="errormessage">Please enter a valid email address.</div>
  );
};

export const PasswordWarning = (props) => {
  if (!props.warn) {
    return null;
  }
  return (
    <div className="errormessage">Please type the same password again.</div>
  );
};
