import React, { Component, useState, useEffect } from "react";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { isSafari } from "react-device-detect";
import branch from "branch-sdk";
import {
  CardNumberElement,
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import CardSection from "./cardsection";
import Promo from "./promo";

const YEARLY_PRICE = 239.99;
const MONTHLY_PRICE = 19.99;

class CheckoutForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promo: "",
      error: "",
      stall: false,
      isLoading: false,
      monthlyPaymentRequest: {},
      annualPaymentRequest: {},
    };
  }

  componentDidMount() {
    ReactGA.initialize(process.env.ANALYTICS_ID);
    branch.init("key_live_birB93AdBoSzWQwgtkp9vmdlryn2vOgO");
  }

  handleChange = (event) => {
    const fieldName = event.target.name;
    this.setState({ [fieldName]: event.target.value });
  };

  setPromo = (promo) => {
    return this.setState({ promo });
  };

  handleSubmit = async (event) => {
    const { stripe, elements, redirectToAccount } = this.props;
    if (event.preventDefault) {
      event.preventDefault();
    }

    if (this.state.stall || !stripe || !elements) {
      return null;
    }

    this.setState({ stall: true, isLoading: true });
    const packageSub = setPackageId(this.props.subscription, this.props.apiKey);

    const cardElement = elements.getElement(CardNumberElement);
    const tokenRes = await stripe.createToken(cardElement);

    this.setState({ isLoading: false });

    if (!tokenRes.token) {
      return this.setState({
        stall: false,
        error: (
          <p>
            <span>
              An error occurred and payment information could not be verified.
            </span>
            <br />
            <span>
              Please try again in a few minutes or contact your bank to approve
              the transaction.
            </span>
            <br />
            <span>
              If the problem persists, contact support@jetsweatfitness.com.
            </span>
            <br />
          </p>
        ),
      });
    } else if (this.props.subscription === "daypass") {
      // fetch('/api/user/daypass/purchase', {
      //   method: 'POST',
      //   headers: {
      //     'Content-type': 'application/json',
      //     Authorization: this.props.authToken
      //   },
      // body: JSON.stringify({
      //   source: 'website',
      //   passDate: this.props.date
      // })
      // }
    } else {
      fetch("/api/user/subscribe", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: this.props.authToken,
        },
        body: JSON.stringify({
          source: "website",
          packageId: packageSub,
          token: tokenRes.token.id,
          promo: this.state.promo,
        }),
      })
        .then(async (body) => {
          const data = await body.json();
          const { status } = body;
          if (status < 200 || status >= 300) {
            this.setState({
              error: data.content,
              stall: false,
              isLoading: false,
            });
            return;
          }
          let subscriptionId = null;
          if (body.content) {
            subscriptionId = body.content.stripeSubscriptionId;
          }

          const isYearly = this.props.subscription === "yearly";
          const price = isYearly ? YEARLY_PRICE : MONTHLY_PRICE;

          const commerceData = {
            transaction_id: subscriptionId,
            currency: "USD",
            revenue: price,
            shipping: 0,
            tax: 0,
          };

          const metadata = [
            {
              $content_schema: "COMMERCE_PRODUCT",
              $og_title: "Jetsweat Subscription",
              $og_description: "Jetsweat Web Subscriber",
              $canonical_identifier: "jetsweat/subscriber",
              $price: price,
              $quantity: 1,
              $product_name: "Subscription",
              $keywords: ["subscription"],
              $condition: "NEW",
            },
          ];

          // Track yearly/monthly subscriber
          branch.track(isYearly ? "yearly-trial" : "monthly-trial", {
            price: price,
            stripeId: subscriptionId,
          });

          // Track purchase event;
          ReactPixel.track("PURCHASE", { metadata, commerceData });
          branch.trackCommerceEvent("purchase", commerceData, metadata);

          // Google Tag to track yearly vs monthly
          const yearlyGtagId = "AW-809931747/AKoGCPHu3IgBEOOnmoID";
          const monthlyGtagId = "AW-809931747/pfWECLqn64gBEOOnmoID";

          // Google Track Subscription
          window.gtag("event", "conversion", {
            send_to: isYearly ? yearlyGtagId : monthlyGtagId,
            value: price,
            currency: "USD",
            transaction_id: subscriptionId,
          });

          if (body.status === 200) {
            console.log("Success!");
            redirectToAccount();
          } else {
            if (body.content.includes("No such coupon")) {
              this.setState({
                error: body.content,
                stall: false,
              });
            }
          }
        })
        .then(() => {
          ReactGA.event({
            category: "Subscription",
            action: "Create a subscription",
          });
          const isYearly = this.props.subscription === "yearly";
          const price = isYearly ? YEARLY_PRICE : MONTHLY_PRICE;

          ReactGA.plugin.require("ecommerce");
          ReactGA.plugin.execute("ecommerce", "addTransaction", {
            id: !isYearly ? "monthly-subscription" : "yearly-subscription",
            revenue: price,
          });
          ReactGA.plugin.execute("ecommerce", "send");
          ReactPixel.track("Purchase", {
            currency: "USD",
            value: price,
          });
          window.pintrk("track", "checkout", {
            value: price,
            order_quantity: 1,
            currency: "USD",
          });
        });
    }
  };

  render() {
    const {
      subscription,
      stripe,
      authToken,
      apiKey,
      redirectToAccount,
    } = this.props;
    const { promo } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        {this.state.error ? (
          <div className="errormessage Center">{this.state.error}</div>
        ) : (
          ""
        )}
        {isSafari && stripe && (
          <ApplePayForm
            redirectToAccount={redirectToAccount}
            subscription={subscription}
            promo={promo}
            authToken={authToken}
            apiKey={apiKey}
            gtag={window.gtag}
          />
        )}
        <CardSection {...this.props} />
        <Promo setPromo={this.setPromo} authToken={authToken} />
        <div className="Center Padding">
          <button
            className="Button-style Font signup-button"
            type="submit"
            disabled={!stripe}
          >
            {this.state.isLoading ? "Processing... " : "Confirm Order"}
          </button>
        </div>
      </form>
    );
  }
}

const setPackageId = (subtype, apiKey) => {
  if (apiKey === "pk_test_FDIF46WIWsv9PiwoMSrWJaHy") {
    if (subtype === "yearly") {
      return "com.jetsweat-test.yearly.7daystrial";
    } else if (subtype === "monthly") {
      return "com.jetsweat-test.monthly.7daystrial";
    }
  }
  if (apiKey !== "pk_test_FDIF46WIWsv9PiwoMSrWJaHy") {
    if (subtype === "yearly") {
      return "jetsweat_yearly_7day";
    } else if (subtype === "monthly") {
      return "com.jetsweat.monthly.7daystrial";
    }
  }
};

const ApplePayForm = (args) => {
  const {
    subscription,
    gtag,
    promo,
    authToken,
    apiKey,
    redirectToAccount,
  } = args;
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [error, setError] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const stripe = useStripe();

  const paymentIntentRef = React.useRef(paymentIntent);

  async function fetchPaymentIntent(subscription, apiKey) {
    let packageId;
    if (subscription !== "daypass") {
      packageId = setPackageId(subscription, apiKey);
    }

    const res = await fetch("api/user/subscription/payment-intent", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify({
        packageId,
      }),
    });
    res
      .json()
      .then((res) => {
        paymentIntentRef.current = res.content;
        setPaymentIntent(res.content);
      })
      .catch((err) => setError(err));
  }

  async function createPaymentRequest(authToken, subscription) {
    const isYearly = subscription === "yearly";
    const paymentRequest = await stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: `JetSweat Subscription ${isYearly ? "Annual" : "Monthly"}`,
        amount: subscription === "yearly" ? 2399 : 1999,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    paymentRequest.on("token", async (ev) => {
      const { complete, token } = ev;
      const clientSecret = paymentIntentRef.current;

      if (!clientSecret)
        setError("There was an issue with Apple Pay please try a credit card.");

      const packageSub = setPackageId(subscription, apiKey);
      fetch("/api/user/subscribe", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify({
          source: "website",
          packageId: packageSub,
          token: token.id,
          promo: promo,
        }),
      })
        .then(async (body) => {
          const data = await body.json();
          const { status } = body;
          if (status < 200 || status >= 300) {
            this.setState({
              error: data.content,
              isLoading: false,
              stall: false,
            });
            return;
          }
          complete("success");
          let subscriptionId = null;
          if (body.content) {
            subscriptionId = body.content.stripeSubscriptionId;
          }
          const isYearly = subscription === "yearly";
          const price = isYearly ? YEARLY_PRICE : MONTHLY_PRICE;
          const commerceData = {
            transaction_id: subscriptionId,
            currency: "USD",
            revenue: price,
            shipping: 0,
            tax: 0,
          };
          const metadata = [
            {
              $content_schema: "COMMERCE_PRODUCT",
              $og_title: "Jetsweat Subscription",
              $og_description: "Jetsweat Web Subscriber",
              $canonical_identifier: "jetsweat/subscriber",
              $price: price,
              $quantity: 1,
              $product_name: "Subscription",
              $keywords: ["subscription"],
              $condition: "NEW",
            },
          ];
          branch.track(isYearly ? "yearly-trial" : "monthly-trial", {
            price: price,
            stripeId: subscriptionId,
          });
          branch.trackCommerceEvent(
            "purchase",
            commerceData,
            metadata,
            console.log
          );
          const yearlyGtagId = "AW-809931747/AKoGCPHu3IgBEOOnmoID";
          const monthlyGtagId = "AW-809931747/pfWECLqn64gBEOOnmoID";
          gtag("event", "conversion", {
            send_to: isYearly ? yearlyGtagId : monthlyGtagId,
            value: price,
            currency: "USD",
            transaction_id: subscriptionId,
          });

          if (body.status === 200) {
            redirectToAccount();
          } else {
            if (body.content.includes("No such coupon")) {
              setError("No such coupon exists");
            }
          }
        })
        .then(() => {
          ReactGA.event({
            category: "Subscription",
            action: "Create a subscription",
          });
          const isYearly = subscription === "yearly";
          const price = isYearly ? YEARLY_PRICE : MONTHLY_PRICE;
          ReactGA.plugin.require("ecommerce");
          ReactGA.plugin.execute("ecommerce", "addTransaction", {
            id: !isYearly ? "monthly-subscription" : "yearly-subscription",
            revenue: price,
          });
          ReactGA.plugin.execute("ecommerce", "send");
          ReactPixel.track("Purchase", {
            currency: "USD",
            value: price,
          });
          window.pintrk("track", "checkout", {
            value: price,
            order_quantity: 1,
            currency: "USD",
          });

          // Handle result.error or result.paymentIntent
        });
    });

    paymentRequest.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(paymentRequest);
      }
    });

    paymentRequest.on("cancel", async (event) => {
      setError("Apple Pay was cancelled. You may try again.");
    });
  }

  useEffect(() => {
    if (authToken && paymentIntent === null && error === null) {
      fetchPaymentIntent(subscription, apiKey);
    }
    if (paymentRequest === null && subscription) {
      createPaymentRequest(authToken, subscription);
    }
  }, [authToken, paymentIntent, error, paymentRequest, promo]);

  if (paymentRequest) {
    return (
      <>
        <div className="apple-pay-form">
          {error ? <div className="errormessage Center">{error}</div> : ""}
          <br />
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
        <p className="apple-pay-note">
          Promo codes will be applied at checkout.
        </p>
      </>
    );
  }
  return null;
};

export default CheckoutForm;
