import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, NavLink } from "react-router-dom";
import "../footer.css";
import "./liveschedule.css";
import "../../views/New_Platform/Live/live.css";
import CardTopBar from "../../views/New_Platform/Dashboard/CardTopBar";
import Day from "../../views/New_Platform/Dashboard/Day";
import AlreadySubscribedModal from "./AlreadySubscribed";
import PaymentModal from "./PaymentModal";
import Footer from "../footer";
import { loadStripe } from "@stripe/stripe-js";
import OrderSummaryModal from "./OrderSummaryModal";
import LoginModal from "./LoginModal";
import SignupModal from "./SignupModal";
import {
  getPurchasedLiveClasses,
  fetchLiveVideosJetsweatPublic,
} from "redux/actions/liveclass.actions";
import JetsweatModal from "components/JetsweatModal";
import * as CalendarUtil from "../../views/Calendar/CalendarUtil";
import moment from "moment";
import { Nav, NavItem, TabContent, TabPane } from "reactstrap";

class LiveSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dates: [],
      selectedDate: new Date(),
      calendarDate: new Date(),
      alreadySubscribedModal: false,
      paymentModal: false,
      orderSummaryModal: false,
      loginModal: false,
      signupModal: false,
      selectedClass: {},
      stripeApiKey: "",
      bookedLiveClasses: [],
      bookedSingleClass: [],
      newOrderAmount: "",
      isPersonalTraining: false,
    };
    this.days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
    this.months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.getCurrentMonth = this.getCurrentMonth.bind(this);
    this.getCurrentWeek = this.getCurrentWeek.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.bookClass = this.bookClass.bind(this);
    this.getBookedClass = this.getBookedClass.bind(this);
    this.getNewOrderAmount = this.getNewOrderAmount.bind(this);
  }

  componentDidMount() {
    // getPurchasedLiveClasses({"Authorization": currUser.authToken})

    let headers = {};
    if (localStorage.authToken) {
      headers["Authorization"] = localStorage.authToken;
    }

    this.getCurrentWeek();

    if (this.props.whiteLabel) {
      this.props.fetchLiveVideosJetsweatPublic(this.props.studio.id, headers);
    } else {
      this.props.fetchLiveVideosJetsweatPublic(null, headers);
    }
    fetch("/api/basic/stripe", {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        if (res.status >= 400) {
          console.log("error!");
        } else {
          return res.json();
        }
      })
      .then((rslt) => {
        this.setState({
          stripeApiKey: (rslt.content || {}).publishable || "",
        });

        return rslt;
      });

    if (this.props.currUser !== null) {
      this.getBookedClass();
    }
  }

  getNewOrderAmount(amount) {
    this.setState({
      newOrderAmount: amount,
    });
  }

  getBookedClass() {
    fetch("/api/workout/myWorkout", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: this.props.currUser.authToken,
      },
      body: JSON.stringify({
        fromDate: moment().format("YYYY-MM-DD"),
        toDate: moment().add(1, "year").format("YYYY-MM-DD"),
        when: new Date(Date.now()),
        authUserInfo: { id: this.props.currUser.id },
      }),
    })
      .then((res) => res.json())
      .then((res) =>
        CalendarUtil.updateWorkoutStatus(
          res.content.workouts,
          this.props.currUser.authToken
        )
      )
      .then((workouts) => {
        const uniqueWorkouts = workouts.filter((item) => {
          if (item.hasOwnProperty("liveClass")) {
            return item.liveClass;
          }
        });
        const liveClasses = uniqueWorkouts.reduce((unique, o) => {
          if (!unique.some((obj) => obj.liveClass.id === o.liveClass.id)) {
            unique.push(o);
          }
          return unique;
        }, []);

        this.setState({ bookedLiveClasses: liveClasses });
      });
  }

  getCurrentWeek(nextWeek = false, previousWeek = false) {
    const dt = this.state.calendarDate;
    const dateArray = [];
    if (nextWeek) dt.setDate(dt.getDate() + 7);
    if (previousWeek) dt.setDate(dt.getDate() - 7);
    this.setState({ calendarDate: dt });
    while (dt.getDay() != 1) {
      dt.setDate(dt.getDate() - 1);
    }
    dateArray.push(new Date(dt));
    while (dt.getDay() != 0) {
      dt.setDate(dt.getDate() + 1);

      dateArray.push(new Date(dt));
    }
    this.setState({ dates: dateArray });
  }

  getCurrentMonth(nextMonth = false, prevMonth = false) {
    const d = new Date();
    const prevMonthIsCurrentMonth =
      this.state.dates[this.state.dates.length - 1].getMonth() - 1 ==
      d.getMonth()
        ? true
        : false;

    const dt = this.state.calendarDate;

    const dateArray = [];
    if (nextMonth) dt.setMonth(dt.getMonth() + 1);
    if (prevMonth) dt.setMonth(dt.getMonth() - 1);
    if (prevMonth && prevMonthIsCurrentMonth) {
      dt.setDate(d.getDate());
    } else {
      dt.setDate(1);
    }
    this.setState({ calendarDate: dt });
    while (dt.getDay() != 1) {
      dt.setDate(dt.getDate() - 1);
    }
    dateArray.push(new Date(dt));
    while (dt.getDay() != 0) {
      dt.setDate(dt.getDate() + 1);

      dateArray.push(new Date(dt));
    }
    this.setState({ dates: dateArray });
  }

  compareDate(date1, date2) {
    return (
      date1.getMonth() === date2.getMonth() &&
      date1.getDay() === date2.getDay() &&
      date1.getDate() === date2.getDate()
    );
  }

  compareMonth(date1, date2) {
    return date1.getMonth() === date2.getMonth();
  }

  bookClass(classData) {
    const checkClass = this.state.bookedLiveClasses.filter(
      (item) => item.liveClass.id === classData.id
    );
    if (checkClass.length > 0) {
      this.setState({ bookedSingleClass: checkClass });
      return;
    }
    this.setState({ bookedSingleClass: [] });

    let time = classData.scheduledDatetime;
    let newTime = new Date(time);
    let m = moment(newTime);
    let modifiedTime = m.utc().startOf("minute").toISOString();
    fetch("/api/workout/add", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: this.props.currUser.authToken,
      },
      body: JSON.stringify({
        authUserInfo: this.props.currUser,
        videoId: null,
        liveClassId: classData.id,
        status: this.state.status,
        when: modifiedTime,
      }),
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  isLoggedIn(item) {
    if (this.props.currUser) {
      if (
        this.props.currUser.subscription &&
        this.props.currUser.subscription.active &&
        this.props.currUser.subscription.packageId != "jetsweat_class"
      ) {
        this.setState({ alreadySubscribedModal: true });
        this.bookClass(item);
      } else {
        this.setState({ paymentModal: true });
      }
    } else {
      this.setState({ signupModal: true });
    }
  }

  render() {
    const {
      studio,
      whiteLabel,
      success,
      sendLiveClassBookingConfirmation,
    } = this.props;
    let stripePromise = null;
    if (this.state.stripeApiKey !== "") {
      stripePromise = loadStripe(this.state.stripeApiKey);
    }
    let hasPersonalTraining = false;

    let liveVideos = this.props.liveVideos
      .filter((video) =>
        this.compareDate(
          this.state.selectedDate,
          new Date(video.scheduledDatetime)
        )
      )
      .filter((video) => {
        if (!this.props.whiteLabel) {
          return video.isOnJetsweat === 1;
        }
        return true;
      })
      .filter((video) => {
        const videoIsPersonalTraining = video?.product?.availability === 1;

        if (videoIsPersonalTraining) {
          hasPersonalTraining = true;
        }

        // We are filtering for group sessions.
        if (!this.state.isPersonalTraining) {
          return !videoIsPersonalTraining;
        } else {
          return videoIsPersonalTraining;
        }
      });
    if (liveVideos && liveVideos.length > 1) {
      console.log("Before Live : " + JSON.stringify(liveVideos));
      liveVideos.sort(
        (a, b) => new Date(a.scheduledDatetime) - new Date(b.scheduledDatetime)
      );
      console.log("Live : " + JSON.stringify(liveVideos));
    }

    return (
      <>
        <AlreadySubscribedModal
          showModal={this.state.alreadySubscribedModal}
          closeModal={() => this.setState({ alreadySubscribedModal: false })}
          selectedClass={this.state.selectedClass}
          bookedClass={this.state.bookedSingleClass}
          bookedFunc={this.getBookedClass}
          title={
            whiteLabel
              ? "You’re already a subscriber!"
              : "You’re already a JETSWEAT subscriber!"
          }
          whiteLabelBackgroundStyles={this.props.whiteLabelBackgroundStyles}
          preventCloseModal
        />
        <PaymentModal
          success={success}
          showModal={this.state.paymentModal}
          stripePromise={stripePromise}
          closeModal={() => this.setState({ paymentModal: false })}
          title={"Confirm live class booking"}
          customStyles={{ content: { width: "35%" } }}
          currUser={this.props.currUser}
          orderSummaryModal={() => this.setState({ orderSummaryModal: true })}
          selectedClass={this.state.selectedClass}
          whiteLabelBackgroundStyles={this.props.whiteLabelBackgroundStyles}
          getOrderAmount={this.getNewOrderAmount}
          preventCloseModal
        />
        <OrderSummaryModal
          showModal={this.state.orderSummaryModal}
          closeModal={() => this.setState({ orderSummaryModal: false })}
          whiteLabelBackgroundStyles={this.props.whiteLabelBackgroundStyles}
          selectedClass={this.state.selectedClass}
          title={"See you there!"}
          customStyles={{ content: { width: "35%" } }}
          showNewAmount={this.state.newOrderAmount}
        />
        <LoginModal
          showModal={this.state.loginModal}
          whiteLabel={this.props.whiteLabel}
          whiteLabelBackgroundStyles={this.props.whiteLabelBackgroundStyles}
          closeModal={() => this.setState({ loginModal: false })}
          title={
            whiteLabel
              ? "To access live classes, you must be logged into your account."
              : "To access live classes, you must be logged into a JETSWEAT account."
          }
          customStyles={{
            content: { width: "35%" },
          }}
          setCurrUser={this.props.setCurrUser}
          alreadySubscribedModal={() =>
            this.setState({ alreadySubscribedModal: true })
          }
          signupModal={() => this.setState({ signupModal: true })}
          paymentModal={() => this.setState({ paymentModal: true })}
          studio={this.props.studio}
          bookClass={this.bookClass}
          selectedClass={this.state.selectedClass}
        />
        <SignupModal
          showModal={this.state.signupModal}
          loginFromToken={this.props.loginFromToken}
          whiteLabelBackgroundStyles={this.props.whiteLabelBackgroundStyles}
          loginModal={() => this.setState({ loginModal: true })}
          closeModal={() => this.setState({ signupModal: false })}
          title={"Create your free account"}
          customStyles={{ content: { width: "35%" } }}
          setCurrUser={this.props.setCurrUser}
          whiteLabel={this.props.whiteLabel}
          studio={this.props.studio}
        />
        <div
          className="live-schedule-img-container"
          style={{
            backgroundImage:
              "linear-gradient(0deg, rgba(75, 75, 75, 0.5) 0%, rgba(255, 255, 255, 0) 100%)",
            height: 715,
          }}
        >
          <img
            className="live-schedule-img"
            alt=""
            src={
              studio && studio.hero
                ? studio.hero
                : require("./liveschedulebanner.png")
            }
            blend="passthrough"
            style={{
              width: "100%",
              height: 810,
              marginTop: -96,
              objectFit: "cover",
              zIndex: -1,
            }}
          />
          <h1 className="live-schedule-header">Live Class Schedule</h1>
          <p className="live-schedule-info">
            Book a livestream class and we'll add it to your schedule
          </p>
        </div>
        <div
          className="recent-classes-snapshot live-schedule-recent-classes"
          style={{
            padding: 50,
            backgroundColor: "aliceblue",
            backgroundImage:
              "linear-gradient(180deg, rgba(250, 250, 250, 1) 0%, rgba(154, 200, 185, 0) 100%)",
          }}
        >
          <div
            className="container-content live-schedule-container-content"
            style={{
              maxWidth: 1150.31,
              borderRadius: 5.27,
              boxShadow: "0px 1.0534px 10.534px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Nav tabs>
              <NavItem
                className={!this.state.isPersonalTraining && "active"}
                onClick={() => {
                  this.setState({
                    isPersonalTraining: false,
                  });
                }}
              >
                Group Sessions
              </NavItem>
              {hasPersonalTraining && (
                <NavItem
                  className={this.state.isPersonalTraining && "active"}
                  onClick={() => {
                    this.setState({
                      isPersonalTraining: true,
                    });
                  }}
                >
                  Personal Training
                </NavItem>
              )}
            </Nav>
            <div className="calendar-top">
              <div
                className="this-month"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 200,
                  paddingRight: 200,
                }}
              >
                <button
                  className="back-arrow-live-button"
                  style={{ transform: "translateY(-10px)" }}
                  onClick={() => this.getCurrentMonth(false, true)}
                  disabled={this.state.dates.some(
                    (date) =>
                      this.compareDate(new Date(), date) ||
                      this.state.dates.every((date) =>
                        this.compareMonth(new Date(), date)
                      )
                  )}
                >
                  ‹
                </button>
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: 30,
                    marginBottom: 20,
                  }}
                >
                  {this.months[this.state.calendarDate.getMonth()]}{" "}
                  {this.state.calendarDate.getFullYear()}
                </h1>
                <button
                  className="back-arrow-live-button"
                  onClick={() => this.getCurrentMonth(true)}
                  style={{ transform: "translateY(-10px)" }}
                >
                  ›
                </button>
              </div>
              <div className="this-week-week">
                <div
                  className="day-information-livestream"
                  style={{ textDecoration: "none" }}
                >
                  <button
                    className="back-arrow-live-button"
                    onClick={() => this.getCurrentWeek(false, true)}
                    disabled={this.state.dates.some((date) =>
                      this.compareDate(new Date(), date)
                    )}
                  >
                    ‹
                  </button>
                  {this.state.dates.map((date, index) => (
                    <div
                      className="live-stream-schedule-date-wrapper"
                      style={{
                        backgroundColor: this.compareDate(
                          this.state.selectedDate,
                          date
                        )
                          ? this.props.whiteLabelBackgroundStyles
                              .backgroundColor || "#9ac8b9"
                          : "white",
                      }}
                      onClick={() => this.setState({ selectedDate: date })}
                      key={`upcoming-live-date-${index}`}
                    >
                      <Day
                        day={this.days[index]}
                        key={this.days[index]}
                        date={this.state.dates[index]}
                        styleProps={{ margin: 0 }}
                        liveStream
                      />
                    </div>
                  ))}
                  <button
                    className="back-arrow-live-button"
                    onClick={() => this.getCurrentWeek(true)}
                  >
                    ›
                  </button>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="results-container" style={{ padding: 15 }}>
              {liveVideos.length === 0 ? (
                <div>
                  <div
                    className="results-top"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div
                      className="results-header-container"
                      style={{ display: "flex" }}
                    >
                      <p
                        className="results-header"
                        style={{
                          whiteSpace: "nowrap",
                          fontFamily: "PT Sans",
                          fontSize: "22.1214px",
                          fontWeight: "bold",
                          paddingLeft: 50,
                          paddingRight: 25,
                        }}
                      >
                        No Results{" "}
                      </p>
                      <div
                        className="results-header-border"
                        style={{ borderLeft: "2px solid black", height: 66.78 }}
                      ></div>
                    </div>
                    <p
                      className="results"
                      style={{
                        lineHeight: "29px",
                        fontFamily: "PT Sans",
                        fontSize: "22.1214px",
                        marginTop: 0,
                        paddingLeft: 25,
                      }}
                    >
                      We’re sorry, there are no classes scheduled for that day!
                      Search for another date or start your free trial to browse
                      our On-Demand classes.
                    </p>
                  </div>
                  <div
                    className="results-bottom-container"
                    style={{
                      textAlign: "center",
                      padding: 15,
                      bottom: 0,
                    }}
                  >
                    <Link
                      style={{
                        boxShadow: "none",
                        padding: 15,
                        width: 263,
                        height: 55.83,
                        fontSize: 20,
                        fontWeight: "bold",
                        fontFamily: "Coco Gothic",
                        margin: 50,
                        backgroundColor:
                          this.props.whiteLabelBackgroundStyles
                            .backgroundColor || "#9AC8B9",
                      }}
                      to={`/signup`}
                      className="try-for-free-button js-home-hero-cta-button-link live-schedule-try-free-button"
                    >
                      {this.props.studio.id == 466
                        ? "Start Free"
                        : "Start Free Trial"}
                    </Link>
                    <hr></hr>
                    <p style={{ marginTop: 50 }}>
                      Already have an account?{" "}
                      <Link
                        style={{
                          color:
                            this.props.whiteLabelBackgroundStyles
                              .backgroundColor || "#9AC8B9",
                        }}
                        to={`/login`}
                      >
                        Log in here!
                      </Link>
                    </p>
                  </div>
                </div>
              ) : null}
              {liveVideos.map((item, key) => {
                const date = new Date(item.scheduledDatetime);
                const militaryHour = date.getHours();
                var hours =
                  militaryHour > 12 ? militaryHour % 12 : militaryHour;
                const mins =
                  date.getMinutes() < 10
                    ? `${date.getMinutes()}0`
                    : date.getMinutes();
                const period = militaryHour < 12 ? "AM" : "PM";
                if (hours === 0) {
                  hours = 12;
                }
                return (
                  <div
                    className="upcoming-livestream-classes-list-item"
                    key={`upcoming-livestream-list-${key}`}
                  >
                    <div className="upcoming-livestream-classes-list-item-left">
                      <div>{`${hours}:${mins}${period}`}</div>
                      <div>|</div>

                      <img
                        className="upcoming-livestream-studio-logo"
                        src={
                          item.studioLogo ||
                          require("../../images/blacklogo.png")
                        }
                        width="50"
                        alt=""
                        height="50"
                        style={{
                          objectFit: "contain",
                        }}
                      />
                      <div className="live-schedule-title-instructor">
                        <div>{item.title}</div>
                        <div style={{ color: "#6F706F" }}>
                          {item.instructor}
                        </div>
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        this.setState({ selectedClass: item });
                        this.isLoggedIn(item);
                      }}
                      style={{
                        width: 70,
                        height: 40,
                        borderRadius: 3,
                        backgroundColor:
                          this.props.whiteLabelBackgroundStyles
                            .backgroundColor || "#9ac8b9",
                        color: "white",
                        textAlign: "center",
                        fontSize: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        padding: "10px",
                      }}
                    >
                      Book
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Footer studio={this.props.studio} />
      </>
    );
  }
}

const mSTP = (state) => {
  return {
    liveVideos: state.liveclass.liveVideos,
    purchased: state.liveclass.purchased,
  };
};

const mDTP = (dispatcher) =>
  bindActionCreators(
    {
      getPurchasedLiveClasses,
      fetchLiveVideosJetsweatPublic,
    },
    dispatcher
  );

export default connect(mSTP, mDTP)(LiveSchedule);
