export const reverseArray = (array) => {
  let first = null;
  let last = null;
  let tmp = null;
  const length = array.length;

  for (first = 0, last = length - 1; first < last; first++, last--) {
    tmp = array[first];
    array[first] = array[last];
    array[last] = tmp;
  }

  return array;
};
