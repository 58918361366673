import {
  signInChangedGoogle,
  syncQueueGoogle,
  syncQueueOutlook,
  getMicrosoftToken,
} from "./calendarHelpers";
const initialState = {
  GoogleAuth: null,
  OutlookAuth: null,
  iCalendarAuth: null,
  sync: {
    GoogleCalendar: "unsynced",
    Outlook: "unsynced",
    iCalendar: "unsynced",
  },
  signingOut: localStorage.getItem("signingOut")
    ? localStorage.getItem("signingOut")
    : null,
  signingIn: localStorage.getItem("signingIn")
    ? localStorage.getItem("signingIn")
    : null,
  // graphClient: null,
};

export let graphClient = null;

const setAuth = (calendar, calendarAuth) => ({
  type: "SET_AUTH",
  calendar,
  calendarAuth,
});

export const syncing = (calendar) => ({
  type: "SYNCING",
  calendar,
});

export const unsynced = (calendar) => ({
  type: "UNSYNCED",
  calendar,
});
export const synced = (calendar) => ({
  type: "SYNCED",
  calendar,
});

// const setGraphClient = (graphClient) => ({
//   type: 'SET_GRAPH_CLIENT',
//   graphClient,
// })

export const settingAuth = (client, currUser, whiteLabelDomain) => {
  return async (dispatch) => {
    try {
      switch (client) {
        case "GoogleAuth":
          window.gapi.load("client:auth2", () => {
            const { gapi } = window;
            gapi.client
              .init({
                apiKey: whiteLabelDomain
                  ? "AIzaSyD4GHWa1Aq6z70C4zFZjqKFHIveu7zljlE"
                  : "AIzaSyBx5pdidRnuAgkyl-Z15FHQhrwxdv1-z28",
                clientId: whiteLabelDomain
                  ? process.env.REACT_APP_GOOGLE_CLIENT_ID_WHITELABEL
                  : process.env.REACT_APP_GOOGLE_CLIENT_ID,
                discoveryDocs: [
                  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
                ],
                scope: "https://www.googleapis.com/auth/calendar.events",
              })
              .then(async () => {
                let GoogleAuth = gapi.auth2.getAuthInstance();
                dispatch(setAuth("GoogleAuth", GoogleAuth));
                GoogleAuth.isSignedIn.listen((val) => {
                  signInChangedGoogle(val, currUser);
                });
                if (GoogleAuth.isSignedIn.get()) {
                  await syncQueueGoogle(
                    null,
                    null,
                    currUser,
                    null,
                    whiteLabelDomain
                  );
                }
              });
          });
          break;
        case "OutlookAuth":
          const msalConfig = {
            auth: {
              clientId: process.env.REACT_APP_OUTLOOK_CLIENT_ID,
              redirectUri: window.location.origin,
            },
          };

          const msalClient = new window.msal.PublicClientApplication(
            msalConfig
          );

          graphClient = window.MicrosoftGraph.Client.initWithMiddleware({
            authProvider: {
              getAccessToken: async () => {
                return await getMicrosoftToken(msalClient);
              },
            },
          });

          if (sessionStorage.getItem("graphUser")) {
            await syncQueueOutlook(
              null,
              null,
              currUser,
              null,
              whiteLabelDomain
            );
          }
          dispatch(setAuth("OutlookAuth", msalClient));

          break;
        case "iCalendarAuth":
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const signingOut = (client) => {
  return async (dispatch) => {
    try {
      switch (client) {
        case "GoogleAuth":
          dispatch({ type: "SIGNING_OUT", client: "GoogleAuth" });
          localStorage.setItem("signingOut", "GoogleAuth");
          break;
        case "OutlookAuth":
          dispatch({ type: "SIGNING_OUT", client: "OutlookAuth" });
          localStorage.setItem("signingOut", "OutlookAuth");
          break;
        case "iCalendarAuth":
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const signingIn = (client, currUser) => {
  return async (dispatch) => {
    try {
      switch (client) {
        case "GoogleAuth":
          dispatch({ type: "SIGNING_IN", client: "GoogleAuth" });
          localStorage.setItem("signingIn", "GoogleAuth");
          break;
        case "OutlookAuth":
          if (sessionStorage.getItem("graphUser")) {
            await syncQueueOutlook(null, null, currUser);
          }
          dispatch({ type: "SIGNING_IN", client: "OutlookAuth" });
          localStorage.setItem("signingIn", "OutlookAuth");
          break;
        case "iCalendarAuth":
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };
};
export const clearSignOut = () => ({ type: "CLEAR_SIGNOUT" });
export const clearSignIn = () => ({ type: "CLEAR_SIGNIN" });

export default function calendarReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_AUTH":
      return {
        ...state,
        [action.calendar]: action.calendarAuth,
      };
    case "SYNCING":
      return {
        ...state,
        sync: {
          ...state.sync,
          [action.calendar]: action.type.toLowerCase(),
        },
      };
    case "UNSYNCED":
      return {
        ...state,
        sync: {
          ...state.sync,
          [action.calendar]: action.type.toLowerCase(),
        },
      };
    case "SYNCED":
      return {
        ...state,
        sync: {
          ...state.sync,
          [action.calendar]: action.type.toLowerCase(),
        },
      };
    case "SET_GRAPH_CLIENT":
      return { ...state, graphClient: action.graphClient };
    case "SIGNING_IN":
      return {
        ...state,
        signingIn: action.client,
      };
    case "CLEAR_SIGNIN":
      localStorage.removeItem("signingIn");

      return {
        ...state,
        signingIn: null,
      };
    case "SIGNING_OUT":
      return {
        ...state,
        signingOut: action.client,
      };
    case "CLEAR_SIGNOUT":
      localStorage.removeItem("signingOut");
      return {
        ...state,
        signingOut: null,
      };
    default:
      return state;
  }
}
