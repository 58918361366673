import React from "react";

import "./SideBarItemContainer.css";
import { withRouter } from "react-router-dom";

const sideBarItemContainer = (props) => {
  // pass prop grid={"any true value"} along with a className={'whatever'} to override the default grid and create your own grid layout.

  const displayChildren = () => {
    if (props.grid) {
      return (
        <>
          {props.children.map((child, i) => (
            <div className="sidebar-item-container" key={i}>
              {child}
            </div>
          ))}
        </>
      );
    }
    return (
      <div
        className={`sidebar-item-container ${props.defaultClassName ? props.defaultClassName : ""
          }`}
      >
        {props.children}
      </div>
    );
  };

  return (
    <div className="buffer">
      <div className={`sidebar-item-master ${props.className || "default"}`}>
        {displayChildren()}
      </div>
    </div>
  );
};

export default sideBarItemContainer;
