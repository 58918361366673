import log from "loglevel";
import remote from "loglevel-plugin-remote";

export let setupLogging = (authToken) => {
  const customJSON = (log) => ({
    msg: log.message,
    level: `${log.level.label}`,
    stacktrace: log.stacktrace,
  });

  setupLogging = () => {};
  remote.apply(log, {
    headers: {
      Authorization: localStorage.authToken || authToken,
    },
    token: "",
    onUnauthorized: (failedToken) => console.log(failedToken),
    format: customJSON,
    url: "/api/logger",
  });
  log.enableAll();
};
