import React, { Component } from "react";

class Terms extends Component {
  render() {
    return (
      <div>
        <div className="Background-color-nav" />
        <div className="PrivacyPolicy">
          <p>END USER LICENSE AGREEMENT AND TERMS OF SERVICE</p>
          <br />
          <p>Effective Date: August 22, 2017</p>
          <br />

          <p>
            This End User License Agreement and Terms of Service (this “EULA”)
            is a binding contract between you, an individual user (“you”) and
            JetSweat LLC (“JetSweat,” “we,” “us” or “our”) governing your use of
            the mobile software application that JetSweat makes available for
            download (individually and collectively, the “App”), the related
            website located at www.Jetsweatfitness.com.com and any other online
            properties owned or controlled by or on behalf of JetSweat
            (collectively with the App, the “Service”). THE SERVICE IS NOT FOR
            PERSONS UNDER THE AGE OF 13. IF YOU ARE UNDER 13 YEARS OF AGE, YOU
            MUST NOT USE OR ACCESS THE SERVICE AT ANY TIME OR IN ANY MANNER.
            Furthermore, by accessing or using the Service, you affirm that
            either you are at least 18 years of age or you have been authorized
            to use the Service by your parent or legal guardian who is at least
            18 years of age. Your access to and use of the Service is subject to
            JetSweat’s Privacy Policy available via the Service (“Privacy
            Policy”), the terms and conditions of which are incorporated herein
            by reference.
          </p>
          <br />

          <p>
            BY INSTALLING OR OTHERWISE ACCESSING OR USING THE SERVICE, YOU AGREE
            THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THIS EULA
            AND THE PRIVACY POLICY. IF YOU DO NOT AGREE TO THE TERMS OF THIS
            EULA OR THE PRIVACY POLICY, THEN YOU MAY NOT USE THE SERVICE.
          </p>
          <br />
          <br />

          <p>1. The Service.</p>
          <br />
          <p>
            a. Description. The Service provides you with the opportunity to
            view fitness content (collectively, “Content”) solely for your
            personal use. Not all Content will be available in perpetuity and
            Content is subject to change at any time with or without notice and
            without any liability to you.
          </p>
          <br />
          <p>
            b. Mobile Services. The Service will be accessible via a mobile
            phone, tablet, or other wireless device (collectively, “Mobile
            Services”). Your mobile carrier’s normal messaging, data, and other
            rates and fees will apply to your use of the Mobile Services. In
            addition, downloading, installing, or using certain Mobile Services
            may be prohibited or restricted by your mobile carrier, and not all
            Mobile Services may work with all carriers or devices. Therefore,
            you are solely responsible for checking with your mobile carrier to
            determine if the Mobile Services are available for your mobile
            device(s), what restrictions, if any, may be applicable to your use
            of the Mobile Services, and how much they will cost you.
            Nevertheless, all use of the App and the related Mobile Services
            must be in accordance with this EULA.
          </p>
          <br />
          <p>
            c. Changes to this EULA. You understand and agree that we may change
            this EULA at any time without prior notice; provided that we will
            endeavor to provide you with prior notice of any material changes
            that may apply to you, including through the posting of a revised
            EULA that you may be required to accept in order to continue using
            the Service. You may read a current, effective copy of this EULA at
            any time by selecting the appropriate link on the Service. The
            revised EULA will become effective at the time of posting unless
            specified otherwise. Any use of the Service after the effective date
            will constitute your acceptance of such revised EULA. If you find
            any change to this EULA or the Service unacceptable, then your sole
            remedy is to stop accessing, browsing, and otherwise using the
            Service. The terms of this EULA will govern any updates JetSweat
            provides to you that replace and/or supplement any portion of the
            Service, unless the upgrade is accompanied by a separate license or
            revised EULA, in which case the terms of that license or revised
            EULA will govern. Notwithstanding the preceding sentences of this
            Section 1.a, no revisions to this EULA will apply to any dispute
            between you and JetSweat that arose prior to the effective date of
            such revision.
          </p>
          <br />
          <br />

          <p>2. Your Account.</p>
          <br />
          <p>
            a. Log-In Credentials. While you may always browse the public-facing
            portions of the Service without registering with us, in order to
            enjoy the full benefits of the Service, you must download the App
            and register an account with us (an “Account”).{" "}
          </p>
          <br />
          <p>
            b. Account Security. You are responsible for the security of your
            Account, and are fully responsible for all activities that occur
            through the use of your credentials. You agree to notify JetSweat
            immediately at support@jetsweatfitness.com if you suspect or know of
            any unauthorized use of your log-in credentials or any other breach
            of security with respect to your Account. JetSweat will not be
            liable for any loss or damage arising from unauthorized use of your
            credentials or loss of your credentials.{" "}
          </p>
          <br />
          <p>
            c. Accuracy of Information. When creating an Account, you will
            provide true, accurate, current, and complete information as
            JetSweat requests. You will update the information about yourself
            promptly, and as necessary, to keep it current and accurate. We may,
            with or without prior notice, suspend or terminate your Account if
            activities occur on your Account which, in our sole discretion,
            would or might constitute a violation of this EULA, cause damage to
            or impair the Service, damage or bring into disrepute the reputation
            of JetSweat, or violate any applicable laws or regulations. If
            messages sent to the e-mail address you provide are returned as
            undeliverable, then JetSweat may terminate your Account immediately
            without notice to you and without any liability to you or any third
            party.{" "}
          </p>
          <br />
          <p>
            d. The owner of the Service is based in the state of New York in the
            United States. We provide the Service for use only by persons
            located in the United States. We make no claims that the Service or
            any of its content is accessible or appropriate outside of the
            United States. Access to the Service may not be legal by certain
            persons or in certain countries. If you access the Service from
            outside the United States, you do so on your own initiative and are
            responsible for compliance with local laws.
          </p>
          <br />
          <br />

          <p>3. Subscriptions and Payment.</p>
          <br />
          <p>
            a. Subscriptions. To enjoy the full benefits of the Service,
            including, without limitation, to view Content in full, you must
            purchase a subscription to the Service. If you purchase a
            subscription to the Service, then the following terms apply:
          </p>
          <br />
          <p>
            b. Automatically Renewable Subscriptions. BY PURCHASING A MONTHLY OR
            ANNUAL SUBSCRIPTION TO THE SERVICE, YOU AGREE THAT, ONCE YOUR
            SUBSCRIPTION EXPIRES, YOUR SUBSCRIPTION WILL AUTOMATICALLY RENEW FOR
            SUCCESSIVE MONTHLY OR ANNUAL PERIODS UNLESS AND UNTIL YOU CANCEL
            YOUR SUBSCRIPTION.
          </p>
          <br />
          <p>
            c. Cancellation. YOU MAY CANCEL YOUR SUBSCRIPTION TO THE SERVICE AT
            ANY TIME, AFTER WHICH JETSWEAT WILL NOT RENEW YOUR SUBSCRIPTION. OUR
            REQUIRED CANCELLATION NOTICE PERIOD IS 24 HOURS, MEANING YOU MUST
            EMAIL US AT SUPPORT@JETSWEATFITNESS.COM 24 HOURS AHEAD OF YOUR
            RENEWAL TIME AND DATE. ONCE WE BEGIN TO BILL YOU, CANCELLATIONS TAKE
            EFFECT STARTING AT THE END OF YOUR CURRENT BILLING PERIOD, MEANING
            THERE ARE NO PARTIAL MONTH REFUNDS OR CREDITS REGARDLESS OF
            SUBSCRIPTION USAGE. PLEASE CONTACT SUPPORT@JETSWEATFITNESS.COM TO
            DISPUTE A CHARGE, OR CANCEL YOUR SUBSCRIPTION.
          </p>
          <br />
          <p>
            d. Recurring Charges. YOU AUTHORIZE JETSWEAT TO CHARGE THE PAYMENT
            METHOD THAT OUR PAYMENT PROCESSOR HAS ON FILE FOR YOU TO PAY FOR ANY
            RENEWAL SUBSCRIPTION. YOU WILL BE BILLED FOR THE SAME SUBSCRIPTION
            PLAN (OR THE MOST SIMILAR SUBSCRIPTION PLAN, IF YOUR PRIOR PLAN IS
            NO LONGER AVAILABLE) AT THE THEN-CURRENT MONTHLY OR ANNUAL
            SUBSCRIPTION PRICE PLUS ANY APPLICABLE TAXES. ADDITIONAL TERMS AND
            CONDITIONS MAY APPLY UPON RENEWAL, AND SUBSCRIPTION FEES MAY CHANGE
            AT ANY TIME, TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW.
          </p>
          <br />
          <br />

          <p>4. Intellectual Property Rights.</p>
          <br />
          <p>
            a. License. The Service is licensed, not sold, to you for use only
            under the terms of this Agreement. JetSweat reserves all rights not
            expressly granted to you. Subject to your complete and ongoing
            compliance with this Agreement, JetSweat hereby grants you a
            personal, limited, revocable, non-transferable license to access and
            use the Service solely for your personal, non-commercial use.{" "}
          </p>
          <br />
          <p>
            b. Content. The content that JetSweat provides to end users on or
            through the Service, including without limitation, any Content,
            text, graphics, photos, software, sound recordings (and the musical
            works embodied therein), and interactive features, may be protected
            by copyright or other intellectual property rights and owned by
            JetSweat or its third party licensors (collectively, the “JetSweat
            Content”). You may not copy, reproduce, upload, republish,
            broadcast, transmit, retransmit, post, create derivative works of,
            publicly perform, publicly display, use for commercial purpose or
            distribute any JetSweat Content and/or any other materials from the
            Service in any way without prior express written permission of the
            copyright owner of such material or as otherwise specified in this
            Agreement or permitted by the Service’s intended functionalities.
            JetSweat owns all design rights, databases, and compilation and
            other intellectual property rights in and to the Service, in each
            case whether registered or unregistered, and all related goodwill.
          </p>
          <br />
          <p>
            c. Marks. JetSweat’s trademarks, service marks, and logos (the
            “JetSweat Marks”) used and displayed on the Service are JetSweat’s
            registered and unregistered trademarks or service marks. Other
            product and service names located on the Service may be trademarks
            or service marks owned by third parties (the “Third-Party
            Trademarks,” and, collectively with JetSweat Marks, the
            “Trademarks”). Except as otherwise permitted by law, you may not use
            the Trademarks to disparage JetSweat or the applicable third-party,
            JetSweat’s or a third-party’s products or services, or in any manner
            (using commercially reasonable judgment) that may damage any
            goodwill in the Trademarks. You may not use any Trademarks as part
            of a link to or from the Service without JetSweat’s prior express
            written consent. You may not remove any Trademarks identifying the
            ownership or origin of any JetSweat Content. All goodwill generated
            from the use of any JetSweat Marks will inure solely to JetSweat’s
            benefit.
          </p>
          <br />
          <p>
            d. Third Party Sites. The Service may contain links to third party
            websites. JetSweat has no control over such websites and is not
            responsible for the availability of such external websites. JetSweat
            does not endorse and is not responsible or liable for any content,
            advertising, products, services or other materials on or available
            from such third party websites linked from the Site. JetSweat does
            not make any representations regarding the content or accuracy of
            materials on such third party websites and your use of third party
            websites is at your own risk and subject to the terms and conditions
            and policies and procedures of such websites. In addition, other
            websites may provide links to the Service with or without our
            authorization. You acknowledge and agree that JetSweat does not
            endorse such third-party sites, and are not and shall not be
            responsible or liable for any links from those sites to the Service,
            any content, advertising, products or other materials available on
            or through such other sites, or any loss or damages incurred in
            connection therewith.{" "}
          </p>
          <br />
          <p>
            e.Restrictions. JetSweat hereby reserves all rights not expressly
            granted to you in this Section 4. Accordingly, nothing in this EULA
            or on the Service will be construed as granting to you, by
            implication, estoppel, or otherwise, any additional license rights
            in and to the Service or any JetSweat Content or Trademarks located
            or displayed therein.
          </p>
          <br />
          <br />

          <p>5. Comments.</p>
          <br />
          <p>
            a. JetSweat may offer end users the ability to submit reviews and/or
            comments after watching Content (“Comments”). You hereby grant
            JetSweat an unrestricted, assignable, sublicensable, revocable,
            royalty-free license throughout the universe to reproduce,
            distribute, publicly display, communicate to the public, publicly
            perform (including by means of digital audio transmissions and on a
            through-to-the-audience basis), make available, create derivative
            works from, and otherwise exploit and use (collectively, “Use”) all
            or any part of all Comments you post to or through the Service by
            any means and through any media and formats now known or hereafter
            developed, for the purposes of (i) advertising, marketing, and
            promoting JetSweat and the Service; (ii) displaying and sharing your
            Comments to other users of the Service; and (iii) providing the
            Service as authorized by this EULA
          </p>
          <br />
          <p>
            b. JetSweat does not guarantee the accuracy, integrity,
            appropriateness, availability, or quality of any Comments, and under
            no circumstances will JetSweat be liable in any way for any
            Comments.
          </p>
          <br />
          <p>
            c. You must not post any Comments on or through the Service or
            transmit to JetSweat any Comments that you consider to be
            confidential or proprietary. Any Comments posted by you to or
            through the Service or transmitted to JetSweat will be considered
            non-confidential and non-proprietary, and treated as such by
            JetSweat, and may be used by JetSweat in accordance with this EULA
            without notice to you and without any liability to JetSweat.
          </p>
          <br />
          <p>
            d. Waiver of Rights to Comments. By posting Comments to or through
            the Service, you waive any rights to prior inspection or approval of
            any marketing or promotional materials related to such Comments. You
            also waive any and all rights of privacy, publicity, or any other
            rights of a similar nature in connection with your Comments, or any
            portion thereof.
          </p>
          <br />
          <p>
            e. Objectionable Content. You are not permitted to, and agree not
            to, post any Comments to the Service that is or could be interpreted
            to be (i) abusive, bullying, defamatory, harassing, harmful,
            hateful, inaccurate, infringing, libelous, objectionable, obscene,
            offensive, pornographic, shocking, threatening, unlawful, violent,
            vulgar, or in violation of any applicable laws (including laws
            related to speech); or (ii) promoting any product, good, or service,
            or bigotry, discrimination, hatred, intolerance, racism, or inciting
            violence (including suicide) (collectively, “Objectionable
            Content”). The Posting of any Objectionable Content may subject you
            to third party claims and none of the rights granted to you in this
            EULA may be raised as a defense against any third party claims
            arising from your posting of Objectionable Content. You also agree
            not to use the Service for illegal or unlawful purposes. If you
            encounter any Objectionable Content on the Service, then please
            immediately email JetSweat at support@jetsweatfitness.com or inform
            us through the functionality offered on the Service. You acknowledge
            and agree that JetSweat provides you with the ability to report
            Objectionable Content as a courtesy, and JetSweat has no obligation
            to remove or take any other action with respect to any Objectionable
            Content on the Service that you report to us. However, JetSweat in
            its sole discretion may take any actions it deems necessary and/or
            appropriate against any User who Posts Objectionable Content on the
            Service, including, but not limited to, warning the User, suspending
            or terminating the user’s Account, removing all of the user’s
            Comments Posted on the Service, and/or reporting the user to law
            enforcement or other authorities, either directly or indirectly.
          </p>
          <br />
          <p>
            f. Feedback. If you choose to contribute by sending JetSweat or our
            employees any ideas for products, services, features, modifications,
            enhancements, content, refinements, technologies, content offerings
            (such as audio, visual, games, or other types of content),
            promotions, strategies, or product/feature names, or any related
            documentation, artwork, computer code, diagrams, or other materials
            (collectively “Feedback”), then regardless of what your accompanying
            communication may say, the following terms will apply, so that
            future misunderstandings can be avoided. Accordingly, by sending
            Feedback to JetSweat, you agree that:
          </p>
          <br />
          <p>
            i. JetSweat has no obligation to review, consider, or implement your
            Feedback, or to return to you all or part of any Feedback for any
            reason;
          </p>
          <br />
          <p>
            ii. Feedback is provided on a non-confidential basis, and JetSweat
            is not under any obligation to keep any Feedback you send
            confidential or to refrain from using or disclosing it in any way;
            and
          </p>
          <br />
          <p>
            iii. You irrevocably grant JetSweat perpetual and unlimited
            permission to Use the Feedback and derivatives thereof for any
            purpose and without restriction, free of charge and without
            attribution of any kind, including by making, using, selling,
            offering for sale, importing, and promoting commercial products and
            services that incorporate or embody Feedback, whether in whole or in
            part, and whether as provided or as modified.
          </p>
          <br />
          <p>
            g. No Liability. For the avoidance of doubt, JetSweat will not be
            liable for any unauthorized use of Comments by any end user.
          </p>
          <br />
          <br />

          <p>6.Restrictions on Use of the Service.</p>
          <br />
          <p>
            While using the Service you agree to comply with all applicable
            laws, rules and regulations. In addition, we expect users of the
            Service to respect the rights and dignity of others. Your use of the
            Service is conditioned on your full compliance with these terms
            including, without limitation, the rules of conduct set forth in
            this section; your failure to comply with such rules may result in
            termination of your access to the Service pursuant to these terms.
            You agree that when using the Service, you will not:
          </p>
          <br />
          <ul>
            <li>
              Transmit by any means any software, virus, malware, program, code,
              file, or other material intended to interrupt, disrupt, alter,
              destroy, or limit any part of the Service;
            </li>
            <br />
            <li>
              Use any robot, spider, script, or any manual or automated
              application or means to extract, download, retrieve, index, mine,
              scrape, reproduce, or circumvent the presentation, operation, or
              intended use of any feature, function, or part of the Service;
            </li>
            <br />
            <li>
              Frame or mirror any part of the Service without JetSweat’s express
              prior written consent;
            </li>
            <br />
            <li>
              Modify, adapt, translate, reverse engineer, decompile or
              disassemble any portion of the Service;
            </li>
            <br />
            <li>
              Submit, post, or submit any offensive, defamatory, threatening,
              indecent, hateful, racist, sexist, pornographic, or otherwise
              inappropriate or obscene content (as determined by us in our sole
              discretion);
            </li>
            <br />
            <li>
              Copy, download, distribute, transmit, upload, or transfer content
              from the Service or the personal information of others without our
              prior written permission or authorization;
            </li>
            <br />
            <li>
              Resell, sub-license, or lease any of the content on the Service;
            </li>
            <br />
            <li>
              Use the Service to make unsolicited offers, requests,
              advertisements, or spam;
            </li>
            <br />
            <li>
              Impersonate or pretend to be anyone else but you; falsely state or
              otherwise misrepresent your affiliation with any person or entity
              in connection with the Service; or express or imply that we
              endorse any statement you make;
            </li>
            <br />
            <li>
              Violate or infringe upon the privacy, publicity, intellectual
              property, or other proprietary rights of third parties; or
            </li>
            <br />
            <li>
              Engage in any activity that is criminal or tortious in nature, or
              otherwise violates the law or rights of another including, without
              limitation, hacking, phishing, fraud, stalking, defaming, abusing,
              harassing, or threatening.
            </li>
            <br />
            <br />
          </ul>

          <p>7. Governing Law.</p>
          <br />
          <p>
            All matters relating to the Service, this EULA and any dispute or
            claim arising therefrom or related thereto (in each case, including
            non-contractual disputes or claims), shall be governed by and
            construed in accordance with the internal laws of the State of New
            York without giving effect to any choice or conflict of law
            provision or rule (whether of the State of New York or any other
            jurisdiction).
          </p>
          <br />
          <p>
            Any legal suit, action or proceeding arising out of, or related to,
            this EULA or the Service shall be instituted exclusively in the
            federal courts of the United States or the courts of the State of
            New York in each case located in the City of New York, County of New
            York. You waive any and all objections to the exercise of
            jurisdiction over you by such courts and to venue in such courts.
          </p>
          <br />

          <p>8. Dispute Resolution.</p>
          <br />
          <p>
            At EULA’s sole discretion, it may require you to submit any disputes
            arising from the use of the Service or this EULA, including disputes
            arising from or concerning their interpretation, violation,
            invalidity, non-performance, or termination, to final and binding
            arbitration under the Rules of Arbitration of the American
            Arbitration Association applying New York law.&nbsp; This agreement
            to arbitrate is intended to be interpreted broadly. It includes, but
            is not limited to, all claims and disputes relating to your use of
            the Service.
          </p>
          <br />
          <p>
            YOU AGREE THAT BY AGREEING TO THESE TERMS, YOU AND JETSWEAT WAIVE
            THE RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION. YOU
            AND JETSWEAT AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY
            IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
            MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. ANY
            ARBITRATION WILL TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS
            ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED.
          </p>
          <br />
          <p>
            ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
            TO THIS EULA OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR
            AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION
            OR CLAIM IS PERMANENTLY BARRED.
          </p>
          <br />
          <br />

          <p>9. Physical Activity Disclaimer.</p>
          <br />
          <p>
            If you have any medical conditions or are thinking about starting an
            exercise program or engaging in strenuous or unusual physical
            activity, you should consult your doctor first. WITHOUT LIMITATION
            OF ANY OTHER TERMS IN THIS EULA, WE DO NOT MAKE ANY REPRESENTATIONS
            OR WARRANTIES THAT THE APP IS INTENDED TO AND/OR DOES DIAGNOSE,
            TREAT, CURE, OR PREVENT ANY ALLERGIES OR OTHER MEDICAL DISORDERS OR
            CONDITIONS, AND YOU HEREBY ACKNOWLEDGE THIS DISCLAIMER AND THAT WE
            ARE NOT ENGAGED IN PROVIDING YOU WITH A MEDICAL DEVICE, MEDICAL
            ADVICE AND/OR HEALTHCARE SERVICES BY PROVIDING YOU WITH ACCESS TO
            THE APP. You should consult with a licensed medical or fitness
            professional if you have any questions regarding your physical
            fitness routine. We are not responsible in any way for any health
            problems or injuries, including, without limitation, incapacity or
            death, which may result from or be related to your use of or
            inability to use the App.
          </p>
          <br />
          <br />

          <p>10. Limitation of Liability and Disclaimer of Warranties.</p>
          <br />
          <p>
            THE FOLLOWING TERMS IN THIS SECTION 10 APPLY TO THE FULLEST EXTENT
            PERMITTED BY LAW:
          </p>
          <br />
          <p>
            a. JETSWEAT, ITS AFFILIATES, AND THEIR RESPECTIVE OFFICERS,
            DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS, CONTENT PROVIDERS
            AND LICENSORS (COLLECTIVELY, THE “JETSWEAT PARTIES”) MAKE NO
            WARRANTIES OR REPRESENTATIONS ABOUT THE SERVICE AND ANY CONTENT
            AVAILABLE ON THE SERVICE, INCLUDING, BUT NOT LIMITED TO, THE
            ACCURACY, RELIABILITY, COMPLETENESS, APPROPRIATENESS, TIMELINESS, OR
            RELIABILITY THEREOF. THE JETSWEAT PARTIES WILL NOT BE SUBJECT TO
            LIABILITY FOR THE TRUTH, ACCURACY, OR COMPLETENESS OF ANY CONTENT ON
            THE SERVICE OR ANY OTHER INFORMATION CONVEYED TO ANY USER, OR FOR
            ERRORS, MISTAKES, OR OMISSIONS THEREIN, OR FOR ANY DELAYS OR
            INTERRUPTIONS OF THE DATA OR INFORMATION STREAM FROM WHATEVER CAUSE.
            AS A USER, YOU AGREE THAT YOU USE THE SERVICE AND ANY CONTENT
            THEREON AT YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR ALL CONTENT
            YOU UPLOAD TO THE SERVICE.
          </p>
          <br />
          <p>
            b. THE JETSWEAT PARTIES DO NOT WARRANT THAT THE SERVICE WILL OPERATE
            ERROR FREE, OR THAT THE SERVICE AND ANY CONTENT THEREON ARE FREE OF
            COMPUTER VIRUSES OR SIMILAR CONTAMINATION OR DESTRUCTIVE FEATURES.
            IF YOUR USE OF THE SERVICE OR ANY CONTENT THEREON RESULTS IN THE
            NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, THEN NO JETSWEAT
            PARTY WILL BE RESPONSIBLE FOR THOSE COSTS.
          </p>
          <br />
          <p>
            c. THE SERVICE AND ALL CONTENT THEREON (INCLUDING CONTENT) ARE
            PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY
            WARRANTIES OF ANY KIND. ACCORDINGLY, THE JETSWEAT PARTIES DISCLAIM
            ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF
            TITLE, MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, AND
            FITNESS FOR PARTICULAR PURPOSE.
          </p>
          <br />
          <p>
            d. IN NO EVENT WILL ANY JETSWEAT PARTY BE LIABLE FOR ANY SPECIAL,
            INDIRECT, PUNITIVE, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOST
            PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS
            INTERRUPTION RESULTING FROM, OR IN CONNECTION WITH, THE USE OR
            INABILITY TO USE THE SERVICE AND ANY CONTENT THEREON, WHETHER BASED
            ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER
            LEGAL THEORY, EVEN IF SUCH JETSWEAT PARTY HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. JETSWEAT’S LIABILITY, AND THE LIABILITY
            OF ANY OTHER JETSWEAT PARTIES, TO YOU OR ANY THIRD PARTIES IN ANY
            CIRCUMSTANCE IS LIMITED TO THE GREATER OF THE FEES YOU HAVE PAID US
            AND U.S. $100.
          </p>
          <br />
          <br />

          <p>11. Third Party Disputes.</p>
          <br />
          <p>
            a. TO THE FULLEST EXTENT PERMITTED BY LAW, ANY DISPUTE YOU HAVE WITH
            ANY THIRD PARTY ARISING OUT OF YOUR USE OF THE SERVICE, INCLUDING,
            BY WAY OF EXAMPLE AND NOT LIMITATION, ANY CARRIER, COPYRIGHT OWNER,
            OR OTHER USER, IS DIRECTLY BETWEEN YOU AND SUCH THIRD PARTY, AND YOU
            IRREVOCABLY RELEASE THE JETSWEAT PARTIES FROM ANY AND ALL CLAIMS,
            DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND
            NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED
            WITH SUCH DISPUTES.
          </p>
          <br />
          <p>
            b. The owners of any content licensed to JetSweat for use on the
            Service are intended beneficiaries of this EULA and shall have the
            right to enforce this EULA against you for any unauthorized use of
            their content in any court of competent jurisdiction. The provisions
            of Section 10 do not apply to any dispute between you and a third
            party licensor of content to JetSweat.
          </p>
          <br />
          <br />

          <p>12. Indemnification.</p>
          <br />
          <p>
            To the fullest extent permitted by law, you agree to defend,
            indemnify, and hold harmless the JetSweat Parties from and against
            any claims, actions or demands, including, without limitation,
            reasonable attorney fees, arising or resulting from (a) your breach
            of this EULA; (b) your access to, use, or misuse of JetSweat Content
            or the Service; or (c) your Comments. JetSweat will provide notice
            to you of any such claim, suit, or proceeding. JetSweat reserves the
            right to assume the exclusive defense and control of any matter
            which is subject to indemnification under this Section if JetSweat
            believes that you are unwilling or incapable of defending JetSweat’s
            interests. In such case, you agree to cooperate with any reasonable
            requests assisting JetSweat’s defense of such matter at your
            expense.
          </p>
          <br />
          <br />

          <p>13. Term and Termination of the EULA.</p>
          <br />
          <p>
            a. Term. As between you and JetSweat, the Term of this EULA
            commences as of your first use of the Service and continues until
            the termination of this EULA by either you or JetSweat.
          </p>
          <br />
          <p>
            b. Termination. You may terminate this EULA by sending written
            notification to JetSweat at support@jetsweatapp.com, deleting the
            App from your mobile device, and terminating all other uses of the
            Service. If you wish to delete any of your Comments from the
            Service, then you may be able to do so using the permitted
            functionalities of the App, but the removal or deletion of such
            Comments will not terminate this EULA. JetSweat reserves the right,
            in its sole discretion, to restrict, suspend, or terminate this EULA
            and your access to all or any part of the Service at any time
            without prior notice or liability if you breach any provision of
            this EULA. JetSweat may further terminate this EULA for any other
            reason upon ten (10) days’ notice to you using the email address
            associated with your account credentials. JetSweat reserves the
            right to change, suspend, or discontinue all or any part of the
            Service at any time without prior notice or liability.
          </p>
          <br />
          <p>
            c. Survival. The terms and conditions of this EULA regarding
            confidentiality, indemnification, limitation of liability, and all
            others that by their sense and context are intended to survive the
            execution, delivery, performance, termination or expiration of this
            EULA survive and continue in effect.
          </p>
          <br />
          <br />

          <p>14. Consent to Electronic Communications.</p>
          <br />
          <p>
            By using the Service, you consent to receiving certain electronic
            communications from us as further described in the Privacy Policy.
            Please read the Privacy Policy to learn more about your choices
            regarding our electronic communications practices. You agree that
            any notices, agreements, disclosures, or other communications that
            we send to you electronically will satisfy any legal communication
            requirements, including that such communications be in writing.
          </p>
          <br />
          <br />

          <p>15. Miscellaneous.</p>
          <br />
          <p>
            You agree that no joint venture, partnership, employment, or agency
            relationship exists between you and JetSweat as a result of this
            EULA or use of the Service. You further acknowledge that by
            submitting Comments, no confidential, fiduciary, contractually
            implied, or other relationship is created between you and JetSweat
            other than pursuant to this EULA. If any provision of this EULA is
            found to be invalid by any court having competent jurisdiction, the
            invalidity of such provision will not affect the validity of the
            remaining provisions of this EULA, which will remain in full force
            and effect. Failure of JetSweat to act on or enforce any provision
            of this EULA will not be construed as a waiver of that provision or
            any other provision in this EULA. No waiver will be effective
            against JetSweat unless made in writing, and no such waiver will be
            construed as a waiver in any other or subsequent instance. Except as
            expressly agreed by JetSweat and you, this EULA constitutes the
            entire agreement between you and JetSweat with respect to the
            subject matter hereof, and supersedes all previous or
            contemporaneous agreements, whether written or oral, between the
            parties with respect to the subject matter herein. The Section
            headings are provided merely for convenience and will not be given
            any legal import. This EULA will inure to the benefit of our
            successors and assigns. You may not assign this EULA or any of the
            rights or licenses granted hereunder, directly or indirectly,
            without the prior express written consent of JetSweat. This means
            that in the event you dispose of any device on which you have
            installed the App, such as by sale or gift, you are responsible for
            deleting the App and any JetSweat Content from your device prior to
            such disposition. JetSweat may assign this EULA, including all its
            rights hereunder, without restriction.
          </p>
          <br />
          <br />

          <p>16. Contact Us.</p>
          <br />
          <p>
            If you would like to contact us in connection with this EULA or your
            use of the Service, then please contact us at
            support@jetsweatfitness.com
          </p>
          <br />
          <br />

          <p>
            NOTICE REGARDING APPLE. You acknowledge that this EULA is between
            you and JetSweat only, not with Apple, and Apple is not responsible
            for the App or the content thereof. Apple has no obligation
            whatsoever to furnish any maintenance and support services with
            respect to the App. In the event of any failure of the App to
            conform to any applicable warranty, then you may notify Apple and
            Apple will refund the purchase price for the relevant App to you;
            and, to the maximum extent permitted by applicable law, Apple has no
            other warranty obligation whatsoever with respect to the App. Apple
            is not responsible for addressing any claims by you or any third
            party relating to the App or your possession and/or use of the App,
            including, but not limited to: (i) product liability claims; (ii)
            any claim that the App fails to conform to any applicable legal or
            regulatory requirement; and (iii) claims arising under consumer
            protection or similar legislation. Apple is not responsible for the
            investigation, defense, settlement, and discharge of any third party
            claim that the App or your possession and use of the App infringes
            that third party’s intellectual property rights. You agree to comply
            with any applicable third party terms, when using the App. Apple,
            and Apple’s subsidiaries, are third party beneficiaries of this
            EULA, and upon your acceptance of this EULA, Apple will have the
            right (and will be deemed to have accepted the right) to enforce
            this EULA against you as a third party beneficiary of this EULA. You
            hereby represent and warrant that (i) you are not located in a
            country that is subject to a U.S. Government embargo, or that has
            been designated by the U.S. Government as a “terrorist supporting”
            country; and (ii) you are not listed on any U.S. Government list of
            prohibited or restricted parties. If JetSweat provides a translation
            of the English language version of this EULA, the translation is
            provided solely for convenience, and the English version will
            prevail.
          </p>
        </div>
      </div>
    );
  }
}

export default Terms;
