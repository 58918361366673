import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Calendar } from "react-calendar";
// import "../../views/Calendar/Calendar.css";
// import CustomButton from '../../views/Calendar/CustomButton'
import moment from "moment-timezone";
import "../payment.css";
import Footer from "../footer";
import CheckoutForm from "./WhiteLabelCheckout";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

class WhiteLabelPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: new Date(),
      firstName: "",
      lastName: "",
      promo: "",
      stripeApiKey: "",
      dailyPrice: "",
    };
    this.onCalendarChange = this.onCalendarChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.redirectToAccount = this.redirectToAccount.bind(this);
  }

  componentDidMount() {
    fetch("/api/basic/stripe", {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        if (res.status >= 400) {
          console.log("error!");
        } else {
          return res.json();
        }
      })
      .then((rslt) => {
        this.setState({
          stripeApiKey: (rslt.content || {}).publishable || "",
        });

        return rslt;
      });
    fetch(`/api/studio/packages/${this.props.studio.shortName}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({
          dailyPrice: result.content.daily.price,
        });
      });
  }

  redirectToAccount(daypassDate) {
    let { whiteLabelDomain } = this.props;
    let pushTo = "";
    let formattedDaypassDate = moment(daypassDate).format("MMMM Do, YYYY");
    if (daypassDate) {
      if (whiteLabelDomain) {
        pushTo = `/account/${formattedDaypassDate}`;
      } else {
        pushTo = `/account/${this.props.studio.shortName}/${formattedDaypassDate}`;
      }
    } else {
      if (whiteLabelDomain) {
        pushTo = `/account`;
      } else {
        pushTo = `/account/${this.props.studio.shortName}`;
      }
    }
    return this.props
      .refreshLoggedInUser()
      .then(() => this.props.history.push(pushTo));
  }

  handleChange(event) {
    const fieldName = event.target.name;
    this.setState({ [fieldName]: event.target.value });
  }

  onCalendarChange(date) {
    this.setState({ date });
  }

  // redirectToAccount() {
  //   return this.props
  //     .refreshLoggedInUser()
  //     .then(() => this.props.history.push("/account"));
  // }

  render() {
    let {
      isLoggedIn,
      whiteLabelDomain,
      whiteLabelBackgroundStyles,
      whiteLabelFontStyles,
    } = this.props;
    let stripePromise = null;
    if (!isLoggedIn) {
      if (whiteLabelDomain) {
        return <Redirect to="/login/daypass" />;
      }
      return <Redirect to={`/login/daypass/${this.props.studio.shortName}`} />;
    }

    if (this.state.stripeApiKey !== "") {
      stripePromise = loadStripe(this.state.stripeApiKey);
    }
    return (
      <div className="payment-container">
        <div className="Background-color-nav" />
        <div className="Center">
          <p className="Main-text" style={{ ...whiteLabelFontStyles }}>
            {" "}
            Billing Information{" "}
          </p>
          <p className="Main-text" style={{ ...whiteLabelFontStyles }}>
            {" "}
            Choose Your Subscription:
          </p>
        </div>

        <div className="clearfix Whitelabel-plans" style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <section
              className="Gallery"
              onClick={() => {
                this.props.setSubscription("daypass");
              }}
              style={{ width: "auto" }}
            >
              <fieldset
                className={`Monthly-annualbox Active`}
                style={{
                  borderColor:
                    whiteLabelBackgroundStyles &&
                    whiteLabelBackgroundStyles.backgroundColor,
                }}
              >
                <legend className="Price-font">Day Pass</legend>
                <div className="Payment-yearly">
                  <p>
                    <span className="Price-font">
                      ${this.state.dailyPrice}/day
                    </span>
                    <br />
                    one time payment
                    <br />
                    <span className="Price-font">Unlimited Workouts</span>
                    <br />
                    <br />
                  </p>
                </div>
              </fieldset>
            </section>
            <Calendar
              onChange={this.onCalendarChange}
              // activeStartDate={this.state.date}
              value={this.state.date}
              showNeighboringMonth={false}
              minDetail="month"
              onActiveDateChange={this.onActiveDateChange}
              // tileContent={(_) => <CustomButton />}
              // tileClassName={({ date }) => {
              //   date = moment(date).format("MM-DD-YYYY");
              // }}
            />
          </div>
        </div>
        {stripePromise && (
          <Elements stripe={stripePromise} s>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <CheckoutForm
                  {...this.props}
                  studio={this.props.studio}
                  apiKey={this.state.stripeApiKey}
                  subscription={"daypass"}
                  date={moment.utc(this.state.date, "YYYY-MM-DD hA")}
                  redirectToAccount={this.redirectToAccount}
                  user={this.props.currUser}
                  studioId={this.props.currUser.studioId}
                  firstName={this.props.currUser.firstName}
                  lastName={this.props.currUser.lastName}
                  authToken={this.props.currUser.authToken}
                  stripe={stripe}
                  elements={elements}
                />
              )}
            </ElementsConsumer>
          </Elements>
        )}
        <div className="footer-payment">
          <Footer studio={this.props.studio} />
        </div>
      </div>
    );
  }
}

export default WhiteLabelPayment;
