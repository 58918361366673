import React, { useState, useEffect, useRef } from 'react';
import '../styles/SingleImageCarousel.css';
import spriteImage from '../assets/images/spriteLogos.png';
import Slider_Phone from '../assets/images/iPhone-test2.svg';
import stretched from '../assets/images/stretched.png';
import pilates from '../assets/images/pilates-pro-works.png';
import mindfresh from '../assets/images/mind-fresh.svg';
import mamasoul from '../assets/images/MAMA-SOUL.png';
import boxflow from '../assets/images/box-flow.png';
import bodyrok from '../assets/images/body-rok.png';


const brands = [
  { id: 0, name: 'box-flow', offset: '0px 0', svg: boxflow },
  { id: 1, name: 'mama-soul', offset: '-600px 0', svg: mamasoul },
  { id: 2, name: 'body-rok', offset: '-1200px 0', svg: bodyrok },
  { id: 3, name: 'mind-fresh', offset: '-1800px 0', svg: mindfresh },
  { id: 4, name: 'stretched', offset: '-2400px 0', svg: stretched },
  { id: 5, name: 'pilates-pro-works', offset: '-3000px 0', svg: pilates }
];

const FRAME_WIDTH = 600;
const FRAME_HEIGHT = 200;

function SingleImageCarousel() {
  const [rotation, setRotation] = useState(0);
  const anglePerItem = 360 / brands.length;
  const offsetAngle = 330;

  const activeIndex = Math.round(rotation / anglePerItem) % brands.length;
  const shiftedIndex = (activeIndex - 1 + brands.length) % brands.length;
  const [prevIndex, setPrevIndex] = useState(0);
  const [direction, setDirection] = useState('forward');

  const currentBrand = brands[shiftedIndex];
  const oldBrand = brands[prevIndex];

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 4000);
    return () => clearInterval(intervalId);
  }, [anglePerItem]);

  const handleNext = () => {
    setDirection('forward');
    setPrevIndex(activeIndex);
    setRotation((prev) => prev + anglePerItem);
  };

  const handlePrev = () => {
    setDirection('backward');
    setPrevIndex(activeIndex);
    setRotation((prev) => prev - anglePerItem);
  };

  const logoClick = (clickedIndex) => {
    const diff = clickedIndex - activeIndex;
    setPrevIndex(activeIndex);
    setDirection(diff >= 0 ? 'forward' : 'backward');
    setRotation((prev) => prev + diff + anglePerItem);
  };

  //this is specifically for the mobile usage when a user tries to switch the shown brand and logo. (might go back later to incorporate swiping, but not sure.)

  const startXRef = useRef(null);

  const touchStart = (e) => {
    startXRef.current = e.touches[0].clientX;
  };
  const touchEnd = (e) => {
    const endX = e.changedTouches[0].clientX;
    const diff = endX - startXRef.current;

    if (diff > 50) {
      handlePrev();
    } else if (diff < -50) {
      handleNext();
    };
  };

  return (
    <div className="carousel-wrapper"
      onTouchStart={touchStart}
      onTouchEnd={touchEnd}>
      <img src={Slider_Phone} alt="iPhone" className="iphone-image" />
      <div
        className="circle-container"
        style={{ transform: `rotate(${-rotation}deg)` }}
      >
        {brands.map((brand, i) => {
          const itemRotation = offsetAngle + i * anglePerItem;
          return (
            <div
              key={brand.id}
              className={`circle-item sprite-item ${i === activeIndex ? "active" : ""
                }`}
              style={{
                transform: `
                  rotate(${itemRotation}deg)
                  translate(1000px)
                  rotate(${rotation}deg)
                  rotate(-${itemRotation}deg)
                `,
                backgroundImage: `url(${spriteImage})`,
                backgroundPosition: brand.offset,
                width: FRAME_WIDTH,
                height: FRAME_HEIGHT,
              }}
              onClick={() => logoClick(i)}
            />
          );
        })}
      </div>
      <div className="brand-images-container">
        {prevIndex !== activeIndex && (
          <div
            key={oldBrand.id + '-exit'}
            className={`brand-image exiting ${direction}`}
          >
            <img src={oldBrand.svg} alt={oldBrand.name} />
          </div>
        )}
        <div
          key={currentBrand.id + '-enter'}
          className={`brand-image entering ${direction}`}
        >
          <img src={currentBrand.svg} alt={currentBrand.name} />
        </div>
      </div>
    </div>
  );
};

export default SingleImageCarousel;
