import React, { Component } from "react";

class CustomButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workouts: this.props.workouts,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ selected: !this.state.selected });
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ workouts: this.props.workouts });
    }
  }

  render() {
    const { whiteLabelBackgroundStyles, whiteLabelFontStyles } = this.props;
    const { workouts } = this.state;
    let status = workouts[0].status;

    let inlineStyle;
    if (status === "completed") {
      inlineStyle = { ...whiteLabelBackgroundStyles };
    } else if (status === "scheduled") {
      inlineStyle = {
        border: `3px solid ${whiteLabelFontStyles.color}`,
      };
    } else if (status === "skipped") {
      inlineStyle = { backgroundColor: "#7d92a5" };
    } else {
      inlineStyle = {};
    }

    return (
      <div
        onClick={this.handleClick}
        className={`circle ${status || ""}`}
        style={inlineStyle}
        active
      />
    );
  }
}

export default CustomButton;

// can pass in data for compelted/scheduled classes as props and cancel the existing onlick behavior, which is intended as a test.
