import React, { Component } from "react";
import { Studios, studiosUrl } from "./exploreStudio";

import "./mobileSlider.css";

class MobileSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startIndex: 0,
      endIndex: 5,
      activeStudioImage: studiosUrl[0],
      activeIndex: 0,
      activeBackground: "white",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idx !== this.props.idx) {
      this.setState({ activeIndex: this.props.idx });
    }
  }

  render() {
    const slide = Studios.map((img, idx) => {
      let id = "inactive-logo2";
      let style = {};
      if (idx === this.state.activeIndex) {
        style = {
          backgroundColor: this.state.activeBackground,
          boxShadow: "0px 1px 14px 0px black",
        };
        id = "active-logo2";
      }

      return (
        <li
          id={id}
          className="explore-studio-logo2"
          style={style}
          onClick={() => {
            this.props.selectStudio(idx);
            this.setState({
              activeStudioImage: studiosUrl[idx],
              activeIndex: idx,
            });
          }}
        >
          {img}
        </li>
      );
    });

    return (
      <div className="slide-container">
        <div className="slider2">
          <ul className="explore-studio-slider2">{slide}</ul>
        </div>
      </div>
    );
  }
}

export default MobileSlider;
