import React from "react";

export default function RenderEquipment({ equipment, icons }) {
  const mapEquipment = equipment.map((tool) => (
    <div key={`tool-${tool}`}>
      <img alt={tool} src={icons[tool]} className="equipmenticons" />
    </div>
  ));

  return equipment.length === 0 ? (
    <>no equipment</>
  ) : (
    <>
      <p className="h2 ml-0">Equipment</p>
      <br></br>
      {mapEquipment}
    </>
  );
}
