const moment = require("moment");

export default function formatData(data) {
  return data.map((dataPoint) => ({
    date: dataPoint.date.split("T")[0],
    calorie: Math.floor(dataPoint.calorie),
  }));
}
/**
 * Converts ISO time as string to Month Day, Year
 * @param {String} date
 * @return {String}
 *
 * @description This function is expecting ISO time as a string
 * @example formatDate("2020-10-10T15:33:51.000Z") -> "Oct 10, 2020"
 */
export function formatDate(date, withDash = false) {
  if (moment(date).isValid()) {
    if (withDash) {
      return moment(date).format("MM-DD-YYYY");
    }
    return moment(date).format("MMM DD, YYYY");
  }
  throw new Error(`Invalid date string passed:${date}`);
}
/**
 * Adds one month to an ISO time and displays it as Month Day, Year
 * @param {String} date
 * @return {String}
 *
 * @description This function is expecting ISO time as a string
 * @example formatDatePlusMonth("2020-10-10T15:33:51.000Z") -> "Nov 10, 2020"
 */
export function formatDatePlusMonth(date) {
  return moment(date).add(1, "month").format("MMM DD, YYYY");
}
