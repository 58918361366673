export const get = async (path, query = "", headers = {}) => {
  return fetch(path + query, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    redirect: "follow",
    referrer: "no-referrer",
  });
};

export const post = async (path, data, headers = {}) => {
  return fetch(path, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data),
  });
};

export const patch = async (path, data, headers = {}) => {
  return fetch(path, {
    method: "PATCH",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data),
  });
};

export const deleteRequest = async (path, data, headers = {}) => {
  return fetch(path, {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data),
  });
};
