import { ActionTypes } from "redux/actions/events.actions.js";

const {
  LEAVE_LIVE_CLASS,
  LEAVE_LIVE_CLASS_SUCCESS,
  LEAVE_LIVE_CLASS_FAILURE,
  JOIN_LIVE_CLASS,
  JOIN_LIVE_CLASS_SUCCESS,
  JOIN_LIVE_CLASS_FAILURE,
} = ActionTypes;

const initialState = {
  liveClassAttendance: null,
};

export default function eventsReducer(state = initialState, action) {
  switch (action.type) {
    case LEAVE_LIVE_CLASS:
      return {
        ...state,
      };
    case LEAVE_LIVE_CLASS_SUCCESS:
      return {
        ...state,
      };
    case LEAVE_LIVE_CLASS_FAILURE:
      return {
        ...state,
      };
    case JOIN_LIVE_CLASS:
      return {
        ...state,
      };
    case JOIN_LIVE_CLASS_SUCCESS:
      return {
        ...state,
        liveClassAttendance: action.liveClassAttendance,
      };
    case JOIN_LIVE_CLASS_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
