import { put, call, all, takeLatest, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "redux/actions/basic.actions";
import { post } from "../../api";

export const verifyPromo = function* (action) {
  const { code, price, headers } = action.payload;
  try {
    const response = yield fetch("/api/basic/verifyPromo", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: headers,
      },
      body: JSON.stringify({
        promo: code,
        amount: price,
      }),
    });
    const data = yield response.json();
    if (
      response.status >= 200 &&
      response.status < 300 &&
      data.status == true
    ) {
      yield put({
        type: ActionTypes.GET_PROMO_SUCCESS,
        coupon: data,
        success: true,
      });
    } else {
      throw data;
    }
  } catch (error) {
    yield put({
      type: ActionTypes.GET_PROMO_FAILURE,
      error: error,
      success: false,
    });
  }
};

export const getClassTypes = function* (action) {
  const { headers } = action.payload;

  try {
    const response = yield call(
      post,
      "/api/basic/classTypes",
      {},
      {
        authorization: headers,
      }
    );

    const data = yield response.json();
    const allClassTypes = data.content;

    //debugger

    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ActionTypes.GET_ALL_CLASSTYPES_SUCCESS,
        ClassTypes: allClassTypes,
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.GET_ALL_CLASSTYPES_FAILURE,
      error: error,
    });
  }
};

export const getStudios = function* (action) {
  const { headers } = action.payload;

  try {
    const response = yield call(
      post,
      "/api/basic/studios",
      {},
      {
        authorization: headers,
      }
    );

    const data = yield response.json();
    const allStudios = data.content;

    if (response.status >= 200 && response.status < 300 && data.status) {
      yield put({
        type: ActionTypes.GET_ALL_STUDIOS_SUCCESS,
        studios: allStudios,
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.GET_ALL_STUDIOS_FAILURE,
      error: error,
    });
  }
};

function* basic() {
  yield all([
    takeEvery(ActionTypes.GET_PROMO, verifyPromo),
    takeLatest(ActionTypes.GET_ALL_CLASSTYPES, getClassTypes),
    takeLatest(ActionTypes.GET_ALL_STUDIOS, getStudios),
  ]);
}

export default basic;
