import React, { useState, useEffect } from "react";
import "./rituwell.css";
import Link from "screens/home/components/link";
import Background from "./assets/rituwell.png";
import Rituwell from "./assets/rituwell-white.png";
import Modal from "react-modal";
import closeButton from "../../../../assets/Vector.png";

const RituWell = () => {
  const [openModal, setOpenModal] = useState(false);

  // Hubspot react forms
  useEffect(() => {
    console.log("react hubspot");
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "23498037", // Replace with your HubSpot portal ID
          formId: "9649ccc1-a6e6-4a67-80bd-4cce292e497c", // Replace with your form ID
          target: "#hubspotFormModal",
        });
      }
    });

    return () => {
      // Clean up: Remove the script from the document on component unmount
      document.body.removeChild(script);
    };
  }, [openModal]);
  const handleOpenModal = (e) => {
    e.preventDefault();
    setOpenModal(true);
    document.body.classList.add("workplace-modal-open");
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    setOpenModal(false);
    document.body.classList.remove("workplace-modal-open");
  };

  return (
    <div className="rituwell-container">
      <p className="rituwell-intro">
        Introducing RituWell, powered by JETSWEAT
      </p>
      <div className="rituwell-section">
        <div className="rituwell-mid-section">
          <img className="rituwell-white" data-src={Rituwell} />
          <h1>
            Empower Your Population to Live
            <br />
            Their Healthiest Lives.
          </h1>
          <button className="rituwell-demo-button" onClick={handleOpenModal}>
            <p className="js-open-sans js-font-25 js-font-semibold">
              Request a Demo
            </p>
          </button>
        </div>

        <img className="rituwell-background" data-src={Background} />
      </div>
      <Modal
        style={{ overlay: { backgroundColor: "rgba(0,0,0,0.75" } }}
        isOpen={openModal}
        className={`workplace-modal ${openModal ? "open" : ""}`}
        shouldCloseOnOverlayClick={true}
      >
        <div className="modal-main">
          <button className="close-modal-button" onClick={handleCloseModal}>
            <img src={closeButton} />{" "}
          </button>
          <div className="modal-header">
            <h3>Get In Contact</h3>
            <p>Ask about our flexible pricing options!</p>
          </div>
          <div id="hubspotFormModal" className="hubspot-form"></div>
        </div>
      </Modal>
    </div>
  );
};

export default RituWell;
