import { ActionTypes } from "redux/actions/userfriends.actions.js";

const {
  ADD_FRIEND,
  ADD_FRIEND_SUCCESS,
  ADD_FRIEND_FAILURE,
  REMOVE_FRIEND,
  REMOVE_FRIEND_SUCCESS,
  REMOVE_FRIEND_FAILURE,
  GET_FRIENDS,
  GET_FRIENDS_SUCCESS,
  GET_FRIENDS_FAILURE,
  GET_FRIEND_REQUESTS,
  GET_FRIEND_REQUESTS_SUCCESS,
  GET_FRIEND_REQUESTS_FAILURE,
  UPDATE_FRIEND_REQUEST,
  UPDATE_FRIEND_REQUEST_SUCCESS,
  UPDATE_FRIEND_REQUEST_FAILURE,
  SEND_EMAIL_INVITE,
  SEND_EMAIL_INVITE_SUCCESS,
  SEND_EMAIL_INVITE_FAILURE,
  SEND_TEXT_INVITE,
  SEND_TEXT_INVITE_SUCCESS,
  SEND_TEXT_INVITE_FAILURE,
  SEARCH_FRIENDS,
  SEARCH_FRIENDS_SUCCESS,
  SEARCH_FRIENDS_FAILURE,
  CLEAR_SEARCH,
} = ActionTypes;

const initialState = {
  success: null,
  removeFriendSuccess: null,
  searchFriendResult: null,
  errorMessage: "",
  successMessage: "",
  friends: [],
  requests: [],
  friendIds: [],
};

export default function userFriendsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_FRIEND:
      return {
        ...state,
        success: null,
        successMessage: "",
        errorMessage: "",
      };
    case ADD_FRIEND_SUCCESS:
      return {
        ...state,
        success: true,
        successMessage: "Friend request successfully sent.",
        searchFriendResult: null,
      };
    case ADD_FRIEND_FAILURE:
      return {
        ...state,
        success: false,
        errorMessage: action.errorMessage,
        searchFriendResult: null,
      };
    case UPDATE_FRIEND_REQUEST:
      return {
        ...state,
        updateFriendRequestSuccess: null,
      };
    case UPDATE_FRIEND_REQUEST_SUCCESS:
      return {
        ...state,
        updateFriendRequestSuccess: true,
      };
    case UPDATE_FRIEND_REQUEST_FAILURE:
      return {
        ...state,
        updateFriendRequestSuccess: false,
      };
    case REMOVE_FRIEND:
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
        removeFriendSuccess: null,
      };
    case REMOVE_FRIEND_SUCCESS:
      return {
        ...state,
        removeFriendSuccess: true,
      };
    case REMOVE_FRIEND_FAILURE:
      return {
        ...state,
        removeFriendSuccess: false,
      };
    case GET_FRIENDS:
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    case GET_FRIENDS_SUCCESS:
      const friendIds = action.friends.map((item) => `${item.friend.id}`);
      return {
        ...state,
        friends: [...action.friends],
        friendIds,
      };
    case GET_FRIENDS_FAILURE:
      return {
        ...state,
      };
    case GET_FRIEND_REQUESTS:
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    case GET_FRIEND_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: [...action.requests],
        getFriendRequestSuccess: true,
      };
    case GET_FRIEND_REQUESTS_FAILURE:
      return {
        ...state,
        getFriendRequestSucess: false,
      };
    case SEND_EMAIL_INVITE:
      return {
        ...state,
      };
    case SEND_EMAIL_INVITE_SUCCESS:
      return {
        ...state,
      };
    case SEND_EMAIL_INVITE_FAILURE:
      return {
        ...state,
      };
    case SEND_TEXT_INVITE:
      return {
        ...state,
      };
    case SEND_TEXT_INVITE_SUCCESS:
      return {
        ...state,
      };
    case SEND_TEXT_INVITE_FAILURE:
      return {
        ...state,
      };
    case SEARCH_FRIENDS:
      return {
        ...state,
        searchFriendResult: null,
        success: null,
        errorMessage: "",
        successMessage: "",
      };
    case SEARCH_FRIENDS_SUCCESS:
      return {
        ...state,
        searchFriendResult: action.result,
        success: true,
      };
    case SEARCH_FRIENDS_FAILURE:
      return {
        ...state,
        searchFriendResult: null,
        success: false,
        errorMessage: action.errorMessage,
      };
    case CLEAR_SEARCH:
      return {
        ...state,
        searchFriendResult: null,
        success: null,
        errorMessage: "",
        successMessage: "",
        removeFriendSuccess: null,
      };
    default:
      return state;
  }
}
