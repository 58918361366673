import { ActionTypes } from "redux/actions/basic.actions";

const initialState = {
  coupon: [],
  classTypes: [],
  studios: [],
  error: {},
};

const basicReducer = (state = initialState, action) => {
  switch (action.type) {
    // case ActionTypes.GET_STRIPE_API_KEY:
    //   return { ...state, success: null }
    case ActionTypes.GET_PROMO_SUCCESS:
      return { ...state, coupon: action.coupon, success: true };
    case ActionTypes.GET_PROMO_FAILURE:
      return {
        ...state,
        coupon: action.error,
        success: false,
      };
    case ActionTypes.GET_ALL_CLASSTYPES_SUCCESS:
      return {
        ...state,
        classTypes: action.ClassTypes,
      };
    case ActionTypes.GET_ALL_CLASSTYPES_FAILURE:
      return {
        ...state,
        error: { ...action.error },
      };
    case ActionTypes.GET_ALL_STUDIOS_SUCCESS:
      return {
        ...state,
        studios: action.studios,
      };
    case ActionTypes.GET_ALL_STUDIOS_FAILURE:
      return {
        ...state,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export default basicReducer;
