export const ActionTypes = {
  GET_ALL_FAVE_CLASSTYPES: "GET_ALL_FAVE_CLASSTYPES",
  GET_ALL_FAVE_CLASSTYPES_FAILURE: "GET_ALL_FAVE_CLASSTYPES_FAILURE",
  GET_ALL_FAVE_CLASSTYPES_SUCCESS: "GET_ALL_FAVE_CLASSTYPES_SUCCESS",
  ADD_FAVORITE_CLASSTYPES: "ADD_FAVORITE_CLASSTYPES",
  ADD_FAVORITE_CLASSTYPES_FAILURE: "ADD_FAVORITE_CLASSTYPES_FAILURE",
  ADD_FAVORITE_CLASSTYPES_SUCCESS: "ADD_FAVORITE_CLASSTYPES_SUCCESS",
  UNFAVORITE_CLASSTYPES: "UNFAVORITE_CLASSTYPES",
  UNFAVORITE_CLASSTYPES_FAILURE: "UNFAVORITE_CLASSTYPES_FAILURE",
  UNFAVORITE_CLASSTYPES_SUCCESS: "UNFAVORITE_CLASSTYPES_SUCCESS",
  ADD_THEN_GET_FAVE_CLASSTYPES: "ADD_THEN_GET_FAVE_CLASSTYPES",
  ADD_THEN_GET_FAVE_CLASSTYPES_SUCCESS: "ADD_THEN_GET_FAVE_CLASSTYPES_SUCCESS",
  ADD_THEN_GET_FAVE_CLASSTYPES_FAILURE: "ADD_THEN_GET_FAVE_CLASSTYPES_FAILURE",
  UNFAVORITE_THEN_GET_FAVE_CLASSTYPES: "UNFAVORITE_THEN_GET_FAVE_CLASSTYPES",
  UNFAVORITE_THEN_GET_FAVE_CLASSTYPES_SUCCESS:
    "UNFAVORITE_THEN_GET_FAVE_CLASSTYPES_SUCCESS",
  UNFAVORITE_THEN_GET_FAVE_CLASSTYPES_FAILURE:
    "UNFAVORITE_THEN_GET_FAVE_CLASSTYPES_FAILURE",
  UNFAVE_MULTIPLE_FAVE_CLASSTYPES: "UNFAVE_MULTIPLE_FAVE_CLASSTYPES",
  UNFAVE_MULTIPLE_FAVE_CLASSTYPES_SUCCESS: "UNFAVE_MULTIPLE_FAVE_CLASSTYPES",
  UNFAVE_MULTIPLE_FAVE_CLASSTYPES_FAILURE:
    "UNFAVE_MULTIPLE_FAVE_CLASSTYPES_FAILURE",
  ADD_MULTIPLE_FAVE_CLASSTYPES: "ADD_MULTIPLE_FAVE_CLASSTYPES",
  ADD_MULTIPLE_FAVE_CLASSTYPES_SUCCESS: "ADD_MULTIPLE_FAVE_CLASSTYPES_SUCCESS",
  ADD_MULTIPLE_FAVE_CLASSTYPES_FAILURE: "ADD_MULTIPLE_FAVE_CLASSTYPES_FAILURE",
};

export const getFaveClassTypes = function (headers) {
  return {
    type: ActionTypes.GET_ALL_FAVE_CLASSTYPES,
    payload: {
      headers,
    },
  };
};

export const addFaveClassTypes = function (headers, classId, callbackUpdate) {
  // callback is used to change UI state
  return {
    type: ActionTypes.ADD_FAVORITE_CLASSTYPES,
    payload: {
      headers,
      classId,
      callbackUpdate,
    },
  };
};

export const addThenGetFaveClassTypes = function (headers, classId, callback) {
  // callback function is used to change UI state if needed
  return {
    type: ActionTypes.ADD_THEN_GET_FAVE_CLASSTYPES,
    payload: {
      headers,
      classId,
      callback,
    },
  };
};

export const unfavoriteClassTypes = function (headers, instanceId, callback) {
  return {
    type: ActionTypes.UNFAVORITE_CLASSTYPES,
    payload: {
      headers,
      instanceId,
      callback,
    },
  };
};

export const unfavoriteThenGetFaveClassTypes = function (headers, instanceId) {
  return {
    type: ActionTypes.UNFAVORITE_THEN_GET_FAVE_CLASSTYPES,
    payload: {
      headers,
      instanceId,
    },
  };
};
