import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Footer from "./footer.js";
import "./subscription.css";

function SelectButton(props) {
  if (props.isLoggedIn) {
    return (
      <Link to="/payment">
        <button className="Button-createaccount">Go to Payment</button>
      </Link>
    );
  }
  return (
    <Link to="signup">
      <button className="Button-createaccount">Create An Account</button>
    </Link>
  );
}

class Subscription extends Component {
  render() {
    const { currUser } = this.props;
    if (currUser && currUser.subscription && currUser.subscription.active) {
      return <Redirect to="/newplatform/dashboard" />;
    }
    return (
      <div>
        <div className="Background-color-nav" />

        <h1 className="Center Subscription-text">Select your subscription.</h1>
        <h1 className="Center Subscription-text Padding">
          For a limited time, get started for free!
        </h1>

        <section className="Z-index10">
          <div className="Responsive-plan">
            <section
              className="Gallery"
              onClick={() => {
                this.props.setSubscription("yearly");
              }}
            >
              <fieldset
                className={`Monthly-annualbox ${
                  this.props.subscription === "yearly" ? "Active" : ""
                }`}
              >
                <legend className="Price-font">Annual Plan</legend>
                <img
                  alt="Save Banner"
                  className="Save-banner"
                  src={require("../images/Save25Banner.png")}
                />
                <div className="Payment-yearly">
                  <p>
                    <span className="Price-font">$239.99/year</span>
                    <br />
                    billed annually
                    <br />
                    <span className="Price-font">Unlimited Workouts</span>
                    <span className="Price-font">First 7 Days Free</span>
                    <br />
                    Cancel at any time
                  </p>
                </div>
              </fieldset>
            </section>
          </div>
          <div className="Responsive-plan">
            <section
              className="Gallery"
              onClick={() => {
                this.props.setSubscription("monthly");
              }}
            >
              <fieldset
                className={`Monthly-annualbox ${
                  this.props.subscription === "monthly" ? "Active" : ""
                }`}
              >
                <legend className="Price-font">Monthly Plan</legend>
                <div className="Payment-monthly">
                  <p>
                    <span className="Price-font">$19.99/month</span>
                    <br />
                    billed monthly
                    <br />
                    <span className="Price-font">Unlimited Workouts</span>
                    <span className="Price-font">First 7 Days Free</span>
                    <br />
                    Cancel at any time
                  </p>
                </div>
              </fieldset>
            </section>
          </div>

          <div className="clearfix" />

          <div className="Create-accountcenter">
            <SelectButton
              isLoggedIn={this.props.isLoggedIn}
              subscription={this.props.subscription}
            />
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default Subscription;
