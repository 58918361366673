import { ActionTypes } from "redux/actions/livestream.actions.js";

const {
  SEND_VIDEO_OFFER,
  SEND_VIDEO_OFFER_SUCCESS,
  SEND_VIDEO_OFFER_FAILURE,
  SEND_VIDEO_ANSWER,
  SEND_VIDEO_ANSWER_SUCCESS,
  SEND_VIDEO_ANSWER_FAILURE,
  JOIN_LIVE_CLASS,
  JOIN_LIVE_CLASS_SUCCESS,
  JOIN_LIVE_CLASS_FAILURE,
  SEND_LIVE_CLASS_MESSAGE,
  SEND_LIVE_CLASS_MESSAGE_SUCCESS,
  SEND_LIVE_CLASS_MESSAGE_FAILURE,
  CREATE_OFFER,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_FAILURE,
  CREATE_ANSWER,
  CREATE_ANSWER_SUCCESS,
  CREATE_ANSWER_FAILURE,
  SET_ANSWER,
  SET_ANSWER_SUCCESS,
  SET_ANSWER_FAILURE,
  ADD_ICE_CANDIDATE,
  ADD_ICE_CANDIDATE_SUCCESS,
  ADD_ICE_CANDIDATE_FAILURE,
} = ActionTypes;

const initialState = {
  offer: null,
  answer: null,
  offer_success: null,
  answer_success: null,
  offer_peeruuid: null,
  offer_dest: null,
};

export default function livestreamReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_VIDEO_OFFER:
      return {
        ...state,
      };
    case SEND_VIDEO_OFFER_SUCCESS:
      return {
        ...state,
      };
    case SEND_VIDEO_OFFER_FAILURE:
      return {
        ...state,
      };
    case SEND_VIDEO_ANSWER:
      return {
        ...state,
      };
    case SEND_VIDEO_ANSWER_SUCCESS:
      return {
        ...state,
      };
    case SEND_VIDEO_ANSWER_FAILURE:
      return {
        ...state,
      };
    case SET_ANSWER:
      return {
        ...state,
      };
    case SET_ANSWER_SUCCESS:
      return {
        ...state,
      };
    case SET_ANSWER_FAILURE:
      return {
        ...state,
      };
    case ADD_ICE_CANDIDATE:
      return {
        ...state,
      };
    case ADD_ICE_CANDIDATE_SUCCESS:
      return {
        ...state,
      };
    case ADD_ICE_CANDIDATE_FAILURE:
      return {
        ...state,
      };
    case CREATE_OFFER:
      return {
        ...state,
        offer_success: null,
        offer_peeruuid: null,
      };
    case CREATE_OFFER_SUCCESS:
      let { offer, offer_peeruuid, offer_dest, offer_peerusertype } = action;
      action.callback(offer);
      return {
        ...state,
        offer,
        offer_peeruuid,
        offer_dest,
        offer_peerusertype,
        offer_success: true,
      };
    case CREATE_OFFER_FAILURE:
      return {
        ...state,
        offer_success: false,
      };
    case CREATE_ANSWER:
      return {
        ...state,
        answer_success: null,
        answer_peeruuid: null,
      };
    case CREATE_ANSWER_SUCCESS:
      let { answer, answer_peeruuid, answer_dest } = action;
      action.callback(answer);
      return {
        ...state,
        answer,
        answer_peeruuid,
        answer_dest,
        answer_success: true,
        answer_peeruuid: null,
      };
    case CREATE_ANSWER_FAILURE:
      return {
        ...state,
        answer_success: false,
      };
    default:
      return state;
  }
}

/*
  {
    message: "Message",
    me: false
  }
*/
