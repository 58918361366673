export const GET_ALL_LIVE_VIDEOS_JETSWEAT = "GET_ALL_LIVE_VIDEOS_JETSWEAT";
export const GET_ALL_LIVE_VIDEOS_JETSWEAT_SUCCESS =
  "GET_ALL_LIVE_VIDEOS_JETSWEAT_SUCCESS";
export const GET_ALL_LIVE_VIDEOS_JETSWEAT_FAIL =
  "GET_ALL_LIVE_VIDEOS_JETSWEAT_FAIL";

export const GET_LIVE_CONTENT_ID = "GET_LIVE_CONTENT_ID";
export const GET_LIVE_CONTENT_ID_SUCCESS = "GET_LIVE_CONTENT_ID_SUCCESS";
export const GET_LIVE_CONTENT_ID_FAIL = "GET_LIVE_CONTENT_ID_FAIL";

export const SCHEDULE_LIVE_CLASSES = "SCHEDULE_LIVE_CLASSES";
export const SCHEDULE_LIVE_CLASSES_SUCCESS = "SCHEDULE_LIVE_CLASSES_SUCCESS";
export const SCHEDULE_LIVE_CLASSES_FAIL = "SCHEDULE_LIVE_CLASSES_FAIL";

/*
export const getLiveContentId = function (studioId, headers) {
  return {
    type: ActionTypes.GET_LIVE_CONTENT_ID,
    payload: {
      studioId,
      headers
    },
  }
}
*/
